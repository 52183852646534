import React, { useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Page from '../../components/pages';
import { signIn } from '../../redux/actions';
import { BaseModal } from '../../common/organisms';
import { LostPassword as LostPasswordPage } from '../../common/pages';
import Footer from 'components/pages/footers';

import IcoSuccess from '../../assets/icons/ico_success.svg';

interface LostPasswordProps {
  appElement: any;
  signIn: (data) => void;
}

const apiUrl: string = process.env.REACT_APP_SERVER + '/api/forgot';

const LostPassword: React.FC<LostPasswordProps> = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [showResponsePopUp, setShowResponsePopUp] = useState(false);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function doLostPassword(data) {
    if (!validateEmail(data.email)) {
      return;
    }

    const config = {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    };

    axios
      .post(apiUrl, data, config)
      .then(() => {
        setShowResponsePopUp(true);
      })
      .catch(() => {
        setShowResponsePopUp(true);
      });
  }

  return (
    <Page title="lostPassword">
      <div className="banner-wrapper banner-lostpassword">
        <LostPasswordPage
          handleSubmit={doLostPassword}
          description={t('lostPasswordDescription')}
          onClick={() => push('/login')}
        />

        {showResponsePopUp && (
          <BaseModal
            appElement="#root"
            setModalIsOpen={setShowResponsePopUp}
            modalIsOpen={showResponsePopUp}
            className="modal modal-success">
            <div className="overlay">
              <img
                src={IcoSuccess}
                className="svg"
                height="101"
              />
              <h4>{'Passwort Vergessen'}</h4>

              <p>
                {
                  'Zum Ändern Ihres Passworts haben wir eine Nachricht an die von dir angegebene E-Mail-Adresse versendet. Prüfe auch dein Spam-Ordner!'
                }
              </p>
              <hr />
              <div className="actions single">
                <button
                  onClick={() => setShowResponsePopUp(false)}
                  className="confirm">
                  <strong>{t('OK')}</strong>
                </button>
              </div>
            </div>
          </BaseModal>
        )}
      </div>
      <Footer />
    </Page>
  );
};

export default connect(({ appElement }) => ({ appElement }), { signIn })(
  LostPassword,
);
