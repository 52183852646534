import { Constants } from '../constants';
import { setProduct } from './product';
import axios from 'axios';
import { Authorization, Product } from '../../graphql';
import { client } from '../../graphql';

const trackingApiUrl = process.env.REACT_APP_SERVER + '/api/tracking';

export function setOfflineData(data) {
  return async function (dispatch, state) {

    const productId = state().license.product_id
      ? state().license.product_id
      : process.env.REACT_APP_PRODUCT_ID;
    let product;
    if (!state().offlineMode.isOffline) {
      try {
        product = await client.query({
          query: Product.getQuery(),
          variables: {
            id: parseInt(productId),
          },
        });
        dispatch(setProduct(product.data.product));
      } catch (err) {
        // console.log('in product error');
      }
    }

    return dispatch({
      type: Constants.SET_OFFLINE_MODUS_DATA,
      payload: data,
    });
  };
}

export function setOfflineResults(result) {
  return {
    type: Constants.SET_OFFLINE_MODUS_RESULT,
    payload: result,
  };
}

export function resetOfflineData() {
  return {
    type: Constants.RESET_OFFLINE_MODUS_DATA,
  };
}

export const resetState = () => ({ type: 'USER_LOGOUT' });

export const changeCourseState = () => ({ type: 'CHANGE_COURSE' });

export async function uploadTrackingData(user) {
  const offlineTrackingData = localStorage.getItem('tracking_offline');
  if (offlineTrackingData) {
    const dataArray = JSON.parse(offlineTrackingData);
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    try {
      await Promise.all(
        dataArray.map(async (item) => {
          return axios.post(trackingApiUrl, item, config);
        }),
      );
      localStorage.removeItem('tracking_offline');
      return await Authorization.getUserDetails(user.token);
    } catch (err) {
      console.log(err);
    }
  }
  return false;
}

export function updateIsOffline(isOffline) {
  return {
    type: Constants.UPDATE_IS_OFFLINE,
    payload: isOffline,
  };
}
