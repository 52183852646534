import React from 'react';
import classNames from 'classnames';
import { PracticeIcon } from '../icon/practice';
import { ArrowIcon } from '../icon/arrow';
import { LearnIcon } from '../icon/learn';
import { Icon } from '../icon';
import './button.scss';
import { Spinner } from '../spinner';

interface ButtonProps {
  onClick?: React.MouseEventHandler;
  theme?: string;
  text: string;
  iconLeft?: string;
  iconRight?: string;
  transparent?: boolean;
  removeBorder?: boolean;
  stretched?: boolean;
  slim?: boolean;
  style?: { [key: string]: string | number };
  disabled?: boolean;
  isLoading?: boolean;
  loadingColor?: string;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  onClick,
  theme = 'primary',
  text,
  iconLeft,
  iconRight,
  transparent = false,
  removeBorder = false,
  stretched = false,
  slim = false,
  style = {},
  isLoading,
  disabled = isLoading,
  loadingColor = '#339bf2',
}) => {
  const renderIcon = (icon) => {
    switch (icon) {
      case 'practice':
        return <PracticeIcon />;
      case 'learn':
        return <LearnIcon />;
      case 'arrow':
        return <ArrowIcon />;
      default:
        return icon ? Icon(icon) : null;
    }
  };

  const renderedIconLeft = renderIcon(iconLeft);
  const renderedIconRight = renderIcon(iconRight);

  const className = classNames({
    button: true,
    'button--is-primary': theme === 'primary',
    'button--is-secondary': theme === 'secondary',
    'button--is-tertiary': theme === 'tertiary',
    'button--is-success': theme === 'success',
    'button--is-danger': theme === 'danger',
    'button--is-light': theme === 'light',
    'button--is-transparent': transparent,
    'button--has-icon-left': renderedIconLeft !== null,
    'button--has-icon-right': renderedIconRight !== null,
    'button--has-icon-left-and-right':
      renderedIconLeft !== null && renderedIconRight !== null,
    'button--has-no-border': removeBorder,
    'button--is-stretched': stretched,
    'button--is-slim': slim,
    'button--is-disabled': !!disabled,
  });

  return (
    <div className={className} style={style} onClick={onClick}>
      {renderedIconLeft && (
        <div className="button-icon-left">{renderedIconLeft}</div>
      )}
      <span className="button-text">{text}</span>
      {renderedIconRight && (
        <div className="button-icon-right">{renderedIconRight}</div>
      )}
      {isLoading && (
        <div className="button-loader">
          <Spinner color={loadingColor} />
        </div>
      )}
    </div>
  );
};

export default Button;
