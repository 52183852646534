import * as React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import './navigationTabStyles.scss';

interface InterfaceNavigationItem {
  name: string;
  keyName: string;
  hidden: boolean;
  icon: any;
  path: string;
  activePaths: string[];
}

interface interfaceNavItems {
  right: Array<InterfaceNavigationItem>;
  center: Array<InterfaceNavigationItem>;
  left: Array<InterfaceNavigationItem>;
}

interface InterfaceNavigationTabProps {
  navItems: interfaceNavItems;
  activeLocation: {
    pathname: string;
  };
  isTop: boolean;
  hidden: boolean;
}

const NavigationTab: React.FunctionComponent<InterfaceNavigationTabProps> = ({
  navItems,
  isTop,
  activeLocation,
  hidden,
}) => {
  const { pathname } = useLocation();
  const mergedItems: any = [];
  Object.values(navItems).forEach((itemArray) =>
    itemArray.forEach((item: any) => mergedItems.push(item)),
  );

  const isNotHiddenPageAvailable = mergedItems.find(
    (link: InterfaceNavigationItem) => !link.hidden,
  );
  if (!navItems || !isNotHiddenPageAvailable) {
    return null;
  }

  const renderItems = (items: Array<InterfaceNavigationItem>) => {
    return items.map((item) => {
      if (item.hidden) {
        return null;
      }
      let isActive = '';
      item.activePaths.forEach((path) => {
        if (pathname.indexOf(path) > -1) {
          return (isActive = 'is-active');
        }
      });
      return (
        <div
          className={`navigation-tab-wrapper__item ${isActive} ${item.keyName}`}
          key={item.name}>
          <Link to={item.path} title={item.name}>
            <div className="icon">{item.icon}</div>
            <span>{item.name}</span>
          </Link>
        </div>
      );
    });
  };

  if (hidden) {
    return null;
  }

  if (isTop) {
    return (
      <div className={'navigation-tab-wrapper'}>
        <div className="navigation-tab-wrapper__left">
          {renderItems(navItems.left)}
        </div>
        <div className="navigation-tab-wrapper__center">
          {renderItems(navItems.center)}
        </div>
        <div className="navigation-tab-wrapper__right">
          {renderItems(navItems.right)}
        </div>
      </div>
    );
  }

  return (
    <div className="navigation-tab-wrapper is-mobile">
      {renderItems(navItems.left)}
      {renderItems(navItems.center)}
      {renderItems(navItems.right)}
    </div>
  );
};

export default NavigationTab;
