import {Constants} from "../constants";

export function prepareExercise(exercise) {
    return {
        type: Constants.PREPARE_EXERCISE,
        payload: exercise,
    }
}

export function startExercise() {
    return {
        type: Constants.START_EXERCISE,
    };
}

export function toggleExerciseAnswer(answerIndex, questionIndex) {
    return {
        type: Constants.TOGGLE_EXERCISE_ANSWER,
        payload: {answerIndex, questionIndex},
    }
}

export function stopExercise() {
    return {
        type: Constants.STOP_EXERCISE,
    };
}

export function setExerciseResult(result) {
    return {
        type: Constants.SET_EXERCISE_RESULT,
        payload: result,
    }
}

export function resetExercise() {
    return {
        type: Constants.RESET_EXERCISE,
    };
}
