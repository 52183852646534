import React from 'react';
import * as Yup from 'yup';
import { Banner } from '../../molecules';
import { useTranslation } from 'react-i18next';
import { FormWrapper, RegisterForm } from '../../organisms';
import { NOOP } from '../../../libs/utils';

interface RegisterPageProps {
  headline?: string;
  onClick: React.MouseEventHandler;
  handleSubmit: Function;
  linkToAlreadyRegistered: string;
  editable?: {};
  appElement: JSX.Element;
  onAvatarUpload: Function;
}

const RegisterPage: React.FunctionComponent<RegisterPageProps> = ({
  headline = 'register',
  onClick = NOOP,
  handleSubmit = NOOP,
  linkToAlreadyRegistered = '#',
  editable,
  appElement,
  onAvatarUpload,
}) => {
  const { t } = useTranslation();
  editable = editable || {
    first_name: {
      name: 'first_name',
      labelText: t('firstName'),
    },
    last_name: {
      name: 'last_name',
      labelText: t('lastName'),
    },
    email: {
      name: 'email',
      labelText: t('email'),
    },
    password: {
      name: 'password',
      labelText: t('password'),
    },
    c_password: {
      name: 'c_password',
      labelText: t('cPassword'),
    },
  };

  let user = {
    email: '',
    password: '',
    terms: false,
  };

  const validation = {
    headline: t('registrationFailedHeadline'),
    description: t('registrationFailedDescription'),
    schema: Yup.object().shape({
      first_name: Yup.string()
        .required(
          t('fieldIsRequired', {
            name: t('firstName'),
          }),
        )
        .trim()
        .min(
          2,
          t('fieldIsTooShort', {
            name: t('firstName'),
            minimumCount: 2,
          }),
        ),
      last_name: Yup.string()
        .required(
          t('fieldIsRequired', {
            name: t('lastName'),
          }),
        )
        .trim()
        .min(
          2,
          t('fieldIsTooShort', {
            name: t('lastName'),
            minimumCount: 2,
          }),
        ),
      email: Yup.string()
        .email(
          t('fieldIsNotAnEmail', {
            name: t('email'),
          }),
        )
        .trim()
        .required(
          t('fieldIsRequired', {
            name: t('email'),
          }),
        ),
      password: Yup.string().required(
        t('fieldIsRequired', {
          name: t('password'),
        }),
      ),
      c_password: Yup.string().test(
        'passwords-match',
        t('fieldsAreNotMatching', {
          name1: t('password'),
          name2: t('cPassword'),
        }),
        function (value) {
          return this.parent.password === value;
        },
      ),
      terms: Yup.boolean().oneOf([true], t('termsAreRequired')),
    }),
  };

  return (
    <Banner headline={t(headline)} onClick={onClick}>
      <FormWrapper
        initialValues={user}
        editable={editable}
        handleSubmit={handleSubmit}
        validation={validation}
        appElement={appElement}>
        <RegisterForm
          save={() => {}}
          values={user}
          editable={null}
          onAvatarUpload={onAvatarUpload}
          linkToAlreadyRegistered={linkToAlreadyRegistered}
        />
      </FormWrapper>
    </Banner>
  );
};

export default RegisterPage;
