import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import './avatarUpload.scss';

interface AvatarUploadProps {
  onImageUpload: (formData: FormData) => void;
  user?: { avatar: string; first_name: string; last_name: string };
}

const AvatarUpload: React.FunctionComponent<AvatarUploadProps> = ({
  onImageUpload,
  user,
}) => {
  const fileInputRef: React.MutableRefObject<HTMLInputElement | null> = useRef(
    null,
  );
  const { t } = useTranslation();

  function onFileSelect(fileEvent) {
    const file = fileEvent.target.files?.[0];
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    onImageUpload(formData);
  }

  function onAvatarClick() {
    if (fileInputRef && fileInputRef?.current) {
      fileInputRef.current.onchange = onFileSelect;
      fileInputRef.current.click();
    }
  }

  function renderProfileImage() {
    if (!user || !user.avatar) {
      return <div className="profile-image-wrapper placeholder" />;
    }

    return (
      <div className="profile-image-wrapper">
        <img src={user.avatar} alt="Avatar" />
      </div>
    );
  }

  return (
    <div onClick={onAvatarClick} className="profile-image">
      <form className="image-upload" encType="multipart/form-data">
        <div className="upload-label">
          {renderProfileImage()}
          {user ? (
            <div className="profile-image-name">
              {user.first_name + ' ' + user.last_name}
            </div>
          ) : (
            <div className="profile-image-name">{t('uploadImage')}</div>
          )}
        </div>
        <input
          ref={fileInputRef}
          type="file"
          id="image-upload-3"
          className="upload"
          accept="image/*"
          style={{ display: 'none' }}
        />
      </form>
    </div>
  );
};

export default AvatarUpload;
