import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { BaseModal } from '../../../organisms';
import { Headline, Circle, Span, RoundButton, Paragraph } from '../../../atoms';
import './resultQuestion.scss';
import {Answer} from "../../../../libs/types";

interface ResultQuestionProps {
  question: {
    question?: string;
    text: string;
    index: number;
    answers: { is_checked: boolean; needs_check: boolean; text: string }[];
  };
  childrenBefore?: JSX.Element;
  setModalIsOpen: (bool: boolean) => void;
  modalIsOpen: boolean;
}

const ResultQuestion: React.FunctionComponent<ResultQuestionProps> = ({
  question,
  childrenBefore,
  setModalIsOpen,
  modalIsOpen,
}) => {
  const { t } = useTranslation();

  function getColumnsClassNames(answer: Answer) {
    return classNames({
      'result-question__answers-item-columns': true,
      'result-question__answers-item-columns--is-needing_check':
        answer.needs_check == true,
      'result-question__answers-item-columns--is-checked':
        answer.is_checked == true,
    });
  }

  return (
    <BaseModal
      baseClass="result-question"
      childrenBefore={childrenBefore}
      setModalIsOpen={setModalIsOpen}
      modalIsOpen={modalIsOpen}>
      <div className="result-question__header">
        <Span type="tertiary" uppercase>
          {t('questionAtIndex', {
            index: question.index.toString().padStart(2, '0'),
          })}
        </Span>
        <div className="result-question__header-close">
          <RoundButton
            icon="X"
            theme="tertiary"
            transparent
            iconSize={26}
            onClick={() => setModalIsOpen(false)}
          />
        </div>
      </div>

      <div className="result-question__text">
        <Headline type="h2">
          {question.question ? question.question : question.text}
        </Headline>
      </div>

      <div className="result-question__answers">
        {question.answers.map((answer, i) => (
          <div
            key={`result-question-answer-${i}`}
            className="result-question__answers-item">
            <div className={getColumnsClassNames(answer)}>
              <div className="status">
                <Circle
                  theme={answer.needs_check ? 'success' : 'danger'}
                  backgroundTheme={answer.is_checked ? 'secondary' : null}
                />
              </div>
              <Paragraph>{answer.text}</Paragraph>
            </div>
          </div>
        ))}
      </div>
    </BaseModal>
  );
};

export default ResultQuestion;
