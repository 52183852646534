import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Stopwatch } from '../../molecules';
import { Button, Headline, Span } from '../../atoms';
import '../exerciseSplash/splash.scss';

interface ExamSplashProps {
  exam: any;
  questionCount: number;
  correctAnswersCount: number;
  timeInMinutes: number;
  onClick: () => void;
}

const ExamSplash: React.FunctionComponent<ExamSplashProps> = ({
  exam,
  questionCount,
  correctAnswersCount,
  timeInMinutes,
  onClick,
}) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  if (correctAnswersCount === 0) {
    correctAnswersCount = 1;
  }
  return (
    <div className="splash">
      <div className="splash__watch">
        <Stopwatch
          timer={{
            ...exam.timer,
            onExpire: () => {},
          }}
        />
      </div>
      <Headline type="h2">{t('examSimulation')}</Headline>
      <div className="splash__description">
        <Span>
          {t('questionWithMinutes', { questionCount, timeInMinutes })}
          <br />
          {correctAnswersCount === 1
            ? t('examTypeAndCorrectAnswer', { correctAnswersCount })
            : t('examTypeAndCorrectAnswers', { correctAnswersCount })}
        </Span>
      </div>
      <Button theme="secondary" text={t('begin')} onClick={onClick} />
      <p className="splash__goback" onClick={goBack}>
        {t('back')}
      </p>
    </div>
  );
};

export default ExamSplash;
