import { number } from 'yup/lib/locale';
import { OfflineSettingsState, OfflineSettingTableNames } from './types';

export const getOfflineDataQuery = `SELECT * FROM offline_settings;`;

export const setOfflineDataMutation = ({
  learning,
  exercise,
  statistics,
}: OfflineSettingsState) => {
  return `
    INSERT INTO offline_settings (type, stored) VALUES
    ("learning", ${learning.all}),
    ("learning_images", ${learning.images}),
    ("exercise", ${exercise.all}),
    ("statistics", ${statistics.all});
    `;
};

export const deleteTableDataMutation = (tableName: string) =>
  `DELETE FROM ${tableName};`;

export const getAllByTable = (tableName: OfflineSettingTableNames) =>
  `SELECT * FROM ${tableName};`;

const fixSingleQuoteInText = (text: string) => {
  return text.replace(/'/g, "###SINGLEQUOTE###");
}

export const storeTextData = ({
  tableName,
  version,
  content,
}: {
  tableName: OfflineSettingTableNames;
  version: number;
  content: string;
}) => {
  // console.log('storing', tableName, version, content.substring(0, 5));
  return `INSERT INTO ${tableName} (version, content) VALUES (${version}, '${fixSingleQuoteInText(content)}')`;
};

export const storeLearningData = ({
  version,
  title,
  content,
}: {
  version: number;
  title: 'subareas' | 'learningcontent' | 'learningareas';
  content: string;
}) => {
  // console.log('storing', title, version, content.substring(0, 5));
  return `INSERT INTO learning (version, title, content) VALUES ('${version}', '${title}', '${fixSingleQuoteInText(content)}')`;
};

export const storeExerciseData = ({
  version,
  title,
  content,
}: {
  version: number;
  title: 'exercises' | 'exam' | 'settings';
  content: string;
}) =>
  `INSERT INTO exercise (version, title, content) VALUES ('${version}', '${title}', '${fixSingleQuoteInText(content)}')`;

export const storeImageData = ({
  index,
  url,
}: {
  index: number;
  url: string;
}) => `INSERT INTO learning_images (id, content) VALUES (${index}, '${url}') `;
