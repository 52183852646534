import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { Headline, Span, Button, Paragraph } from '../../../atoms';
import { BaseModal } from '../../../organisms';
import './error.scss';
import { NOOP } from '../../../../libs/utils';

interface ErrorProps {
  title: string;
  description: string | JSX.Element;
  accept?: Function;
  modalIsOpen: boolean;
  setModalIsOpen: Function;
  errorCode?: number;
}

const Error: React.FunctionComponent<ErrorProps> = ({
  title,
  description,
  children,
  accept = NOOP,
  modalIsOpen,
  setModalIsOpen,
  errorCode,
}) => {
  Modal.setAppElement('#root');
  const { t } = useTranslation();
  return (
    <BaseModal
      baseClass="error"
      contentLabel="error"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}>
      {title && <Headline type="h2">{title}</Headline>}
      <Paragraph>
        {description && <div className="error-description">{description}</div>}
        <Span>{children}</Span>
        {errorCode && (
          <div className="error-code">
            {t('errorCode', {
              code: errorCode,
            })}
          </div>
        )}
      </Paragraph>
      <Button
        theme="danger"
        text={t('ok')}
        onClick={() => {
          setModalIsOpen(false);
          accept();
        }}
      />
    </BaseModal>
  );
};

export default Error;
