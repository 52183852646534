import React from 'react';

export const SuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42">
    <g id="Gruppe_104" data-name="Gruppe 104" transform="translate(-186 -79)">
      <circle
        id="Ellipse_95"
        data-name="Ellipse 95"
        cx="21"
        cy="21"
        r="21"
        transform="translate(186 79)"
        fill="#fff"
      />
      <path
        id="checkbox-marked-circle-outline"
        d="M10.848,14.1,8.737,16.223l6.737,6.737L30.446,7.989,28.335,5.863,15.475,18.723Z"
        transform="translate(188.474 86.028)"
        fill="#34526b"
      />
    </g>
  </svg>
);
