import { combineReducers } from 'redux';
import user from './user.ts';
import product from './product';
import lastLocation from './lastLocation';
import exam from './exam';
import trackings from './trackings';
import exercise from './exercise';
import license from './license';
import legal from './legalReducer';
import appElement from './appElement';
import appDefaults from './appDefaults';
import globalError from './globalError';
import offlineMode from './offlineModus';
import queue from './queue';

export default combineReducers({
  user,
  product,
  trackings,
  lastLocation,
  legal,
  exam,
  exercise,
  appElement,
  globalError,
  appDefaults,
  license,
  offlineMode,
  queue,
});
