export default {
  translation: {
    no: 'Nein',
    yes: 'Ja',
    deleteAllMyData: {
      headline: 'Lösche alle meine Daten',
      message:
        'Achtung, wenn Sie dies bestätigen werden alle ihre Nutzerdaten gelöscht. Falls Sie weitere APPucations Apps besitzen bedeutet dies, dass auch alle Daten in diesen Apps gelöscht werden und auch dort der Zugriff gesperrt wird. Bitte bestätigen Sie indem Sie das Wort “löschen” in folgendes Input Feld eingeben.',
    },
    delete: "löschen",
    examModeTitle: 'Prüfungsmodus',
    isExercisable: 'Frage darf in Übungen auftauchen',
    questionTitle: 'Frage',
    countPoint: '{{count}} Punkt',
    countPoints: '{{count}} Punkte',
    answer: 'Antwort',
    answersTitle: 'Antworten',
    singleChoice: 'Single-Choice-Verfahren',
    multipleChoice: 'Multiple-Choice-Verfahren',
    completeText: 'Lückentextverfahren',
    orderTerms: 'Anordnungsverfahren (korrekte Reihenfolge finden)',
    assignment: '1 zu 1 Einzelzuordnung-Verfahren',
    assignmentMultiple: '1 zu N Mehrfachzuordnung-Verfahren',
    preview: 'Vorschau',
    back: 'Zurück',
    forgotPassword: 'Kennwort vergessen?',
    resetPassword: 'Zurücksetzen',
    resetPasswordHeadline: 'Kennwort Zurücksetzen',
    gotoLogin: 'Zur Anmeldung',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'Email',
    password: 'Kennwort',
    cPassword: 'Kennwort bestätigen',
    alreadyRegistered: 'Du hast bereits ein Konto?',
    proceed: 'Weiter',
    create: 'Erstellen',
    examSimulation: 'Prüfungssimulation',
    questionWithMinutes: '{{questionCount}} Fragen - {{timeInMinutes}} Minuten',
    examTypeAndCorrectAnswer:
      'Multiple-Choice-Test (maximal {{correctAnswersCount}} korrekte Antwort)',
    examTypeAndCorrectAnswers:
      'Multiple-Choice-Test (maximal {{correctAnswersCount}} korrekte Antworten)',
    fieldIsRequired: '{{name}} ist ein Pflichtfeld',
    termsAreRequired:
      'Bitte akzeptiere unsere Datenschutzerklärung sowie die AGB',
    fieldIsNotAnEmail: '{{name}} ist keine gültige E-Mail-Adresse',
    fieldsAreNotMatching: 'Die Passwörter stimmen nicht überein',
    fieldIsTooShort: '{{name}} ist zu kurz (mind. {{minimumCount}} Zeichen)',
    ok: 'OK',
    loginFailedHeadline: 'Authentifizierung abgebrochen',
    loginFailedDescription: 'Deine Daten konnten nicht gesendet werden:',
    registrationFailedHeadline: 'Registrierung unvollständig',
    registrationFailedDescription: 'Deine Daten konnten nicht gesendet werden:',
    errorCode: '(Fehler Code {{code}})',
    error: 'Fehler',
    questionCountWithTotal: 'Frage {{count}} / {{totalCount}}',
    finishExam: 'Prüfung Beenden',
    correctAnswers: '(Richtige Antworten: {{countCorrectAnswers}})',
    showCorrectAnswersCount: 'Anzahl der richtigen Antworten je Frage anzeigen',
    duration: 'Dauer in min.',
    description: 'Beschreibung',
    confirmFinishExam: 'Prüfung beenden?',
    attentionUnansweredQuestion:
      'Achtung, du hast noch eine unbeantwortete Frage.',
    noUnansweredQuestions:
      'Du hast alle Fragen beantwortet. Möchtest du die Prüfung beenden?',
    attentionUnansweredQuestions:
      'Achtung, du hast noch {{unansweredQuestions}} unbeantwortete Fragen.',
    doYouReallyWantToFinishYourExam: 'Willst du die Prüfung wirklich beenden?',
    finish: 'Beenden',
    yesFinish: 'Ja, beenden',
    countUnansweredQuestions: '{{unansweredCount}} unbeantwortete Fragen',
    examSuccess: 'Prüfung bestanden',
    examFailed: 'Leider durchgefallen',
    details: 'Details',
    tryAgain: 'Erneut Versuchen',
    typeExam: 'Prüfung',
    typeExercise: 'Übung',
    questionAtIndex: 'Frage {{index}}',

    resultDateExam: 'Prüfung vom',
    resultDateExercise: 'Übung vom',
    resultIsSuccess: 'Bestanden',
    resultLearningArea: 'Lernbereich',
    resultAchievedPoints: 'Erzielte Punkte',
    resultWrongAnswers: 'Falsche Antworten gesamt',
    resultLearningAreas: 'Themen',
    noDataFound: 'Keine Daten gefunden',
    yearAgo: 'vor {{number}} Jahr',
    yearsAgo: 'vor {{number}} Jahren',
    monthAgo: 'vor {{number}} Monat',
    monthsAgo: 'vor {{number}} Monaten',
    weekAgo: 'vor {{number}} Woche',
    weeksAgo: 'vor {{number}} Wochen',
    dayAgo: 'vor {{number}} Tag',
    daysAgo: 'vor {{number}} Tagen',
    hourAgo: 'vor {{number}} Stunde',
    hoursAgo: 'vor {{number}} Stunden',
    minuteAgo: 'vor {{number}} Minute',
    minutesAgo: 'vor {{number}} Minuten',
    now: 'gerade eben',
    exerciseTypeAndCorrectAnswer:
      'Multiple-Choice-Test (maximal {{correctAnswersCount}} korrekte Antwort)',
    exerciseTypeAndCorrectAnswers:
      'Multiple-Choice-Test (maximal {{correctAnswersCount}} korrekte Antworten)',
    questionWithoutMinutes: '{{questionCount}} Fragen - Ohne Zeitbegrenzung',
    confirmFinishExercise: 'Übung beenden?',
    doYouReallyWantToFinishYourExercise:
      'Willst du die Übung wirklich beenden?',
    correct: 'Gut',
    incorrect: 'Falsch',
    evaluate: 'Auswerten',
    exerciseSuccess: 'Übung bestanden',
    exerciseFailed: 'Leider durchgefallen',
    onBoardingDescriptionStep1:
      'Dein qualitätsgesicherter Lerninhalt für eine optimale Vorbereitung zum Bestehen deiner Prüfung',
    onBoardingDescriptionStep2:
      'Teste dein Wissen mit unseren Übungen und realitätsnahen Prüfungssimulationen',
    onBoardingDescriptionStep3:
      'Verfolge im Profilbereich deinen persönlichen Lernstand anhand detaillierter Statistiken',
    onBoardingDescriptionStep4:
      'Melde dich jetzt an, um die vielen Vorteile zu nutzen oder registriere dich kostenfrei und lerne auf deinem Tablet oder am PC',
    uploadImage: 'Bild hochladen',
    order: 'Order: {{count}}',
    question_count: 'Count: {{count}}',
    headline: 'Überschrift',
    points: 'Punkte',
    point: 'Punkt',
    openLearningArea: 'Thema öffnen',
    learningAreaHeader: 'Thema {{count}} / {{countTotal}}',
    exerciseHeader: 'Übung {{count}} / {{countTotal}}',
    exerciseName: 'Übung - {{name}}',
    examine: 'Prüfen',
    search: 'Suchen',
    enterQuestion: 'Frage eingeben ...',
    login: 'Anmelden',
    loggedInAs: 'Angemeldet als',
    loginPageTitle: 'Anmeldung',
    register: 'Registrieren',
    onboarding: 'Onboarding',
    learning: 'Lernen',
    imprint: 'Impressum',
    dataProtection: 'Datenschutzerklärung',
    conditionTerms: 'AGB',
    cardDescription:
      '{{countSubAreas}} Bereiche - {{countLearningContents}} Kapitel',
    cardSecondaryDescription: '{{points}} Punkte',
    exam: 'Prüfen',
    logout: 'Logout',
    profile: 'Profil',
    simulateExam: 'Prüfungssimulation',
    startExam: 'Prüfung starten',
    begin: 'Beginnen',
    abort: 'Abbrechen',
    copyright: '&copy; {{currentYear}} by APPucations',
    openLearningContent: 'Lernen',
    openSubArea: 'Bereich öffnen',
    learningContent: 'Bereich',
    learningContents: 'Bereiche',
    learningSubAreas: 'Kapitel',
    learningSubArea: 'Kapitel',
    subarea: 'Unterbereich',
    startExercise: 'Übung starten',
    countQuestion: '{{count}} Frage',
    countQuestions: '{{count}} Fragen',
    generalErrorTitle: 'Fehler',
    generalErrorDescription: 'Es ist ein allgemeiner Fehler aufgetreten:',
    loginErrorTitle: 'Authentifizierung ist fehlgeschlagen',
    loginErrorDescription:
      'Die Anmeldung ist fehlgeschlagen. Bitte überprüfe deinen Benutzernamen und Passwort.',
    statisticsHistory: 'Abgeschlossene Prüfungen',
    learningHistory: 'Abgeschlossene Übungen',
    statistics: 'Statistiken',
    examStatisticsOverview: 'Prüfungsstatistik',
    examStatisticsEmpty: 'Du hast noch keine Prüfungssimulationen absolviert',
    exerciseStatisticsOverview: 'Übungsstatistik',
    exerciseStatisticsEmpty: 'Du hast noch keine Übungen absolviert',
    examDescriptionWithMinute:
      'Diese Prüfungssimulation entspricht dem IHK-Standard. Zur Beantwortung aller Fragen steht {{duration}} Minute zur Verfügung. Die Ergebnisse werden in die Statistik aufgenommen und können dort detailliert nachvollzogen werden.',
    examDescriptionWithMinutes:
      'Diese Prüfungssimulation entspricht dem IHK-Standard. Zur Beantwortung aller Fragen stehen {{duration}} Minuten zur Verfügung. Die Ergebnisse werden in die Statistik aufgenommen und können dort detailliert nachvollzogen werden.',
    lostPasswordDescription:
      'Falls das Kennwort zurückgesetzt werden soll, ist hier die persönliche E-Mail-Adresse einzutragen.',
    firstname: 'Vorname',
    lastname: 'Nachname',
    save: 'Speichern',
    infoArea: 'Infobereich',
    feedBack: 'Feedback senden',
    changePersonalData: 'Persönliche Daten ändern',
    offlineMode: 'Offline-Modus',
    offlineModeContent:
      'Um die App ohne Internetverbindung zu nutzen, können einige Inhalte auf Ihr Gerät heruntergeladen werden. Hierbei wird Speicherplatz verbraucht. Bitte legen Sie fest, auf welche Features Sie offline Zugriff haben möchten.',
    offlineModeContentLoadingTime:
      'Dies kann einige Minuten in Anspruch nehmen.',
    offlineModeHeadline: 'Offline-Modus konfigurieren',
    offlineModeLearning: 'Lerninhalte',
    offlineModeLearningImages: 'inklusive <strong>Bildern</strong>',
    offlineModeLearningVideos: 'inklusive <strong>Videos</strong>',
    offlineModeExercises: 'Übungen und Prüfungen',
    offlineModeStatistics: 'Nutzerstatistiken',
    offlineModeDiskSpace: 'Benötigter Speicherplatz',
    download: 'Herunterladen',
    update: 'Aktualisieren',
    calculating: 'Wird berechnet',
    evaluation: 'Auswertung',
    errorReport: 'Fehlerbericht',
    versionLog: 'Version Log',
    statistic: 'Statistiken',
    account: 'Konto',
    info: 'Info',
    cancel: 'Abbrechen',
    further: 'Weiter',
    skip: 'Überspringen',
    configure: 'Konfigurieren',
    end: 'Beenden',
    done: 'Fertig',
    notNow: 'Jetzt nicht',
    start: 'Starten',
    tutorialHeader0: 'Willkommen!',
    tutorialHeader1: 'Lern-Modus',
    tutorialHeader2: 'Übungs- und Prüfungsmodus',
    tutorialHeader3: 'Statistik und Einstellungen',
    tutorialHeader4: 'Offline-Modus',
    tutorialHeader5: 'Viel Erfolg!',
    tutorialText0:
      'Für den optimalen Lernerfolg empfehlen wir dir eine kurze Einführung in die App. Falls du bereits mit anderen unserer Apps gelernt hast oder dich auch so zurechtfindest, kannst du das Tutorial jederzeit überspringen.',
    tutorialText1:
      'Bereite dich optimal auf deine bevorstehende Prüfung vor, indem du alle prüfungsrelevanten Lerninhalte durcharbeitest.',
    tutorialText2:
      'Teste dein erworbenes Wissen in verschiedenen Übungen oder starte direkt eine Prüfungssimulation, um unter realem Zeitdruck deine Kenntnisse auf die Probe zu stellen - so oft, wie du möchtest.',
    tutorialText3:
      'Behalte deinen Lernfortschritt im Blick und identifiziere Wissenslücken mit Hilfe deiner Statistiken. <br/><br/>Persönliche Daten oder der Offline-Modus können jederzeit angepasst werden.',
    tutorialText3Web:
      'Behalte deinen Lernfortschritt im Blick und identifiziere Wissenslücken mit Hilfe deiner Statistiken. <br/><br/>Persönliche Daten können hier jederzeit angepasst werden.',
    tutorialText4:
      'Dein Lernfortschritt wird auf unseren Servern gespeichert, um deinen lokalen Speicher frei zu halten. Um ohne Netzwerkverbindung lernen zu können, müssen einzelne Inhalte auf dein Gerät heruntergeladen werden. <br/><br/>Du kannst diesen Modus jetzt konfigurieren oder dies später über deine Profileinstellungen anpassen.',
    tutorialText5:
      'Du weißt nun alles, um mit dem Lernen zu beginnen. Wir wünschen dir viel Erfolg für deine bevorstehende Prüfung!',
    tutorialTextConfigurationOutro:
      'Du weißt nun alles, um mit dem Lernen zu beginnen. Um deinen Offline-Mode zu konfigurieren, leiten wir dich im Anschluss automatisch weiter. <br/><br/>Wir wünschen dir viel Erfolg für deine bevorstehende Prüfung!',
    saveChange: 'Änderungen speichern',
    licenceStatus: 'Lizenzstatus überprüfen',
    licenseHasExpired: 'Lizenz ist abgelaufen',
    licenseCancelBtn: 'Abbrechen',
    licenseBuyBtn: 'Jetzt verlängern',
    licenseHasExpiredDesc:
      'Sie können Ihre Lizenz verlängern, um den Zugriff auf die Vollversion beizubehalten.',
    checkLicenseStateDescription: 'Sie besitzen eine gültige Lizenz.',
    saveChangeHeadline: 'Änderungen übernehmen',
    saveChangeModalDescription:
      'Möchtest du die Änderungen an deinem Profil wirklich speichern?',
    saveChangeButtonLabel: 'Speichern',
    changePasswordUpdateSuccessfulHeadline: 'Passwort erfolgreich geändert',
    changePasswordUpdateSuccessful:
      'Dein neues Passwort wurde erfolgreichen gespeichert.',
    profileUpdateSuccessful:
      'Deine Profildaten wurden erfolgreich aktualisiert.',
    profileUpdateSuccessfulHeadline: 'Profil erfolgreich aktualisiert',
    changePasswordErrorTitle: 'Passwort ändern ist fehlgeschlagen',
    changePasswordErrorDescription:
      'Die Validierung der angegebenen Daten ist fehlgeschlagen.<br />' +
      '- Das angegebene Passwort ist zu kurz. Bitte verwende mindestens 5 Zeichen oder Zahlen. <br />',
    changePasswordErrorOldPassword:
      'Das alte Passwort ist nicht korrekt. <br /> <br /> ' +
      '(Fehler Code: 64)',
    changePassword: 'Passwort ändern',
    currentPassword: 'Aktuelles Passwort',
    newPassword: 'Neues Passwort',
    newPasswordRepeat: 'Neues Passwort wiederholen',
    checkLicenseState: 'Lizenzstatus überprüfen',
    checkLicenseErrorTitle: 'Lizenzstatus überprüfen',
    checkLicenseErrorDescription: 'Keine Valide Lizenz',
    feedbackHeadline: 'Feedback',
    feedbackDescription:
      'Wir sind an einer stetigen Weiter­entwicklung unserer\n' +
      '                    Software interessiert. Sollten Fehler oder Probleme aufgetreten sein, freuen wir uns über ein\n' +
      '                    Feedback.',
    sendFeedback: 'Feedback senden',
    feedbackErrorMessageTitle: 'Feedback senden fehlgeschlagen',
    feedbackErrorMessage:
      '<div><p>Die Validierung der angegebenen Daten ist fehlgeschlagen.</p><ul><li>Das Feedback ist nicht genau genug. Bitte konkretisiere dein Anliegen.</li></ul></div>',
    feedbackSuccessHeadline: 'Feedback senden',
    feedbackSuccessful: 'Feedback senden erfolgreich',
    general_information: 'Allgemeine Hinweise',
    instructions: 'Prüfungshinweise',
    thanks: 'Danksagung',
    partner: 'Partner',
    cancelExamWizard:
      'Möchtest du wirklich die Prüfung verlassen? Das Ergebnis wird trotzdem gespeichert.',
    practiceTopic: 'Thema üben',
    topic: 'Thema',
    infoLinkMessage:
      'In diesem Thema können {{points}} Punkte in der Endprüfung erreicht werden.',
    selectCoursePageTitle: 'Bitte wähle deinen Kurs aus',
    selectCourse: 'Kurs wechseln',
  },
};
