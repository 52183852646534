import React from 'react';
import classNames from 'classnames';
import './circle.scss';
import { NOOP } from '../../../libs/utils';

interface CircleProps {
  theme: string | null;
  backgroundTheme: string | null;
  onClick?: React.MouseEventHandler;
}

const Circle: React.FunctionComponent<CircleProps> = ({
  theme = 'primary',
  backgroundTheme = 'primary',
  onClick,
  children,
}) => {
  const className = classNames({
    circle: true,
    'circle--is-clickable': onClick !== NOOP,
    'circle--is-primary': theme === 'primary',
    'circle--is-secondary': theme === 'secondary',
    'circle--is-tertiary': theme === 'tertiary',
    'circle--is-success': theme === 'success',
    'circle--is-danger': theme === 'danger',
    'circle--is-primary-bg': backgroundTheme === 'primary',
    'circle--is-secondary-bg': backgroundTheme === 'secondary',
    'circle--is-tertiary-bg': backgroundTheme === 'tertiary',
    'circle--is-success-bg': backgroundTheme === 'success',
    'circle--is-danger-bg': backgroundTheme === 'danger',
  });

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

export default Circle;
