import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { RoundButton, Button, Headline } from '../../atoms';
import { NOOP } from '../../../libs/utils';
import '../exerciseCompleted/exerciseCompleted.scss';

export default function ExamCompleted({
  points = 0,
  pointsTotal = 0,
  navigateToDetails = NOOP,
  onClickTryAgain = NOOP,
  isSuccess = false,
}) {
  const { t } = useTranslation();
  const className = classNames({
    'exercise-completed__header': true,
    'exercise-completed__header--is-danger': !isSuccess,
    'exercise-completed__header--is-success': isSuccess,
  });

  return (
    <div className="exercise-completed">
      <div className={className}>
        <div className="exercise-completed__header-icon">
          <RoundButton
            iconSize={110}
            transparent
            icon={isSuccess ? 'Check' : 'X'}
          />
        </div>
        <div className="exercise-completed__header-description">
          {isSuccess ? t('examSuccess') : t('examFailed')}
        </div>
      </div>
      <div className="exercise-completed__panel">
        <div className="exercise-completed__panel-header">
          <Headline type="h2">{`${points} / ${pointsTotal}`}</Headline>
        </div>
        <div className="exercise-completed__panel-actions">
          <Button
            onClick={navigateToDetails}
            text={t('details')}
            theme="tertiary"
            removeBorder
            transparent
          />
          <Button
            onClick={onClickTryAgain}
            text={t('tryAgain')}
            theme="tertiary"
            removeBorder
            transparent
          />
        </div>
      </div>
    </div>
  );
}
