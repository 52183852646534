import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import './baseModal.scss';
import { NOOP } from 'libs/utils';

interface BaseModalProps {
  baseClass?: string;
  modalIsOpen: boolean;
  setModalIsOpen?: Function;
  contentLabel?: string;
  childrenBefore?: JSX.Element;
  className?: string;
}
const BaseModal: React.FunctionComponent<BaseModalProps> = ({
  baseClass,
  modalIsOpen,
  setModalIsOpen = NOOP,
  contentLabel,
  children,
  childrenBefore,
  className,
}) => {
  Modal.setAppElement('#root');
  const { t } = useTranslation();
  return (
    <div className={baseClass}>
      {childrenBefore}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className={className ? className : `${baseClass}-content`}
        overlayClassName={`${baseClass}-overlay`}
        bodyOpenClassName={`${baseClass}--is-modal-open`}
        contentLabel={t(contentLabel || '')}
        shouldFocusAfterRender={false}>
        <div className={`${baseClass}-content__body`}>{children}</div>
      </Modal>
    </div>
  );
};

export default BaseModal;
