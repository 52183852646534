import i18n from 'i18next';
import { initReactI18next, useTranslation, getI18n as nextGetI18n } from 'react-i18next';
import de from './languages/de';
import en from './languages/de'; // en not needed

export default function I18n(translationFilesObject = { de, en }) {
    return i18n
        .use(initReactI18next)
        .init({
            resources: translationFilesObject,
            lng: 'de',
            fallbackLng: 'de',
            interpolation: {
                escapeValue: false
            }
        });
}

function getTranslations() {
    const defaultLanguage = window.navigator.language.split("-")[0]
    const langs = {
        en: en.translation,
        de: de.translation
    }
    return langs[defaultLanguage] ? langs[defaultLanguage] : langs['de'];
}

export function t(name) {
    return getTranslations()[name] ? getTranslations()[name] : name;
}

export function changeLanguage(lng) {
    nextGetI18n().changeLanguage(lng);
}
