import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { ExamSplash } from '../../../common/pages';
import Page from '../../../components/pages';
import { prepareExam, startExam } from '../../../redux/actions/exam';
import '../../../theme/pages/examStart.scss';
import { Banner } from '../../../common/molecules';
import ExamQueries from '../../../libs/ExamQueries';
import { useIonViewWillEnter } from '@ionic/react';

interface ExamStartProps {
  startExam: (exam) => void;
  prepareExam: (exam) => void;
  exam: any;
  product: any;
  offlineMode: any;
}

const ExamStart: React.FC<ExamStartProps> = ({
  startExam,
  exam,
  product,
  prepareExam,
  offlineMode,
}) => {
  const history = useHistory();
  const instanceExamQueries = new ExamQueries(offlineMode.data);
  const examData = instanceExamQueries.getExam();
  const callAgain = window.location.href.indexOf('callAgain') > -1;
  // useEffect(() => {
  //   prepareExam(examData);
  // }, [callAgain]); // eslint-disable-line

  useIonViewWillEnter(() => prepareExam(examData));

  function handleStartExam() {
    setTimeout(() => {
      startExam(examData);
      history.push('/exam/wizard');
    }, 250);
  }

  return (
    <Page isSplash>
      {exam.questions.length === 0 ? (
        <Banner headline={'not found'} onClick={() => {
          history.goBack()
        }}>
          <h2>Kein Prüfung verfügbar</h2>
        </Banner>
      ) : (
        <ExamSplash
          key={`exam-start-${exam.timer.startDate}`}
          exam={exam}
          onClick={handleStartExam}
          timeInMinutes={offlineMode.data?.newSettings?.exam_time}
          questionCount={offlineMode.data?.newSettings?.exam_max_questions}
          correctAnswersCount={offlineMode.data?.newSettings?.exam_show_right_answers}
        />
      )}
    </Page>
  );
};

export default connect(
  ({ exam, product, offlineMode }) => ({ exam, product, offlineMode }),
  {
    startExam,
    prepareExam,
  },
)(ExamStart);
