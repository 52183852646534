import { cloneDeep } from 'lodash';
import { Constants } from '../constants';

const storedQueue = localStorage.getItem('results_queue');

const initialState = storedQueue
  ? { list: JSON.parse(storedQueue) }
  : { list: [] };

export default function queue(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_QUEUE: {
      const newList = cloneDeep(state.list);
      newList.push(action.payload);
      localStorage.setItem('results_queue', JSON.stringify(newList));
      return {
        list: newList,
      };
    }
    case Constants.REMOVE_FROM_QUEUE: {
      let newList = cloneDeep(state.list);
      newList.splice(newList.indexOf(action.payload), 1);
      localStorage.setItem('results_queue', JSON.stringify(newList));
      return {
        list: newList,
      };
    }
    default:
      return state;
  }
}
