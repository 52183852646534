import React, { useEffect, useState } from 'react';
import { IonRouterOutlet, isPlatform } from '@ionic/react';
import { Route, Redirect, useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setLastLocation } from '../redux/actions/lastLocation';
import { setAppMode } from '../redux/actions/appDefaults';
import { getAndStoreLicenseAction } from '../redux/actions/license';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { smartphoneWidth } from '../frontendConfig';
import NavigationTab from './navigationTab';
import { LearnIcon } from '../common/atoms/icon/learn';
import { PracticeIcon } from '../common/atoms/icon/practice';
import { RoundButton } from '../common/atoms';
import DataLoader from '../pages/data-loader/dataLoader';
import { setProduct } from '../redux/actions/product';
import PrivatePageWrapper from 'common/pages/privatePageWrapper';
import { RouterProps } from './types';
import { routeConfig } from './routeConfig';
import Tutorial from 'pages/tutorial/tutorial';

function getNavigationRoutes(token, translate, product) {
  const isAuthorizedRouteHidden = token === null;
  return {
    left: [
      {
        keyName: 'appIcon',
        hidden: isAuthorizedRouteHidden,
        path: '/learning',
        activePaths: [],
        name: product && product.name,
      },
    ],
    center: [
      {
        name: translate('learning'),
        keyName: 'learning',
        hidden: isAuthorizedRouteHidden,
        icon: <LearnIcon />,
        activePaths: ['/learning'],
        path: '/learning',
      },
      {
        name: translate('exam'),
        keyName: 'exam',
        hidden: isAuthorizedRouteHidden,
        icon: <PracticeIcon />,
        activePaths: ['/exam', '/exercise'],
        path: '/exam',
      },
    ],
    right: [
      {
        name: '',
        keyName: 'profile',
        hidden: isAuthorizedRouteHidden,
        icon: <RoundButton icon="PersonFill" theme="tertiary" />,
        path: '/profile',
        activePaths: [
          '/profile',
          '/change-password',
          '/delete-user-data',
          '/feedback',
          '/info-pages',
          '/terms-of-service',
          '/data-protection-policy',
          '/imprint',
          '/offline-settings',
          '/select-course',
        ],
      },
    ],
  };
}

function mergeNavigationItems(token, t, product) {
  const mergedItems: any = [];
  Object.values(getNavigationRoutes(token, t, product)).forEach((itemArray) =>
    itemArray.forEach((item: any) => mergedItems.push(item)),
  );
  return mergedItems;
}

const Routes: React.FC<RouteComponentProps<{}> & RouterProps> = ({
  user,
  lastLocation,
  setLastLocation,
  product,
  offlineMode,
  license,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [isTop, setIsTop] = useState(window.innerWidth > smartphoneWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsTop(window.innerWidth > smartphoneWidth);
    });
  }, []);

  useEffect(() => {
    const mergedItems: any = mergeNavigationItems(user.token, t, product);
    const link = mergedItems.find((link) => {
      return location.pathname.includes(link.path);
    });
    if (link && !link.hidden) {
      setLastLocation(location);
    }
    // }, [location, product, setLastLocation, t, user.token]);
  }, [location]); //eslint-disable-line
  let pathname: any =
    window.location.pathname === '/'
      ? 'onboarding'
      : window.location.pathname.replace('/', '');
  let pathClassName = 'page-' + pathname;
  if (pathname.indexOf('/') > -1) {
    pathname = pathname.split('/');
    pathClassName = 'page-' + pathname[0];
    if (pathname.length > 1) {
      pathClassName = pathClassName + ' ' + pathClassName + '-' + pathname[1];
    }
  }

  const urlContains = (path: string) => window.location.href.indexOf(path) > -1;

  const isOnAuthLegalPage = () =>
    (urlContains('data-protection-policy') ||
      urlContains('imprint') ||
      urlContains('terms-of-service')) &&
    !user.token;

  let ionRouterClassNames = pathClassName + ' ';
  ionRouterClassNames += !isTop ? ' is-mobile ' : '';
  ionRouterClassNames +=
    urlContains('onboarding') ||
    urlContains('lost-password') ||
    urlContains('login') ||
    urlContains('register') ||
    isOnAuthLegalPage()
      ? 'is-auth '
      : '';
  ionRouterClassNames += isPlatform('tablet') ? ' tablet ' : '';
  ionRouterClassNames += !mergeNavigationItems(user.token, t, product).find(
    (item) => !item.hidden,
  )
    ? 'hidden-nav-header'
    : '';

  useEffect(() => {
    setTimeout(() => {
      const ionRouterOutletElement = document.getElementById(
        'ion-router-outlet',
      );
      if (ionRouterOutletElement) {
        let classes = ionRouterClassNames.split(' ');
        ionRouterOutletElement.className = 'hydrated';
        classes.map((className) => {
          if (className.length > 1)
            ionRouterOutletElement.classList.add(className);
        });
        ionRouterClassNames = '';
      }
    }, 300);
  }, [window.location.pathname]);

  const paths = location.pathname.split('/');

  const examRunning = paths.includes('exam') && paths.includes('wizard');
  const exerciseRunning =
    paths.includes('exercise') && paths.includes('wizard');

  const userHasAlreadyLoggedIn =
    localStorage.getItem('login') || !process.env.REACT_APP_PRODUCT_ID;

  return (
    <>
      <IonRouterOutlet className={ionRouterClassNames} id="ion-router-outlet">
        <Route exact path="/">
          {user.token ? (
            <Redirect to={lastLocation.pathname} />
          ) : (
            <Redirect to={userHasAlreadyLoggedIn ? '/login' : '/onboarding'} />
          )}
        </Route>
        {routeConfig.map(
          ({
            path,
            type,
            component,
            requiresOfflineData,
            alternativeRoute,
          }) => (
            <Route exact path={path} key={path}>
              <PrivatePageWrapper
                path={path}
                type={type}
                component={component}
                isReachable={requiresOfflineData ? !!offlineMode.data : true}
                alternativeRoute={alternativeRoute}
              />
            </Route>
          ),
        )}
      </IonRouterOutlet>
      <NavigationTab
        hidden={
          examRunning ||
          exerciseRunning ||
          window.location.href.includes('logout') ||
          (license && !license?.product_id)
        }
        isTop={isTop}
        navItems={getNavigationRoutes(user.token, t, product)}
      />
      {!!user.token && !user.onboardingSeen && <Tutorial />}

      {!!user.token && !offlineMode.data && license && license?.product_id && (
        <DataLoader />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    product: state.product,
    lastLocation: state.lastLocation,
    appDefaults: state.appDefaults,
    offlineMode: state.offlineMode,
    license: state.license,
  };
};

export default connect(mapStateToProps, {
  setLastLocation,
  setAppMode,
  setProduct,
  getAndStoreLicense: getAndStoreLicenseAction,
})(withRouter(Routes));
