import React, { useEffect, useState } from 'react';
import './stopwatch.scss';
import { Progress } from '../../atoms';

const StopWatch = ({ timer }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(new Date()));
  const [percent, setPercent] = useState(
    Math.trunc(
      Math.floor(
        100 -
          ((Date.now() - timer.startDate.getTime()) /
            (timer.endDate.getTime() - timer.startDate.getTime())) *
            100,
      ),
    ),
  );
  const [
    countdownInterval,
    setCountdownInterval,
  ] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    function tick() {
      const endTime = timer.endDate.getTime();
      const startTime = timer.startDate.getTime();
      const now = new Date();
      const nowTime = now.getTime();
      setTimeLeft(calculateTimeLeft(now));
      setPercent(
        Math.trunc(
          Math.floor(
            100 - ((nowTime - startTime) / (endTime - startTime)) * 100,
          ),
        ),
      );
    }
    if (timeLeft <= 0) {
      if (countdownInterval) {
        clearInterval(countdownInterval);
        setPercent(0);
      }
      timer.onExpire();
    } else if (timer.isRunning && !countdownInterval) {
      setCountdownInterval(setInterval(tick, 1000)); // CHECK
    }
  }, [calculateTimeLeft, countdownInterval, timeLeft, timer]);

  function calculateTimeLeft(startDate) {
    return (
      Math.trunc((timer.endDate.getTime() - startDate.getTime()) / 1000) + 1
    );
  }

  function splitTimeInSecondsInParts(time) {
    let r = {
      hours: '0',
      minutes: '0',
      seconds: '0',
    };
    const definitions = {
      hours: 3600,
      minutes: 60,
      seconds: 1,
    };

    Object.keys(definitions).forEach(function (key) {
      r[key] = `${Math.floor(time / definitions[key])}`.padStart(2, '0');
      time -= r[key] * definitions[key];
    });

    return r;
  }

  function getFormattedTimeLeft() {
    const formattedTime = splitTimeInSecondsInParts(timeLeft);

    return (
      formattedTime.hours +
      ':' +
      formattedTime.minutes +
      ':' +
      formattedTime.seconds
    );
  }

  return (
    <div className="stopwatch">
      <div className="stopwatch__progress">
        <Progress value={percent} />
        <div className="stopwatch__progress-time">{getFormattedTimeLeft()}</div>
      </div>
    </div>
  );
};

export default React.memo(StopWatch);
