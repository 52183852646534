import React, { useState } from 'react';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';
import './onboarding.scss';
import LearningImage from './LearningImage';
import PersoImage from './PersoImage';
import ScreensImage from './ScreensImage';
import PracticeImage from './PracticeImage';
import { RoundButton, Button } from '../../atoms';
import { t } from '../../../config/translation';
import Footer from 'components/pages/footers';

export default function Onboarding({ onLogin, onRegister }) {
  const [step, setStep] = useState(0);
  function calculateStep(next = true) {
    let currentStep = next ? step + 1 : step - 1;

    if (currentStep > steps.length - 1) {
      currentStep = steps.length - 1;
    } else if (currentStep < 0) {
      currentStep = 0;
    }

    setStep(currentStep);
  }

  const steps = [
    <>
      <p>{t('onBoardingDescriptionStep1')}</p>
      <LearningImage />
    </>,
    <>
      <p>{t('onBoardingDescriptionStep2')}</p>
      <PracticeImage />
    </>,
    <>
      <p>{t('onBoardingDescriptionStep3')}</p>
      <PersoImage />
    </>,
    <>
      <p>{t('onBoardingDescriptionStep4')}</p>
      <ScreensImage />
    </>,
  ];

  const handlers = useSwipeable({
    onSwipedLeft: () => calculateStep(),
    onSwipedRight: () => calculateStep(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const className = classNames({
    onboarding: true,
    'onboarding--step1': step === 0,
    'onboarding--step2': step === 1,
    'onboarding--step3': step === 2,
    'onboarding--step4': step === 3,
  });

  return (
    <div className={className} {...handlers}>
      <div className="onboarding__content">{steps[step]}</div>
      <div className="onboarding__arrows">
        <RoundButton
          theme="tertiary"
          transparent
          icon="ChevronLeft"
          iconSize={26}
          onClick={() => calculateStep(false)}
          style={{
            visibility: step !== 0 ? 'visible' : 'hidden',
            position: 'relative',
            right: '25px',
          }}
        />
        {step !== 3 && (
          <RoundButton
            theme="tertiary"
            transparent
            icon="ChevronRight"
            iconSize={26}
            onClick={() => calculateStep()}
            style={{
              position: 'relative',
              left: '25px',
            }}
          />
        )}
      </div>
      <div className="onboarding__footer">
        <div className="onboarding__progress-dots">
          <ul>
            <li className={step === 0 ? 'current' : ''} />
            <li className={step === 1 ? 'current' : ''} />
            <li className={step === 2 ? 'current' : ''} />
            <li className={step === 3 ? 'current' : ''} />
          </ul>
        </div>
        <span className="low-opacity">
          <Button
            removeBorder
            transparent
            slim
            stretched
            theme="light"
            onClick={onRegister}
            text={t('register')}
          />
        </span>
        <span className="login-btn">
          {step !== 3 ? (
            <Button
              removeBorder
              transparent
              slim
              stretched
              theme="light"
              onClick={onLogin}
              text={t('login')}
            />
          ) : (
            <Button theme="primary" onClick={onLogin} text={t('login')} />
          )}
        </span>
      </div>
    </div>
  );
}
