import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ProductState } from 'redux/reducers/types';
import { Banner } from 'common/molecules';
import Page from 'components/pages';

interface InterfaceInfoPagesProps {
  product: ProductState;
}

interface PageParamOptions {
  page: 'general_information' | 'instructions' | 'partner' | 'thanks';
}

const InfoPages: React.FunctionComponent<InterfaceInfoPagesProps> = ({
  product,
}) => {
  const history = useHistory();
  const { page } = useParams<PageParamOptions>();
  const [showImage, setShowImage] = useState(false);
  const { t } = useTranslation();

  const pagesByContent = {
    general_information: product.general_instructions,
    instructions: product.written_exam_instructions,
    partner: product.partner_page,
    thanks: product.thanks_page,
  };

  function setAttributes(el, attrs) {
    for (var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  }

  setTimeout(() => {
    // @ts-ignore
    const imagesSelect = document.querySelectorAll(
      '.banner_body-content .learning-content img, .banner_body-content .image img',
    );

    let imagesArray = Array.from(imagesSelect);
    imagesArray.forEach((imageElement) => {
      imageElement.addEventListener('click', () => {
        setShowImage(true);
        let wrapper = document.getElementById('image-container');

        if (wrapper) {
          wrapper.addEventListener('click', () => {
            setShowImage(false);
          });

          let image = document.createElement('img');
          if (image) {
            setAttributes(image, {
              src: imageElement.getAttribute('src'),
              alt: 'figure',
            });
            // image.style.maxWidth = 'absolute';
            // image.style.top = '25%';

            wrapper.innerHTML = '';
            wrapper.appendChild(image);
          }
        }
      });
    });
  }, 100);

  return (
    <>
      {showImage && <div id="image-container" />}
      <Page>
        <div className="banner-wrapper banner-infopage">
          <Banner headline={t(page)} onClick={() => {
            history.goBack()
          }}>
            <div
              dangerouslySetInnerHTML={{
                __html: pagesByContent[page] || '',
              }}
            />
          </Banner>
        </div>
      </Page>
    </>
  );
};

export default connect(({ product }) => ({ product }))(InfoPages);
