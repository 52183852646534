import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import { NOOP } from 'libs/utils';
import { RoundButton } from 'common/atoms';
import { Copyright } from 'common/molecules';
import { ProductState } from 'redux/reducers/types';

interface InterfaceInfoAreaProps {
  handleGoBack?: () => void;
  product: ProductState;
}

const InfoArea: React.FunctionComponent<InterfaceInfoAreaProps> = ({
  handleGoBack = NOOP,
  product,
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <>
      <div className="sidebar__information-box">
        <div className="sidebar__links has-icon">
          <button onClick={handleGoBack} className="back-button">
            <RoundButton icon="ChevronLeft" transparent iconSize={24} />
            <span>{t('back')}</span>
          </button>
          {!!product.general_instructions && (
            <button onClick={() => push('/info-pages/general_information')}>
              <span>{t('general_information')}</span>
              <RoundButton icon="ChevronRight" transparent iconSize={24} />
            </button>
          )}
          {!!product.written_exam_instructions && (
            <button onClick={() => push('/info-pages/instructions')}>
              <span>{t('instructions')}</span>
              <RoundButton icon="ChevronRight" transparent iconSize={24} />
            </button>
          )}
          {!!product.thanks_page && (
            <button onClick={() => push('/info-pages/thanks')}>
              <span>{t('thanks')}</span>
              <RoundButton icon="ChevronRight" transparent iconSize={24} />
            </button>
          )}
          {!!product.partner_page && (
            <button onClick={() => push('/info-pages/partner')}>
              <span>{t('partner')}</span>
              <RoundButton icon="ChevronRight" transparent iconSize={24} />
            </button>
          )}
        </div>
      </div>
      <Copyright />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  product: state.product,
});

export default connect(mapStateToProps)(InfoArea);
