import React from 'react';
import { t } from '../../../config/translation';
import { Button, Link } from '../../atoms';
import './lostPasswordForm.scss';

interface LostPasswordFormProps {
  editable?: {
    email: {
      labelText: string;
    };
    password: {
      labelText: string;
    };
    onChange: React.ChangeEventHandler<HTMLInputElement>;
  };
  values?: {
    email: string;
    password: string;
  };
  save?: React.MouseEventHandler;
}

const LostPasswordForm: React.FunctionComponent<LostPasswordFormProps> = ({
  editable,
  values,
  save,
}) => {
  return editable && values ? (
    <>
      <div className="lost-password-form__inputs">
        <div className="lost-password-form__field">
          <span className="label">{editable.email.labelText}</span>
          <input
            value={values.email}
            onChange={editable.onChange}
            {...editable.email}
          />
        </div>
      </div>
      <div className="lost-password-form__actions">
        <Button onClick={save} text={t('resetPassword')} />
        <Link to="/login">{t('gotoLogin')}</Link>
      </div>
    </>
  ) : null;
};

export default LostPasswordForm;
