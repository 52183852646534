import React from 'react';
import classnames from 'classnames';
import { TextArea } from '../';
import './paragraph.scss';
import { NOOP } from '../../../libs/utils';

interface ParagraphProps {
  editable?: {
    isEditMode: boolean;
    onChange: Function;
    name: 'string';
    labelText: string;
  };
  type?: string;
  children: string | JSX.Element | any[];
}

const Paragraph: React.FunctionComponent<ParagraphProps> = ({
  children,
  editable = { isEditMode: false, onChange: NOOP, name: '', labelText: '' },
  type,
}) => {
  const className = classnames({
    paragraph: true,
    'paragraph--is-danger': type === 'danger',
    'paragraph--is-success': type === 'success',
  });

  return !editable.isEditMode ? (
    <p className={className}>{children}</p>
  ) : (
    <TextArea
      value={`${children}`}
      name={editable.name}
      onChange={editable.onChange}
      labelText={editable.labelText}
    />
  );
};

export default Paragraph;
