export const Constants = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SELECT_PRODUCT: 'SELECT_PRODUCT',
  SET_PRODUCT: 'SET_PRODUCT',
  RESET_PRODUCT: 'RESET_PRODUCT',
  SET_LAST_LOCATION: 'SET_LAST_LOCATION',
  RESET_LAST_LOCATION: 'RESET_LAST_LOCATION',
  SET_APP_ELEMENT: 'SET_APP_ELEMENT',
  RESET_APP_ELEMENT: 'RESET_APP_ELEMENT',
  PREPARE_EXAM: 'PREPARE_EXAM',
  START_EXAM: 'START_EXAM',
  TOGGLE_EXAM_ANSWER: 'TOGGLE_EXAM_ANSWER',
  STOP_EXAM: 'STOP_EXAM',
  SET_EXAM_RESULT: 'SET_EXAM_RESULT',
  RESET_EXAM: 'RESET_EXAM',
  PREPARE_EXERCISE: 'PREPARE_EXERCISE',
  START_EXERCISE: 'START_EXERCISE',
  TOGGLE_EXERCISE_ANSWER: 'TOGGLE_EXERCISE_ANSWER',
  STOP_EXERCISE: 'STOP_EXERCISE',
  SET_EXERCISE_RESULT: 'SET_EXERCISE_RESULT',
  RESET_EXERCISE: 'RESET_EXERCISE',
  SET_GLOBAL_ERROR: 'SET_GLOBAL_ERROR',
  RESET_GLOBAL_ERRORS: 'RESET_GLOBAL_ERRORS',
  SET_APP_MODE: 'SET_APP_MODE',
  UPDATE_USER: 'UPDATE_USER',
  SET_LICENSE: 'SET_LICENSE',
  SET_OFFLINE_MODUS_DATA: 'SET_OFFLINE_MODUS_DATA',
  SET_QUEUE: 'SET_QUEUE',
  REMOVE_FROM_QUEUE: 'REMOVE_FROM_QUEUE',
  SET_OFFLINE_MODUS_RESULT: 'SET_OFFLINE_MODUS_RESULT',
  RESET_OFFLINE_MODUS_DATA: 'RESET_OFFLINE_MODUS_DATA',
  SET_LEGAL_DATA: 'SET_LEGAL_DATA',
  SET_TRACKINGS: 'SET_TRACKINGS',
  ADD_TRACKING: 'ADD_TRACKING',
  UPDATE_IS_OFFLINE: 'UPDATE_IS_OFFLINE',
  SET_ONBOARDING_SEEN: 'SET_ONBOARDING_SEEN'
};
