import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Button } from 'common/atoms';
import NoWifiIcon from '../../assets/icons/no-wifi-icon.svg';
import { setOfflineData } from 'redux/actions/offlineModus';
import { OfflineDataType } from 'pages/data-loader/types';
import { Error } from 'common/molecules/modal';
import './NoDataPage.scss';
import { OfflineModeState } from 'redux/reducers/types';

interface NoDataPageProps {
  color: string;
  offlineMode: OfflineModeState;
  type: 'exercise' | 'learning' | 'profile';
  user: { product_id: string };
  setOfflineData: (data: OfflineDataType | null) => void;
  updateIsOffline: (data: boolean) => void;
}

const NoDataPage: React.FunctionComponent<NoDataPageProps> = ({
  color,
  type,
  setOfflineData,
}) => {
  const [networkError, setHasNetworkError] = useState(false);

  const handleRefetchData = () => {
    setOfflineData(null);
  };

  return (
    <div
      className={`no-data-wrapper ${
        type === 'profile' ? 'no-data-profile' : ''
      }`}
      style={{ backgroundColor: color }}>
      <img src={NoWifiIcon} className="no-data-icon" alt="" />
      <h3>Keine Daten</h3>
      {type === 'profile' ? (
        <p>Statistiken offline nicht verfügbar.</p>
      ) : (
        <p>
          Du scheinst offline zu sein. Um den{' '}
          {type === 'exercise' ? 'Übungsmodus' : 'Lernmodus'} ohne
          Internetverbindung nutzen zu können, musst Du die Inhalte vorher über
          die Einstellungen herunterladen.
        </p>
      )}
      <Button
        loadingColor={color}
        onClick={handleRefetchData}
        text="Neu laden"
        style={{ background: '#fff', color, marginBottom: 60, maxWidth: 240 }}
      />
      {networkError && (
        <Error
          title={'Kein Netzwerk gefunden'}
          description={
            'Bitte überprüfe Deine Internetverbindung oder versuche es später erneut.'
          }
          modalIsOpen={true}
          setModalIsOpen={() => setHasNetworkError(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    offlineMode: state.offlineMode,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setOfflineData: (data: OfflineDataType | null) =>
    dispatch(setOfflineData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoDataPage);
