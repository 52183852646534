import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import Page from '../../../../components/pages';
import { Banner } from '../../../../common/molecules';
import { ResultPage } from '../../../../common/pages';
import { ResultItem } from 'types/result';

interface StatisticsItemProps {
  offlineMode: any;
}

const StatisticsItem: React.FC<StatisticsItemProps> = ({ offlineMode }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { resultId } = useParams();
  const hasProduct = !!offlineMode.data?.results.length;
  const result = hasProduct
    ? offlineMode.data.results.find(
        (result: ResultItem) => result.id == resultId,
      )
    : null;

  function goToList() {
    history.goBack();
  }

  return (
    <Page title="statistics detail">
      <div className="banner-wrapper banner-feedback banner-statistics">
        <Banner headline={t('statistics')} onClick={goToList}>
          {result && <ResultPage result={result} />}
        </Banner>
      </div>
    </Page>
  );
};

export default connect(
  ({ offlineMode }) => ({
    offlineMode,
  }),
  null,
)(StatisticsItem);
