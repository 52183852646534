import gql from 'graphql-tag';

var defaultProperties = `
  id
  email
  first_name
  last_name
  avatar
  address {
    id
    street
    number
    postal_code
    country
}`;
export default class User {
  static getQuery(properties) {
    properties = properties || defaultProperties;
    return gql`
        query getUser($id: Int!) {
          user(id: $id) {
            ${properties}
          }
        }`;
  }

  static createMutation(properties) {
    properties = properties || defaultProperties;
    return gql`
        mutation createUser(
            $first_name: String!
            $last_name: String!
            $email: String!
            $avatar: String
            $password: String!
          ) {
            createUser(
              first_name: $first_name
              last_name: $last_name
              email: $email
              avatar: $avatar
              password: $password
            ) {
              ${properties}
            }
          }`;
  }

  static updateMutation(properties) {
    properties = properties || defaultProperties;

    return gql`mutation updateUser(
          $id: Int!
          $first_name: String
          $last_name: String
          $email: String
          $avatar: String
          $password: String,
          $permissions: [PermissionInput]
          $roles: [RoleInput]
        ) {
          updateUser(
            id: $id
            first_name: $first_name
            last_name: $last_name
            email: $email
            avatar: $avatar
            password: $password
            permissions: $permissions
            roles: $roles
          ) {
            ${properties}
          }
        }
      `;
  }

  static deleteMutation(properties) {
    properties = properties || defaultProperties;

    return gql`mutation deleteUser($id: Int!) {
        deleteUser(id: $id) {
          ${properties}
        }
      }
    `;
  }

  static searchAllByQueryAndPage(properties) {
    properties = properties || `
      totalCount
      page
      data {
        id
        email
        first_name
        last_name
        deleted_at
      }`;

    return gql`query getUser($query: String, $page: Int) {
        userSearch(query: $query, page: $page) {
          ${properties}
        }
      }`;
  }

  static batchCreateWithLicensesMutation(properties) {
    properties = properties || defaultProperties;

    return gql`
      mutation batchCreateWithLicensesMutation(
        $users: [UserInput]
        $device_limit: Int
        $product_id: Int
        $date_of_activation: String
        $time_to_live: String
      ) {
        batchCreateWithLicensesMutation(
          users: $users
          device_limit: $device_limit
          product_id: $product_id
          date_of_activation: $date_of_activation
          time_to_live: $time_to_live
        ) {
          ${properties}
        }
      }
    `;
  }
}
