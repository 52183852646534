import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { ExamCompleted } from '../../../common/pages';
import Page from '../../../components/pages';

interface ExamFinishProps {
  exam: any;
}

const ExamFinish: React.FC<ExamFinishProps> = ({ exam }) => {
  const history = useHistory();
  const isSuccess = exam.result && exam.result.is_success;

  function navigateToDetails(result) {
    history.push(`/profile/statistics/result/${result.id}`);
  }

  function onClickTryAgain() {
    history.push('/exam/start?callAgain=1', { callAgain: true });
  }

  return (
    <Page type={isSuccess ? 'success' : 'danger'} hideFooter>
      {exam.result && (
        <ExamCompleted
          points={exam.result.points_achieved}
          pointsTotal={exam.result.points}
          isSuccess={isSuccess}
          navigateToDetails={() => navigateToDetails(exam.result)}
          onClickTryAgain={onClickTryAgain}
        />
      )}
    </Page>
  );
};

export default connect(({ exam }) => ({ exam }), null)(ExamFinish);
