import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Span, Headline, Divider, Button } from '../../atoms';
import { Actions } from '../';
import { NOOP } from '../../../libs/utils';
import './card.scss';

export interface ContentCardProps {
  currentCount: number;
  totalCount: number;
  headline: string;
  description: string;
  secondaryDescription?: string;
  buttonText: string;
  styleDescription?: boolean;
  onClick: () => void;
}
const LearningAreaCard: React.FunctionComponent<ContentCardProps> = ({
  headline = null,
  points,
  order,
  question_count,
  description,
  secondaryDescription = false,
  buttonText,
  onClick,
  save,
  remove,
  id,
  styleDescription = false,
  withMarker = true,
  showActionButtons = false,
  showPoints = false,
  editable,
  hasErrors = {},
  values = {},

  // new
  currentCount,
  totalCount,
}) => {
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);

  editable = editable || {
    isEditMode: false,
    onChange: NOOP,
    headline: {
      name: 'headline',
      labelText: t('headline'),
    },
    points: {
      name: 'points',
      labelText: t('points'),
    },
    order: {
      name: 'order',
      labelText: t('order'),
    },
    question_count: {
      name: 'question_count',
      labelText: t('question_count'),
    },
  };

  const closeForm = function () {
    setIsEditMode(false);
  };

  return (
    <div className="content-card">
      {showActionButtons && (
        <Actions
          isEditMode={isEditMode}
          edit={setIsEditMode}
          id={id}
          save={(data) => {
            save(data, closeForm);
          }}
          remove={(id) => {
            remove(id);
            setIsEditMode(false);
          }}
        />
      )}
      <div className="content-card-header">
        <Span uppercase type="tertiary">
          <span className="tablet-only">{t('topic')} </span>
          {currentCount} / {totalCount}
        </Span>
      </div>
      <div className="content-card-body">
        {(showPoints || isEditMode) && (
          <>
            <div className="content-card-body__points">
              <Span
                hasErrors={hasErrors['points'] ? hasErrors['points'] : null}
                editable={{
                  ...editable,
                  ...editable.points,
                  isEditMode: isEditMode,
                }}>
                {isEditMode
                  ? points
                  : points === 1
                  ? t('countPoint', {
                      count: points,
                    })
                  : t('countPoints', {
                      count: points,
                    })}
              </Span>
            </div>
            <div className="content-card-body__points">
              <Span
                hasErrors={hasErrors['order'] ? hasErrors['order'] : null}
                editable={{
                  ...editable,
                  ...editable.order,
                  isEditMode: isEditMode,
                }}>
                {isEditMode
                  ? order
                  : order === 1
                  ? t('order', {
                      count: order,
                    })
                  : t('order', {
                      count: order,
                    })}
              </Span>
            </div>
            <div className="content-card-body__points">
              <Span
                editable={{
                  ...editable,
                  ...editable.question_count,
                  isEditMode: isEditMode,
                }}>
                {isEditMode
                  ? question_count
                  : question_count === 1
                  ? t('question_count', {
                      count: question_count,
                    })
                  : t('question_count', {
                      count: question_count,
                    })}
              </Span>
            </div>
          </>
        )}

        {headline !== null && (
          <div
            className="learning-area-headline"
            style={{ marginBottom: '45px', flexGrow: 1 }}>
            <Headline
              type="h2"
              editable={{
                ...editable,
                ...editable.headline,
                isEditMode: isEditMode,
              }}
              values={values}>
              <div
                style={{ minHeight: '55px' }}
                dangerouslySetInnerHTML={{ __html: headline }}
              />
            </Headline>
          </div>
        )}
        {description &&
          (styleDescription ? (
            <div className="learning-area-info">
              <Span withMarker={withMarker} type="primary">
                {description}
              </Span>
              {secondaryDescription && (
                <Span withMarker={withMarker} type="secondary">
                  {secondaryDescription}
                </Span>
              )}
            </div>
          ) : (
            <Span>{description}</Span>
          ))}
        <Divider size="auto" />
        <Button
          onClick={onClick}
          theme="primary"
          style={{
            gridTemplateColumns: '45px 1fr 45px',
            maxWidth: '248px',
          }}
          text={buttonText}
          iconRight="arrow"
        />
      </div>
    </div>
  );
};

export default LearningAreaCard;
