import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import classnames from 'classnames';

import { RoundButton, Span } from '../../common/atoms';
import Footer from './footers';

import { Error } from '../../common/molecules/modal';
import '../../theme/components/pages/page.scss';
import { resetGlobalErrors } from '../../redux/actions/globalError';
import { signOut } from '../../redux/actions';
import { useRef } from 'react';
import { NOOP } from '../../libs/utils';

export function Page({
  appElement,
  children,
  title,
  previousClick = NOOP,
  nextClick = NOOP,
  isSplash = false,
  type = null,
  hideFooter = false,
  errors,
  resetGlobalErrors,
  signOut,
}) {
  const { t } = useTranslation();
  const contentRef = useRef();

  useEffect(() => {
    const isUnauthorized = errors
      ? errors.some((error) => error.statusCode === 401)
      : false;
    if (isUnauthorized) {
      // @TODO: shouldn't be needed, since signOut should reset the entire redux store
      resetGlobalErrors();
      signOut();
    }
  }, [errors, resetGlobalErrors, signOut]);

  useEffect(() => {
    const buttons = document.querySelectorAll(
      '.exercise-wizard__footer .button, .exam-wizard__footer .round-button, .navigation-tab-wrapper__item',
    );
    if (buttons.length) {
      buttons.forEach((button) => {
        button.removeEventListener('click', scrollIonToTop);
        button.addEventListener('click', scrollIonToTop);
      });
    }
  });

  const scrollIonToTop = () => {
    if (contentRef.current) contentRef.current!.scrollToTop();
    document
      .querySelectorAll('ion-content')
      .forEach((content) => content.scrollToTop());
  };

  const className = classnames({
    'content--is-splash': isSplash,
    [`content--is-${type}`]: type !== null,
  });

  return (
    <IonPage>
      <IonContent
        ref={contentRef.current}
        className={className}
        scrollEvents={false}>
        {title && (
          <IonHeader collapse="condense">
            <IonToolbar>
              {title && <IonTitle size="large">{t(title)}</IonTitle>}
            </IonToolbar>
          </IonHeader>
        )}
        <div className="page-wrapper">
          <div className="page">
            {previousClick !== NOOP && previousClick !== null && (
              <span className="pageAction previousPageAction">
                <RoundButton
                  theme="tertiary"
                  icon="ChevronLeft"
                  transparent
                  onClick={previousClick}
                />
              </span>
            )}
            {children}
            {nextClick !== NOOP && nextClick !== null && (
              <span className="pageAction nextPageAction">
                <RoundButton
                  theme="tertiary"
                  icon="ChevronRight"
                  transparent
                  onClick={nextClick}
                />
              </span>
            )}
          </div>
          {/* {!hideFooter && <Footer />} */}
        </div>
        {/* {errors && errors.length > 0 && (
          <Error
            appElement={appElement}
            title={t('generalErrorTitle')}
            description={t('generalErrorDescription')}
            modalIsOpen={true}
            setModalIsOpen={resetGlobalErrors}>
            {errors.map((error, index) => (
              <Span key={`error-${index}`}>
                {`${error.message} - ${error.debugMessage}`}
              </Span>
            ))}
          </Error>
        )} TO DO (add to auth)*/}
      </IonContent>
    </IonPage>
  );
}

export default connect(
  ({ appElement, globalError }) => ({
    appElement,
    errors: globalError.errors,
  }),
  { resetGlobalErrors, signOut },
)(Page);
