import React from 'react';
import { Span, Headline, Button } from '../../atoms';
import ChevronL from '../../../assets/icons/chevron-l.png';
import './banner.scss';
import {NOOP} from "../../../libs/utils";

interface BannerProps {
  headline: string;
  bodyHeadline?: string;
  bodyDescription?: string;
  secondaryBodyDescription?: string;
  descriptionWithMarker?: boolean;
  onClick: React.MouseEventHandler;
  hasPracticeButton?: boolean;
  onClickPractice?: React.MouseEventHandler;
  buttonPracticeText?: string;
}

const Banner: React.FunctionComponent<BannerProps> = ({
  children,
  headline,
  bodyHeadline,
  bodyDescription,
  secondaryBodyDescription,
  descriptionWithMarker,
  onClick,
  hasPracticeButton,
  onClickPractice,
  buttonPracticeText = '',
}) => {
  return (
    <div className="banner">
      <div className="banner_header">
        {onClick !== NOOP ? <div className="banner_header-action" onClick={onClick}>
          <img src={ChevronL} width={20} alt="" />
        </div> : <div></div>}

        <Span type="tertiary" uppercase>
          <span dangerouslySetInnerHTML={{ __html: headline }} />
        </Span>
      </div>
      {children && (
        <div className="banner_body">
          {bodyHeadline && (
            <Headline>
              <div dangerouslySetInnerHTML={{ __html: bodyHeadline }} />
            </Headline>
          )}
          {(bodyDescription || secondaryBodyDescription) && (
            <div className="banner_info_description">
              <Span withMarker={descriptionWithMarker}>{bodyDescription}</Span>
              {secondaryBodyDescription && (
                <Span type="secondary" withMarker={descriptionWithMarker}>
                  {secondaryBodyDescription}
                </Span>
              )}
            </div>
          )}

          <div className="banner_body-content">{children}</div>
          {hasPracticeButton && (
            <div className="practice-button-wrapper">
              <Button
                onClick={onClickPractice}
                theme="secondary"
                text={buttonPracticeText}
                iconRight="practice"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Banner;
