export default class LearnQueries {
  learningAreasData: any = null;

  constructor(learningAreasData) {
    this.learningAreasData = learningAreasData;
  }

  /**
   *  ger learning areas
   * @param learningAreaId
   */
  getLearningData(learningAreaId) {
    try {
      const findAreas = this.learningAreasData.learning_areas.find(
        (element) => element.id === parseInt(learningAreaId),
      );
      if (findAreas) {
        return findAreas;
      }
      return [];
    } catch (e) {
      // logout();
    }
  }

  /**
   * get sub learning areas
   *
   * @param learningAreaId
   * @param subAreaId
   */
  getSubAreasData(learningAreaId, subAreaId) {
    const findAreas = this.getLearningData(learningAreaId);
    let subAreas: any = [];
    if (findAreas) {
      if (!subAreaId) {

        findAreas.sub_areas.forEach((area) => {
          const selectedArea = this.learningAreasData.sub_areas.find(
            (subArea) => subArea.id === parseInt(area.id),
          );
          if (selectedArea) {
            subAreas.push(selectedArea);
          }
        });
      } else {
        const selectedAreas = this.learningAreasData.sub_areas.find(
          (subArea) => {
            return (
              subArea.id === parseInt(subAreaId) &&
              subArea.learning_area_id === parseInt(learningAreaId)
            );
          },
        );

        if (selectedAreas && selectedAreas.child_categories) {
          selectedAreas.child_categories.forEach((area) => {
            const selectedArea = this.learningAreasData.sub_areas.find(
              (subArea) => subArea.id === parseInt(area.id),
            );
            if (selectedArea) {
              subAreas.push(selectedArea);
            }
          });
        }
      }
    }
    return subAreas;
  }

  /**
   * get single sub area content
   *
   * @param subAreaId
   */
  getSubAreaData(subAreaId) {
    const findArea = this.learningAreasData.sub_areas.find(
      (subArea) => subArea.id === parseInt(subAreaId),
    );
    if (findArea) {
      return findArea;
    }
    return null;
  }

  /**
   *
   * get learning content
   *
   * @param learningAreaId
   * @param subAreaId
   */
  getLearnContent(learningAreaId, subAreaId) {
    const contentAreaId = subAreaId === '0' ? null : subAreaId;
    const subAreas = this.getSubAreasData(learningAreaId, contentAreaId);
    const learningAreas: any = [];
    subAreas.forEach((subArea) => {
      const item: any = this.learningAreasData.learning_contents.find(
        (learningContent) => learningContent.sub_area_id === subArea.id,
      );
      if (item) {
        learningAreas.push(item);
      }
    });
    return learningAreas;
  }
}
