import React from 'react';
import './input.scss';
import { NOOP } from '../../../libs/utils';

interface InputProps {
  name?: string;
  value: string | number;
  type?: string;
  labelText?: string;
  required?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  noBar?: boolean;
  removeLabel?: boolean;
  placeholder?: string;
}

const Input: React.FunctionComponent<InputProps> = ({
  name,
  value,
  type = 'text',
  labelText,
  required = false,
  onChange = NOOP,
  noBar = false,
  removeLabel = false,
  placeholder = '',
  ...restProps
}) => {
  const renderLabel = () => {
    if (removeLabel) {
      return null;
    }
    return (
      <label>
        {labelText}
        {required && '*'}
      </label>
    );
  };

  return (
    <div className="group">
      <input
        type={type}
        value={value || ''}
        name={name}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        {...restProps}
      />

      <span className="highlight" />
      {!noBar && <span className="bar" />}
      {renderLabel()}
    </div>
  );
};

export default Input;
