import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import ErrorIcon from './error-file.png';
import axios from 'axios';

import Page from '../../../../components/pages';
import {
  Banner,
  LearningContent as LearningContentComponent,
} from '../../../../common/molecules';
import { RoundButton } from '../../../../common/atoms';
import LearnQueries from '../../../../libs/LearnQueries';
import {
  ProductState,
  TrackingItem,
  TrackingState,
} from 'redux/reducers/types';
import { addTracking } from 'redux/actions/trackings';
import { useIonViewWillEnter } from '@ionic/react';

interface LearningContentProps {
  offlineMode: any;
  product: ProductState;
  user: any;
  trackings: TrackingState;
  addTracking: (tracking: TrackingItem) => void;
}

const apiUrl: string = process.env.REACT_APP_SERVER + '/api/tracking';

const LearningContent: React.FC<LearningContentProps> = ({
  offlineMode,
  user,
  product,
  trackings,
  addTracking,
}) => {
  const hasProduct = !!offlineMode.data && !!offlineMode.data?.learning_areas;
  const history = useHistory();
  const [showImage, setShowImage] = useState(false);
  const { parentSubAreaId, learningAreaId, subAreaId } = useParams();
  const [questionIndex, setQuestionIndex] = useState(-1);
  const [startTime, setStartTime] = useState<number | null>(Date.now());

  const instanceLearnQueries = new LearnQueries(offlineMode.data);

  const learningContents = hasProduct
    ? instanceLearnQueries.getLearnContent(learningAreaId, parentSubAreaId)
    : [];

  setTimeout(() => {
    // @ts-ignore
    const imagesSelect = document.querySelectorAll(
      '.banner_body-content .learning-content img, .banner_body-content .image img',
    );

    let imagesArray = Array.from(imagesSelect);
    imagesArray.forEach((imageElement) => {
      imageElement.addEventListener('click', () =>
        imageClickListener(imageElement),
      );
      imageElement.setAttribute(
        'onerror',
        `this.onerror=null;this.src="${ErrorIcon}";this.style.pointerEvents='none';`,
      );
    });
  }, 100);

  const imageClickListener = (imageElement: Element) => {
    setShowImage(true);
    let wrapper = document.getElementById('image-container');

    if (wrapper) {
      wrapper.addEventListener('click', () => {
        setShowImage(false);
      });

      let image = document.createElement('img');
      if (image) {
        setAttributes(image, {
          src: imageElement.getAttribute('src'),
          alt: 'figure',
        });

        wrapper.innerHTML = '';
        wrapper.appendChild(image);
      }
    }
  };

  useEffect(() => {
    if (learningContents.length && questionIndex === -1) getQuestionIndex();
  }, [learningContents, questionIndex]); //eslint-disable-line

  useIonViewWillEnter(() => {
    if (learningContents.length && questionIndex === -1) getQuestionIndex();
  });

  const getQuestionIndex = () => {
    let questionKey = learningContents.findIndex((learningContent) => {
      return learningContent.sub_area_id == subAreaId;
    });
    setQuestionIndex(questionKey);
  };

  const logTime = (id: number) => {
    if (!startTime) {
      setStartTime(Date.now());
    }
    const timePassed = Date.now() - startTime!;
    const totalTime = Math.round(timePassed / 1000) + getTimeSpentOnContent(id);
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const data = {
      user_id: user.id,
      learning_area_id: +learningAreaId,
      sub_area_id: +parentSubAreaId,
      learning_content_id: learningContents[questionIndex].sub_area_id,
      time_spent: totalTime,
      product_id: product.id,
      is_completed:
        learningContent.reading_time &&
        learningContent.reading_time <= totalTime
          ? true
          : false,
    };

    axios
      .post(apiUrl, data, config)
      .then(() => {
        addTracking(data);
      })
      .catch((err) => {
        if (!err.response) {
          const storageData = localStorage.getItem('tracking_offline');
          const existingOfflineData = storageData
            ? JSON.parse(storageData)
            : [];
          const newArray = [...existingOfflineData];
          const existingItem = existingOfflineData.find(
            (item) => item.learning_content_id === data.learning_content_id,
          );
          if (existingItem) {
            const newTime = existingItem.time_spent + data.time_spent;
            data.time_spent = newTime;
            data.is_completed =
              learningContent.reading_time &&
              learningContent.reading_time <= newTime
                ? true
                : false;
            newArray.splice(newArray.indexOf(existingItem), 1, data);
          } else {
            newArray.push(data);
          }
          addTracking(data);
          localStorage.setItem('tracking_offline', JSON.stringify(newArray));
        }
      });
    setStartTime(Date.now());
  };

  function getTimeSpentOnContent(subArea) {
    let tracks = trackings.filter((track) => {
      return track.learning_content_id === subArea.id;
    });
    return tracks.reduce((prev, current) => prev + current.time_spent, 0);
  }

  function setAttributes(el, attrs) {
    for (var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  }

  const mySlides = useRef(null);
  const [hasButton, setHasButton] = useState({
    previous: false,
    next: false,
  });
  const config = {};
  const handlers = useSwipeable({
    onSwiped: (eventData) => onSwiped(eventData),
    ...config,
  });

  function togglePreviousAndNextButton() {
    if (!mySlides || mySlides.current) {
      return;
    }

    const swiper =
      mySlides && mySlides.current ? mySlides.current.swiper : null;
    setHasButton({
      previous: swiper ? swiper.activeIndex !== 0 : false,
      next: swiper ? learningContents.length > swiper.activeIndex + 1 : false,
    });
  }

  function onClickPrevious() {
    // @ts-ignore
    mySlides.current.swiper.slidePrev();
    togglePreviousAndNextButton();
  }

  function onClickNext() {
    // @ts-ignore
    mySlides.current.swiper.slideNext();
    togglePreviousAndNextButton();
  }

  const onSwiped = function (swipeEvent) {
    if (
      (swipeEvent.dir === 'Right' && !questionIndex) ||
      (swipeEvent.dir === 'Left' &&
        questionIndex === learningContents.length - 1)
    )
      return; // don't log time if we're on the first or last slide and slide in the direction where we have no content
    if (swipeEvent.dir === 'Right' || swipeEvent.dir === 'Left') {
      logTime(subAreaId);
      const newIndex =
        swipeEvent.dir === 'Right' ? questionIndex - 1 : questionIndex + 1;
      const newContent = learningContents[newIndex];
      if (newContent) {
        setQuestionIndex(newIndex);
        const newId = newContent.sub_area_id;
        const newUrl = `/learning-content/${learningAreaId}/${parentSubAreaId}/${newId}`;
        window.history.replaceState(null, '', newUrl);
      }
    }
  };

  const clickOnPreviousContent = function () {
    logTime(subAreaId);
    const newIndex = questionIndex - 1;
    if (learningContents.length > 0 && learningContents[newIndex]) {
      setQuestionIndex(questionIndex - 1);
    } else {
      setQuestionIndex(0);
    }
  };

  const clickOnNextContent = function () {
    logTime(subAreaId);
    const newIndex = questionIndex + 1;
    if (learningContents.length > 0 && learningContents[newIndex]) {
      setQuestionIndex(questionIndex + 1);
    } else {
      setQuestionIndex(learningContents.length - 1);
    }
  };

  const learningContent = hasProduct
    ? learningContents[questionIndex === -1 ? 0 : questionIndex]
    : {};

  const subAreaData = learningContent?.sub_area_id
    ? instanceLearnQueries.getSubAreaData(learningContent.sub_area_id)
    : {};

  const parentSubAreaData =
    subAreaData?.parent_sub_area &&
    instanceLearnQueries.getSubAreaData(subAreaData.parent_sub_area.id);

  let headline = parentSubAreaData?.headline;

  const contentHeadline = learningContent?.headline || subAreaData?.headline;

  if (!headline && subAreaData?.learning_area_id) {
    headline = instanceLearnQueries.getLearningData(
      subAreaData.learning_area_id,
    ).headline;
  }

  const handleGoBack = () => {
    logTime(subAreaId);
    history.goBack();
  };

  return (
    <div {...handlers}>
      {showImage && <div id="image-container" />}
      <Page
        previousClick={hasButton.previous ? onClickPrevious : null}
        nextClick={hasButton.next ? onClickNext : null}>
        {learningContents && (
          <div className="banner-wrapper banner-subpage">
            <div className="content__arrow content__arrow__prev">
              <RoundButton
                theme="tertiary"
                transparent
                icon="ChevronLeft"
                iconSize={26}
                onClick={clickOnPreviousContent}
                style={{
                  visibility: questionIndex !== 0 ? 'visible' : 'hidden',
                }}
              />
            </div>
            <div className="content__arrow content__arrow__next">
              <RoundButton
                theme="tertiary"
                transparent
                icon="ChevronRight"
                iconSize={26}
                onClick={clickOnNextContent}
                style={{
                  visibility:
                    learningContents.length !== questionIndex + 1
                      ? 'visible'
                      : 'hidden',
                }}
              />
            </div>
            <Banner
              key={learningContent.id}
              headline={headline}
              onClick={handleGoBack}>
              <LearningContentComponent headline={contentHeadline || headline}>
                <div
                  dangerouslySetInnerHTML={{ __html: learningContent.wysiwyg }}
                />
              </LearningContentComponent>
            </Banner>
          </div>
        )}
      </Page>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    offlineMode: state.offlineMode,
    user: state.user,
    product: state.product,
    trackings: state.trackings,
    appDefaults: state.appDefaults,
  };
};

export default connect(mapStateToProps, { addTracking })(LearningContent);
