import ApolloClient from 'apollo-boost';
import { store } from '../redux/store';
import { setGlobalErrors } from '../redux/actions/globalError';
import { signOut } from '../redux/actions';
import User from './user';
import Product from './product';
import License from './license';
import Authorization from './authorization';
import Result from './result';
import ResultInput from './resultInput';
import All from './all';

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_SERVER}/graphql`,
  request: (operation) => {
    const state = store.getState();
    const token = state.user.token;
    // console.log({ token });
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        applicationName: `${process.env.REACT_APP_NAME}`,
      },
    });
  },
  onError: (response) => {
    const { networkError } = response;
    // FIX THIS: Will cause an issue with offline mode
    if (response.networkError) {
      // store.dispatch(signOut());
      if (window.parent) {
        window.parent.postMessage('logout', '*');
      }
      setTimeout(function () {
        // console.log('REDIRECTING');
        // window.location.href = '/login';
      }, 1000);
    }
    store.dispatch(setGlobalErrors(response));
    response.forward(response.operation);
    response.networkError = undefined;
    response.graphQLErrors = [];
  },
});

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
};

export {
  User,
  License,
  Product,
  Authorization,
  Result,
  ResultInput,
  All,
  client,
};
