import { useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import * as Icon from 'react-bootstrap-icons';

import { ResultItem } from 'types/result';
import '../../theme/components/charts/results.scss';

interface DataType {
  x: Date;
  y: number | null;
}

interface ResultsChartProps {
  type: 'exercise' | 'exam';
  results: ResultItem[];
  showGoToHistoryButton: {
    onClick: () => void;
    label: string;
    show: boolean;
  };
}

const ResultsChart: React.FunctionComponent<ResultsChartProps> = ({
  type,
  results = [],
  showGoToHistoryButton,
  children,
}) => {
  const [filteredRange, setFilteredRange] = useState<'week' | 'month'>('week');
  const getChartData = () => {
    let dataPerDay: {
      [key: string]: {
        date: Date;
        amount: number;
        count_correct_questions: number;
        count_wrong_questions: number;
      };
    } = {};
    for (let i = 0; i < (filteredRange === 'week' ? 7 : 30); i++) {
      const calculatedDate = new Date();
      calculatedDate.setDate(calculatedDate.getDate() - i);
      const dateString =
        '' +
        calculatedDate.getDate() +
        calculatedDate.getMonth() +
        calculatedDate.getFullYear();
      dataPerDay[dateString] = {
        date: calculatedDate,
        amount: 0,
        count_correct_questions: 0,
        count_wrong_questions: 0,
      };
    }

    results.forEach((res: ResultItem) => {
      const date = new Date(res.started_at * 1000);
      const stringifiedDate =
        '' + date.getDate() + date.getMonth() + date.getFullYear();
      if (!dataPerDay[stringifiedDate]) return;
      const {
        amount,
        count_correct_questions,
        count_wrong_questions,
      } = dataPerDay[stringifiedDate];
      dataPerDay[stringifiedDate] = {
        ...dataPerDay[stringifiedDate],
        amount: amount + 1,
        count_wrong_questions:
          res.count_wrong_questions + count_wrong_questions,
        count_correct_questions:
          res.count_correct_questions + count_correct_questions,
      };
    });
    let amountArray: DataType[] = [];
    let percentageRightArray: DataType[] = [];
    Object.keys(dataPerDay).forEach((key) => {
      const {
        amount,
        count_correct_questions,
        count_wrong_questions,
        date,
      } = dataPerDay[key];
      const amountArrayY = !amount ? null : Math.round(amount);
      const percentageArrayY = amountArrayY
        ? count_correct_questions > 0
          ? Math.round(
              (count_correct_questions /
                (count_correct_questions + count_wrong_questions)) *
                100,
            )
          : 0
        : null;
      amountArray.push({
        x: date,
        y: amountArrayY,
      });
      percentageRightArray.push({
        x: date,
        y: percentageArrayY,
      });
    });
    amountArray.sort(sortByDates);
    percentageRightArray.sort((a, b) => (a.x > b.x ? 1 : -1));
    return {
      amountArray,
      percentageRightArray,
      startDate: amountArray[0].x,
      endDate: amountArray[amountArray.length - 1].x,
    };
  };

  useIonViewWillEnter(() => {
    window.dispatchEvent(new Event('resize'));
  });
  const sortByDates = (a: DataType, b: DataType) => (a.x > b.x ? 1 : -1);

  const filteredByMonth = filteredRange === 'month';
  const pluralTitle = type === 'exam' ? 'Prüfungen' : 'Übungen';
  const RenderIcon = Icon['ChevronRight'];

  const chartData = getChartData();

  return (
    <div className="results-chart">
      <div className="results-chart__header">
        <h2>{pluralTitle}</h2>
        <div className="results-chart__filter">
          <div
            className={`${
              filteredByMonth ? 'results-chart__filter-item--active' : ''
            } results-chart__filter-item`}
            onClick={() => setFilteredRange('month')}>
            30 Tage
          </div>
          <div
            className={`${
              !filteredByMonth ? 'results-chart__filter-item--active' : ''
            } results-chart__filter-item`}
            onClick={() => setFilteredRange('week')}>
            7 Tage
          </div>
        </div>
      </div>
      {children}
      {chartData && (
        <Chart
          options={{
            noData: {
              offsetY: -10,
              style: {
                fontSize: '14px',
                fontFamily: 'Montserrat light',
                color: '#758FA6',
              },
              text: `Keine Daten gefunden`,
            },
            chart: {
              id: 'basic-bar',
              toolbar: {
                show: false,
              },
              animations: {
                enabled: true,
              },
            },
            stroke: {
              width: 3,
            },
            markers: {
              size: 3,
              showNullDataPoints: false,
              discrete: chartData.percentageRightArray.map(
                (dataPoint, dataPointIndex) => {
                  return {
                    seriesIndex: 1,
                    dataPointIndex,
                    size:
                      !dataPoint.y && !chartData.amountArray[dataPointIndex].y
                        ? 0
                        : 3,
                    fillColor: '#ffffff',
                    strokeColor: '#339bf2',
                  };
                },
              ),
            },
            legend: {
              show: false,
            },
            colors: ['#F5AD24', '#339bf2'],
            xaxis: {
              tickPlacement: 'asdf',
              type: 'datetime',
              min: new Date(chartData.startDate).getTime(),
              max: new Date(chartData.endDate).getTime(),
              tooltip: {
                enabled: false,
              },
              labels: {
                style: {
                  colors: '#758FA6',
                  fontFamily: 'Montserrat light',
                  fontSize: '11px',
                },
              },
            },
            yaxis: [
              {
                seriesName: 'correct',
                opposite: true,
                tickAmount: 5,
                min: 0,
                max: 100,
                labels: {
                  formatter: (val) => val + '%',
                  style: {
                    colors: '#F5AD24',
                    fontFamily: 'Montserrat light',
                    fontSize: '11px',
                  },
                },
                title: {
                  text: 'Richtige Antworten',
                  style: {
                    color: '#F5AD24',
                    fontFamily: 'Montserrat light',
                    fontSize: '11px',
                  },
                },
              },
              {
                seriesName: 'amount',
                title: {
                  text: `Anzahl ${pluralTitle}`,
                  style: {
                    color: '#339bf2',
                    fontFamily: 'Montserrat light',
                    fontSize: '11px',
                  },
                },
                labels: {
                  style: {
                    colors: '#339bf2',
                    fontFamily: 'Montserrat light',
                    fontSize: '11px',
                  },
                  formatter: function (val) {
                    return val?.toFixed(0);
                  },
                },
                tooltip: {
                  enabled: false,
                },
              },
            ],
          }}
          series={[
            {
              name: 'Richtige Antworten',
              type: 'column',
              data: chartData.percentageRightArray,
            },
            {
              name: `Anzahl ${pluralTitle}`,
              type: 'line',
              data: chartData.amountArray,
            },
          ]}
          width="100%"
        />
      )}
      {showGoToHistoryButton.show && (
        <div
          className="results-chart__detail-button"
          onClick={showGoToHistoryButton.onClick}>
          <span>Abgeschlossene {pluralTitle}</span> <RenderIcon />
        </div>
      )}
    </div>
  );
};

export default ResultsChart;
