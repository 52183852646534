import React from 'react';
import { useHistory } from 'react-router';
import Page from '../../components/pages';
import { Onboarding as OnboardingComponent } from '../../common/pages';
import Footer from 'components/pages/footers';

export default function Onboarding() {
  const { push } = useHistory();

  return (
    <Page>
      <OnboardingComponent
        onLogin={() => push('/login')}
        onRegister={() => push('/register')}
      />
      <Footer />
    </Page>
  );
}
