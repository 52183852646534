import React from 'react';
import { Link as ReactLink } from 'react-router-dom';

import { Span } from '../';
import './link.scss';

export default function Link({ to, children, type = 'primary' }) {
  const absoluteUrlRegex = /^[a-z]+?:\/\/.*/i;

  function isAbsoluteUrl() {
    return absoluteUrlRegex.test(to);
  }

  return isAbsoluteUrl() ? (
    <a className="link" href={to}>
      <Span type={type}>{children}</Span>
    </a>
  ) : (
    <ReactLink className="link" to={to}>
      <Span type={type}>{children}</Span>
    </ReactLink>
  );
}
