import React, { useState } from 'react';
import classNames from 'classnames';
import './checkbox.scss';

interface CheckboxProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  name?: string;
  type: string;
  value: string;
  label?: string;
  useCheckIcon?: boolean;
  colored?: boolean;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  onChange,
  name,
  type = 'primary',
  value,
  label,
  useCheckIcon = false,
  colored = false,
}) => {
  const [isChecked, setChecked] = useState(false);

  const onCheckboxClicked = (event) => {
    setChecked(event.target.checked);
    if (typeof onChange === 'function') {
      onChange(event);
    }
  };

  const isActive = Array.isArray(value) ? value.length === 1 : !!value;
  const className = classNames({
    checkbox: true,
    [`checkbox--is-${type}`]: type,
    checked: isChecked,
    'check-icon': useCheckIcon,
    colored: colored,
  });

  return (
    <div>
      {label && <label className="checkbox-label">{label}</label>}
      <div className={className}>
        <input
          type="checkbox"
          checked={isActive}
          name={name}
          onChange={onCheckboxClicked}
        />
        <label className="inner"></label>
      </div>
    </div>
  );
};

export default Checkbox;
