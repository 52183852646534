import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import {getPlatforms} from "@ionic/react";
// import { PersistGate } from 'redux-persist/integration/react';


const platforms = getPlatforms();
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  attachStacktrace: true,
  release: process.env.REACT_APP_PRODUCT_ID ? `APP_${process.env.REACT_APP_PRODUCT_ID}_${platforms?.join("_")}` : `APPUCATIONS_APP_${platforms?.join("_")}`
});

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <App />
    {/* </PersistGate> */}
  </Provider>,
  document.querySelector('#root'),
);

serviceWorker.unregister();
