import {Constants} from "../constants";

const initialState = '#root';

export default function appElement(state = initialState, action) {
    switch (action.type) {
        case Constants.SET_APP_ELEMENT:
            return action.selector;
        case Constants.RESET_APP_ELEMENT:
            return initialState;
        default:
            return state;
    }
}
