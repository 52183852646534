import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  Headline,
  Checkbox,
  Span,
  RoundButton,
  Button,
  Circle,
  Paragraph,
} from '../../atoms';
import { List } from '../../organisms';
import { ListItem } from '../../molecules';
import { ConfirmFinishExercise } from '../../molecules/modal';
import { smartphoneWidth } from '../../../frontendConfig';
import { countUnansweredQuestions } from '../../../libs/utils';
import './exerciseWizard.scss';
import {Answer} from "../../../libs/types";

export default function ExerciseWizardPage({
  appElement,
  exercise,
  questionIndex,
  onToggleAnswer,
  getQuestionResult,
  onClickNext,
  onClickFinish,
}) {
  const { t } = useTranslation();
  const [isTabletOrBigger, setIsTabletOrBigger] = useState(
    window.innerWidth > smartphoneWidth,
  );
  const question = exercise.questions[questionIndex];
  const unansweredCount = countUnansweredQuestions(exercise.questions);
  const [finishModalIsOpen, setFinishModalIsOpen] = useState(false);
  const [inputMode, setInputMode] = useState(true);
  const [questionResult, setQuestionResult] = useState<{
    answers: {
      needs_check: boolean;
      is_checked: boolean;
      text: string;
    }[];
    is_correct: boolean;
  } | null>(null);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsTabletOrBigger(window.innerWidth > smartphoneWidth);
    });
  }, []);

  useEffect(() => {
    setInputMode(true);
    setQuestionResult(null);
  }, [questionIndex]);

  const handleClickNext = () => {
    const contentWrapperEl = document.querySelector('ion-content');
    if (contentWrapperEl) contentWrapperEl.scrollToTop();
    onClickNext();
  };

  function getColumnsClassNames(answer: Answer) {
    return classNames({
      'exercise-wizard__question-evaluated-answers-item-columns': true,
      'exercise-wizard__question-evaluated-answers-item-columns--is-needing_check':
        answer.needs_check == true,
      'exercise-wizard__question-evaluated-answers-item-columns--is-checked':
        answer.is_checked == true,
      'exercise-wizard__question-evaluated-answers-item-columns--is-selected':
        answer.is_correct === true,
    });
  }

  function hasQuestionCheckedAnswers() {
    return question.answers.some((answer) => answer.is_correct);
  }

  function evaluateQuestion() {
    setInputMode(false);
    setQuestionResult(getQuestionResult());
  }

  return (
    <div className="exercise-wizard">
      <div className="exercise-wizard__panel">
        <div className="exercise-wizard__header">
          <Span type="tertiary" uppercase>
            {t('questionCountWithTotal', {
              count: questionIndex + 1,
              totalCount: exercise.questions.length,
            })}
          </Span>
          <div className="exercise-wizard__header-close">
            <ConfirmFinishExercise
              appElement={appElement}
              setModalIsOpen={setFinishModalIsOpen}
              modalIsOpen={finishModalIsOpen}
              childrenBefore={
                <RoundButton
                  icon="X"
                  theme="tertiary"
                  transparent={true}
                  iconSize={26}
                  onClick={() => setFinishModalIsOpen(true)}
                />
              }
              unansweredQuestions={unansweredCount}
              onFinishExercise={onClickFinish}
            />
          </div>
        </div>
        <Headline type="h2">{question.question}</Headline>
      </div>

      {inputMode || !questionResult ? (
        <>
          <List>
            {question.answers.map((answer, index) => (
              <ListItem
                key={`exercise-question-answer-${index}`}
                title={answer.text}
                removeButton
                isSelected={answer.is_correct}
                theme="secondary"
                onClick={() => onToggleAnswer(index, questionIndex)}>
                <Checkbox
                  value={answer.is_correct}
                  name={`answers.${index}`}
                  type="secondary"
                />
              </ListItem>
            ))}
          </List>
          {(isTabletOrBigger || hasQuestionCheckedAnswers()) && (
            <div className="exercise-wizard__footer">
              <Button
                theme="secondary"
                text={t('evaluate')}
                onClick={evaluateQuestion}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <List>
            <div className="exercise-wizard__question-evaluated-answers">
              {questionResult.answers.map((answer, index) => (
                <div
                  key={`exercise-question-answer-${index}`}
                  className="exercise-wizard__question-evaluated-answers-item">
                  <div className={getColumnsClassNames(answer)}>
                    <div className="status">
                      <Circle
                        theme={answer.needs_check ? 'success' : 'danger'}
                        backgroundTheme={answer.is_checked ? 'secondary' : null}
                      />
                    </div>
                    <Paragraph>{answer.text}</Paragraph>
                  </div>
                </div>
              ))}
            </div>
          </List>

          <div className="exercise-wizard__footer">
            {questionResult.is_correct ? (
              <Button
                theme="success"
                text={t('correct')}
                iconRight="arrow"
                onClick={handleClickNext}
              />
            ) : (
              <Button
                theme="danger"
                text={t('incorrect')}
                iconRight="arrow"
                onClick={handleClickNext}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
