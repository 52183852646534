import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER}/api`,
});

export default class Authorization {
  static login(email, password) {
    const loginUrl = process.env.REACT_APP_PRODUCT_ID ? `/login?product_id=${process.env.REACT_APP_PRODUCT_ID}` : `/login`;
    return api
      .post(loginUrl, {
        email,
        password,
        applicationName: process.env.REACT_APP_NAME,
      })
      .then((res) => res.data.success.token);
  }

  static register(data) {
    return api.post('/register', data).then((res) => res.data.success.token);
  }

  static logout(token) {
    return api
      .post('/logout', { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => res.data);
  }

  static getUserDetails(token) {
    return api
      .get('/details', { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => res.data.success);
  }
  static licenseCheck(token, userId) {
    return api
      .get('/license-check?userId=' + userId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res.data.success);
  }
  static getUserTrackings(token) {
    const product_id = process.env.REACT_APP_PRODUCT_ID;
    return api
      .get(`/tracking/${product_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res.data.success);
  }
}
