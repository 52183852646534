import gql from 'graphql-tag';

export default class Result {
    static getDefaultProperties() {
        return `
            id
            type
            is_success
            count_correct_questions
            count_wrong_questions
            points
            points_achieved
            duration
            started_at
            finished_at
            learning_areas {
                id
                name
                is_success
                count_correct_questions
                count_wrong_questions
                points
                points_achieved
                order
                questions {
                    id
                    text
                    is_correct
                    points
                    points_achieved
                    index
                    order
                    answers {
                        id
                        text
                        needs_check
                        is_checked
                        is_correct
                        order
                    }
                }
            }
        `;
    }

    static getQuery(properties = Result.getDefaultProperties()) {
        return gql`query result($product_id: Int!, $result_id: Int!) {
            result(product_id: $product_id, result_id: $result_id) {
                ${properties}
            }
        }`;
    }

    static getAllQuery(properties = Result.getDefaultProperties()) {
        return gql`query results($product_id: Int!, $type: String, $learning_area_id: String) {
            results(product_id: $product_id, type: $type, learning_area_id: $learning_area_id) {
                ${properties}
            }
        }`;
    }

    static createMutation(properties = Result.getDefaultProperties()) {
        return gql`mutation createResult($result: ResultInput!) {
            createResult(result: $result) {
                ${properties}
            }
        }`;
    }
}
