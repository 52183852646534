import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import Page from '../../components/pages';
import {Button, Divider} from 'common/atoms';
import {Grid} from 'common/organisms';
import {LearningAreaCard} from 'common/molecules';
import SearchBox from './searchBox/searchBox';
import {removeUnUsedButtonOnMobile} from '../../libs/utils';
import LearnQueries from 'libs/LearnQueries';
import NoDataPage from 'pages/NoDataPage/NoDataPage';

interface LearningProps {
  offlineMode: any;
  license: any;
}

const Learning: React.FC<LearningProps> = ({offlineMode, license}) => {
  const {data: offlineData} = offlineMode;

  const {t} = useTranslation();
  const {push} = useHistory();
  const hasProduct =
    !!offlineData &&
    !!offlineData?.learning_contents &&
    !!offlineData?.learning_contents?.length;

  if (!hasProduct && !license?.product_id && !process.env.REACT_APP_PRODUCT_ID) {
    push(`/select-course`);
  }

  const instanceLearnQueries = new LearnQueries(offlineMode.data);
  const [data, setData] = useState([]);
  if (!hasProduct && data.length !== 0) {
    setData([]);
  }
  useEffect(() => {
    if (offlineMode.data) {
      const result = offlineMode.data.learning_areas.filter(
        (item) => item.subareas_count > 0 && item.learning_contents_count > 0,
      );
      setData(result);
    }
  }, [offlineMode.data]);

  setTimeout(() => {
    removeUnUsedButtonOnMobile();
  }, 500);

  const renderSubareas = () => {
    return data?.map((learningArea: any, index) => {
      const subAreaData = instanceLearnQueries.getSubAreaData(
        learningArea.sub_areas[0].id,
      );
      return (
        <LearningAreaCard
          key={`learning-area-${subAreaData.id}`}
          headline={subAreaData.headline}
          description={t('cardDescription', {
            countSubAreas: subAreaData.child_categories.length - 1,
            countLearningContents: learningArea.learning_contents_count,
          })}
          secondaryDescription={t('cardSecondaryDescription', {
            points: learningArea.points,
          })}
          buttonText={t('openLearningArea')}
          currentCount={index + 1}
          totalCount={data.length}
          styleDescription
          onClick={() => push(`/learning/${learningArea.id}/${subAreaData.id}`)}
        />
      );
    });
  };

  return (
    <Page>
      {hasProduct ? (
        <div className="cards-page">
          <SearchBox/>
          <div className={'wrap-on-tablet'}>
            <Grid size="col-6">{data && renderSubareas()}</Grid>
            <div className="learning-more-links-wrapper">
              <Divider size="full"/>
              <div className="buttons-wrapper">
                <Button
                  onClick={() => push('/profile')}
                  theme="tertiary"
                  text="Statistiken"
                />{' '}
                <Button
                  onClick={() => {
                    push('/exam')
                  }}
                  theme="secondary"
                  text="Zur Prüfung"
                  iconRight="practice"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoDataPage color="#339BF2" type="learning"/>
      )}
    </Page>
  );
};

export default connect(({offlineMode, license}) => ({
  offlineMode,
  license
}))(Learning);
