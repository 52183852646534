import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useIonViewWillEnter } from '@ionic/react';

import Page from '../../../../components/pages';
import { Banner } from '../../../../common/molecules';
import { ResultsPage } from '../../../../common/pages';
import ExamQueries from '../../../../libs/ExamQueries';
import { OfflineModeState } from 'redux/reducers/types';

interface StatisticsHistoryProps {
  offlineMode: OfflineModeState;
}

const StatisticsHistory: React.FC<StatisticsHistoryProps> = ({
  offlineMode,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isExercise, setIsExercise] = useState<boolean>(
    window.location.href.indexOf('exercise') > -1,
  );
  const hasProduct = !!offlineMode.data?.results.length;
  const instanceExamQueries = new ExamQueries(offlineMode.data);
  const exerciseResultsData = hasProduct
    ? instanceExamQueries.getResultsData('exercise')
    : [];
  const examResultsData = hasProduct
    ? instanceExamQueries.getResultsData('exam')
    : [];
  let data = exerciseResultsData.concat(examResultsData);
  data.results = data;

  useIonViewWillEnter(() => {
    setIsExercise(window.location.href.indexOf('exercise') > -1);
  });

  function goToProfile() {
    history.push('/profile');
  }

  function goToDetail(result) {
    history.push(
      `/profile/statistics/result/${result.id}?type=${
        isExercise ? 'exercise' : 'exam'
      }`,
    );
  }

  let examData = [];
  if (data && data.results)
    examData = data.results.filter(
      (result) => result.type === (isExercise ? 'exercise' : 'exam'),
    );

  return (
    <Page title="statistics history">
      <div className="banner-wrapper banner-feedback banner-statistics">
        <Banner headline={t('evaluation')} onClick={goToProfile}>
          {data && data.results && (
            <ResultsPage results={examData} onEnterResult={goToDetail} />
          )}
        </Banner>
      </div>
    </Page>
  );
};

export default connect(
  ({ offlineMode }) => ({ offlineMode }),
  null,
)(StatisticsHistory);
