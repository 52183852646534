import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Page from '../../components/pages';
import { TabNavigation } from '../../common/molecules';
import { updateUser } from '../../redux/actions';
import ResultsChart from '../../components/charts/results';
import SideBar from './sideBar/sideBar';

import './profileStyles.scss';
import { APP_MODE } from '../../frontendConfig';
import SideBarProfileImage from './sideBar/compProfileImage';
import SideBarUserData from './sideBar/compUserData';
import SideBarInformationBox from './sideBar/compInformationBox';
import ChangePassword from './changePassword';
import Feedback from './feedback';
import { useHistory } from 'react-router';
import InfoArea from './info-area';
import ExerciseFilter, {
  ExerciseFilterState,
} from 'common/molecules/exerciseFilter';
import { ResultItem } from 'types/result';
import NoDataPage from 'pages/NoDataPage/NoDataPage';

interface ProfileProps {
  product: any;
  updateUser: (data: any) => void;
  appDefaults: any;
  user: any;
  offlineMode: any;
}

const PAGE_CONTENTS = {
  STATISTIC: 'STATISTIC',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  FEEDBACK: 'FEEDBACK',
  INFO_AREA: 'INFO_AREA',
  NULL: 'NULL',
};

const Profile: React.FC<ProfileProps> = ({
  product,
  appDefaults,
  user,
  updateUser,
  offlineMode,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [tabId, setTabId] = useState(0);
  const [exerciseFilter, setExerciseFilter] = useState<ExerciseFilterState>(
    'all',
  );
  const [pageContent, setPageContent] = useState(PAGE_CONTENTS.STATISTIC);

  const logout = async function () {
    history.push('/logout');
  };

  const getResultsData = (type: 'exam' | 'exercise') => {
    return offlineMode.data.results.filter(
      (result: ResultItem) => result.type === type,
    );
  };

  let exerciseResultsData = !!offlineMode.data
    ? getResultsData('exercise')
    : [];
  let examResultsData = !!offlineMode.data ? getResultsData('exam') : [];

  const onInfoAreaClicked = function () {
    setTabId(-1);
    setPageContent(PAGE_CONTENTS.INFO_AREA);
  };

  const onGoBackInfoArea = function () {
    setTabId(2);
    setPageContent(PAGE_CONTENTS.INFO_AREA);
  };

  const onStatisticClick = function () {
    setTabId(0);
    setPageContent(PAGE_CONTENTS.NULL);
  };

  const filterExerciseData = () => {
    if (exerciseFilter === 'all') return exerciseResultsData;
    return exerciseResultsData.filter(
      (result: ResultItem) => result.learning_areas[0].name === exerciseFilter,
    );
  };
  const filteredExerciseData = filterExerciseData();

  const getFilterableLearningAreas = () => {
    const availableAreas: string[] = [];
    exerciseResultsData.forEach((result: ResultItem) => {
      const { name } = result.learning_areas[0];
      if (availableAreas.indexOf(name) === -1) availableAreas.push(name);
    });
    return availableAreas;
  };
  const filterableLearningAreas = getFilterableLearningAreas();

  const renderStatistic = offlineMode.data?.results?.length ? (
    <>
      <ResultsChart
        type="exam"
        results={examResultsData}
        showGoToHistoryButton={{
          onClick: () => history.push('/profile/statistics/history'),
          label: t('statisticsHistory'),
          show: !!examResultsData.length,
        }}
      />
      <ResultsChart
        type="exercise"
        results={filteredExerciseData}
        showGoToHistoryButton={{
          onClick: () =>
            history.push('/profile/statistics/history?type=exercise'),
          label: t('statisticsHistory'),
          show: !!exerciseResultsData.length,
        }}>
        <ExerciseFilter
          options={filterableLearningAreas}
          selectedItem={exerciseFilter}
          onChange={setExerciseFilter}
        />
      </ResultsChart>
    </>
  ) : offlineMode.isOffline ? (
    <NoDataPage type="profile" color="#59c11a" />
  ) : (
    <p className="no-data-statistics">{t('noDataFound')}</p>
  );

  function renderPageContent() {
    switch (pageContent) {
      case PAGE_CONTENTS.STATISTIC:
        return renderStatistic;
      case PAGE_CONTENTS.CHANGE_PASSWORD:
        return (
          <ChangePassword
            isMobile={appDefaults.mode === APP_MODE.MOBILE}
            user={user}
          />
        );
      case PAGE_CONTENTS.FEEDBACK:
        return <Feedback user={user} productName={product.name} />;
      case PAGE_CONTENTS.INFO_AREA:
        return <InfoArea />;
      default:
        return renderStatistic;
    }
  }

  function renderSideBarContent() {
    let content: any = null;
    switch (tabId) {
      case 0:
        content = renderStatistic;
        break;
      case -1:
        content = <InfoArea handleGoBack={onGoBackInfoArea} />;
        break;
      case 1:
        content = (
          <SideBarUserData
            onChangePasswordClick={console.log}
            updateUser={updateUser}
            signOut={logout}
            user={user}
            product={product}
          />
        );
        break;
      case 2:
        content = (
          <SideBarInformationBox
            onStatisticClick={onStatisticClick}
            onInfoAreaClicked={onInfoAreaClicked}
            onFeedbackClick={() => setPageContent(PAGE_CONTENTS.FEEDBACK)}
          />
        );
        break;
    }
    return content;
  }

  function renderTabBar() {
    return (
      <>
        <div className="profile_header">
          <SideBarProfileImage user={user} updateUser={updateUser} />
          <TabNavigation
            tabs={[
              {
                text: t('statistic'),
                index: [0],
              },
              {
                text: t('account'),
                index: [1],
              },
              {
                text: t('info'),
                index: [2, -1],
              },
            ]}
            selectedIndex={tabId}
            onSelectTab={setTabId}
          />
        </div>
        <div className="profile__content">{renderSideBarContent()}</div>
      </>
    );
  }

  function renderDesktopElements() {
    return (
      <>
        <div className="profile__sidebar">
          <SideBar
            onStatisticClick={onStatisticClick}
            onInfoAreaClicked={onInfoAreaClicked}
            onFeedbackClick={() => setPageContent(PAGE_CONTENTS.FEEDBACK)}
            onChangePasswordClick={() =>
              setPageContent(PAGE_CONTENTS.CHANGE_PASSWORD)
            }
            signOut={logout}
            updateUser={updateUser}
          />
        </div>
        <div className="profile__content">{renderPageContent()}</div>
      </>
    );
  }

  return (
    <Page>
      <div className="profile">
        <div className="profile-page-desktop">{renderDesktopElements()}</div>
        <div className="profile-page-mobile">{renderTabBar()}</div>
      </div>
    </Page>
  );
};

export default connect(
  ({ product, appDefaults, user, offlineMode }) => ({
    product,
    appDefaults,
    user,
    offlineMode,
  }),
  { updateUser },
)(Profile);
