import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '../../../common/atoms';
import '../../../theme/components/pages/footers.scss';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <div className="footer">
      <Link to="http://www.appucations.de">
          <span
            dangerouslySetInnerHTML={{
              __html: t('copyright', { currentYear }),
            }}
          />
      </Link>
      <Link to={'/terms-of-service'}>{t('conditionTerms')}</Link>
      <Link to={'/imprint'}>{t('imprint')}</Link>
      <Link to={'/data-protection-policy'}>{t('dataProtection')}</Link>
    </div>
  );
}
