import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';

import { Error } from 'common/molecules/modal';
import { BaseModal } from 'common/organisms';
import { Banner } from 'common/molecules';
import { Button } from 'common/atoms';
import { Authorization, User } from '../../graphql';
import Page from '../../components/pages';
// import { APP_MODE } from '../../frontendConfig';
import { UserState } from 'redux/reducers/types';

import IcoSuccess from '../../assets/icons/ico_success.svg';

interface InterfaceChangePasswordProps {
  user: UserState;
}

const ERROR_TYPES = Object.freeze({
  OLD_PASSWORD_INVALID: 'OLD_PASSWORD_INVALID',
  PASSWORD_LENGTH: 'PASSWORD_LENGTH',
  PASSWORD_CONDITIONS: 'PASSWORD_CONDITIONS',
  NO_ERROR: 'NO_ERROR',
});

const ChangePassword: React.FunctionComponent<InterfaceChangePasswordProps> = ({
  user,
}) => {
  const { t } = useTranslation();
  const appElement = '#root';
  const history = useHistory();
  const [errorType, setErrorType] = useState(ERROR_TYPES.NO_ERROR);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [updateUserData] = useMutation(User.updateMutation());

  function validateUserPassword() {
    // const userId = (user.id + '').replace("'", ''); //todo: fix on api side
    if (newPassword.length > 5) {
      Authorization.login(user.email, oldPassword)
        .then((token) => {
          Authorization.getUserDetails(token).then((user) => {
            updateUserData({
              variables: {
                id: user.id,
                password: newPassword,
              },
            })
              .then((response) => {
                setShowSuccessModal(true);
              })
              .catch((e) => {
                setErrorType(ERROR_TYPES.OLD_PASSWORD_INVALID);
              });
          });
        })
        .catch((response) => {
          setErrorType(ERROR_TYPES.OLD_PASSWORD_INVALID);
        });
      return;
    }
    setErrorType(ERROR_TYPES.PASSWORD_CONDITIONS);
  }

  function acceptChangedPassword() {
    setOldPassword('');
    setNewPassword('');
    setNewPasswordRepeat('');
    setShowSuccessModal(false);

    setTimeout(() => {
      history.push(`/profile/`);
    }, 500);
  }

  return (
    <Page>
      <div className="banner-wrapper banner-feedback">
        <Banner
          headline={t('changePassword')}
          onClick={() => history.push(`/profile/`)}>
          <div className="profile-edit-password-form">
            <div className="form-wrapper">
              <div className="field-item old-password">
                <span>{t('currentPassword')}</span>
                <input
                  value={oldPassword}
                  type="password"
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>

              <div
                className={
                  'field-item password-1' +
                  (newPassword !== newPasswordRepeat ? ' has-error' : '')
                }>
                <span>{t('newPassword')}</span>
                <input
                  value={newPassword}
                  type="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>

              <div
                className={
                  'field-item password-2' +
                  (newPassword !== newPasswordRepeat ? ' has-error' : '')
                }>
                <span>{t('newPasswordRepeat')}</span>
                <input
                  value={newPasswordRepeat}
                  type="password"
                  onChange={(e) => setNewPasswordRepeat(e.target.value)}
                />
              </div>
            </div>
            <Button
              disabled={
                !oldPassword.length ||
                !newPassword.length ||
                !newPasswordRepeat.length
              }
              onClick={validateUserPassword}
              theme="primary"
              text={t('changePassword')}
            />
          </div>
          <Error
            appElement={appElement}
            title={t('changePasswordErrorTitle')}
            description={
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    errorType === ERROR_TYPES.OLD_PASSWORD_INVALID
                      ? t('changePasswordErrorOldPassword')
                      : t('changePasswordErrorDescription'),
                }}
              />
            }
            modalIsOpen={errorType !== ERROR_TYPES.NO_ERROR}
            setModalIsOpen={() => setErrorType(ERROR_TYPES.NO_ERROR)}
          />
          <BaseModal
            appElement="#root"
            setModalIsOpen={setShowSuccessModal}
            modalIsOpen={showSuccessModal}
            className="modal modal-success">
            <div className="overlay">
              <img
                src={IcoSuccess}
                className="svg"
                height="101"
                alt=""
              />
              <h4>{t('changePasswordUpdateSuccessfulHeadline')}</h4>
              <p>{t('changePasswordUpdateSuccessful')}</p>
              <hr />
              <div className="actions single">
                <button onClick={acceptChangedPassword} className="confirm">
                  <strong>{t('OK')}</strong>
                </button>
              </div>
            </div>
          </BaseModal>
        </Banner>
      </div>
    </Page>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    appDefaults: state.appDefaults,
  };
};

export default connect(mapStateToProps)(ChangePassword);
