import React from 'react';
import classNames from 'classnames';
import './span.scss';
import { Input } from '../';
import { NOOP } from '../../../libs/utils';

interface SpanProps {
  overflow?: boolean;
  uppercase?: boolean;
  withMarker?: boolean;
  hasErrors?: null | { message: string };
  type?: string;
  editable?: {
    isEditMode: boolean;
    onChange: Function;
    name: string;
    labelText: string;
  };
}

const Span: React.FunctionComponent<SpanProps> = ({
  overflow = false,
  uppercase = false,
  withMarker = false,
  children,
  hasErrors = null,
  type = 'primary',
  editable = { isEditMode: false, onChange: NOOP, name: '', labelText: '' },
}) => {
  const className = classNames({
    text: true,
    'text-with-marker': withMarker,
    [`text--is-${type}`]: type,
    'text--is-overflow': overflow,
    'text--is-uppercase': uppercase,
  });

  return !editable.isEditMode ? (
    <span className={className} title={editable.name || ''}>
      {children}
    </span>
  ) : (
    <div className={hasErrors && hasErrors.message ? 'has-error' : ''}>
      {hasErrors && hasErrors.message ? (
        <span className="has-error-input">{hasErrors.message}</span>
      ) : null}
      <Input
        value={children}
        name={editable.name}
        onChange={() => editable.onChange()}
        labelText={editable.labelText}
      />
    </div>
  );
};

export default Span;
