import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '../../../organisms';
import { Headline, RoundButton, Button } from '../../../atoms';
import { NOOP } from '../../../../libs/utils';
import './confirmFinishExercise.scss';

export default function ConfirmFinishExercise({
  childrenBefore,
  unansweredQuestions = 0,
  setModalIsOpen,
  modalIsOpen,
  onFinishExercise = NOOP,
}) {
  const { t } = useTranslation();

  return (
    <BaseModal
      baseClass="confirm-finish-exercise"
      childrenBefore={childrenBefore}
      setModalIsOpen={setModalIsOpen}
      modalIsOpen={modalIsOpen}>
      <div className="confirm-finish-exercise__icon">
        <RoundButton icon="ExclamationCircleFill" />
      </div>
      <div className="confirm-finish-exercise__headline">
        <Headline type="h2">{t('confirmFinishExercise')}</Headline>
      </div>
      {unansweredQuestions > 0 && (
        <div className="confirm-finish-exercise__attention">
          {unansweredQuestions === 1
            ? t('attentionUnansweredQuestion')
            : t('attentionUnansweredQuestions', { unansweredQuestions })}
        </div>
      )}
      <div className="confirm-finish-exercise__question">
        {t('doYouReallyWantToFinishYourExercise')}
      </div>
      <div className="confirm-finish-exercise__actions">
        <Button
          theme="tertiary"
          transparent
          text={t('abort')}
          removeBorder
          onClick={() => setModalIsOpen(false)}
        />
        <Button
          theme="danger"
          transparent
          text={t('finish')}
          removeBorder
          onClick={() => {
            onFinishExercise();
            setModalIsOpen(false);
          }}
        />
      </div>
    </BaseModal>
  );
}
