import React from 'react';
import './exerciseFilter.scss';

export type ExerciseFilterState = string;

const ExerciseFilter: React.FunctionComponent<{
  onChange: (name: string) => void;
  selectedItem: string;
  options: string[];
}> = ({ onChange, selectedItem, options }) => {
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) =>
    onChange(e.target.value);

  return (
    <div className="exercise-filter">
      <select onChange={handleSelect} value={selectedItem}>
        <option value="all">Alle Übungen</option>
        {options.map((value: string) => {
          return (
            <option key={value} value={value}>
              {value}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default ExerciseFilter;
