import { Constants } from '../constants';

export function prepareExam(exam) {
  return function (dispatch) {
    return dispatch({ type: Constants.PREPARE_EXAM, payload: exam });
  };
}

export function startExam(exam) {
  return function (dispatch) {
    return dispatch({ type: Constants.START_EXAM, payload: exam });
  };
}

export function toggleExamAnswer(answerIndex, questionIndex) {
  return function (dispatch) {
    return dispatch({
      type: Constants.TOGGLE_EXAM_ANSWER,
      payload: { answerIndex, questionIndex },
    });
  };
}

export function stopExam() {
  return function (dispatch) {
    return dispatch({ type: Constants.STOP_EXAM });
  };
}

export function setExamResult(result) {
  return function (dispatch) {
    return dispatch({ type: Constants.SET_EXAM_RESULT, payload: result });
  };
}

export function resetExam() {
  return function (dispatch) {
    return dispatch({ type: Constants.RESET_EXAM });
  };
}
