import React from 'react';
import { OfflineSettingProps, OfflineSettingSubareaType } from './types';

export const OfflineSetting: React.FunctionComponent<OfflineSettingProps> = ({
  type,
  title,
  checkedFields,
  handleChange,
  subareas,
}) => {
  const fieldType: {
    [key: string]: any;
  } = checkedFields[type];
  return (
    <div className="offlineSettingItem">
      <div
        className="offlineSettingItem--header"
        onClick={() => handleChange(type, 'all')}>
        <p className="offlineSettingItem--title">{title}</p>
        <label className="switch">
          <input type="checkbox" checked={fieldType.all} readOnly />
          <span className="slider round"></span>
        </label>
      </div>
      {fieldType.all &&
        subareas &&
        Object.keys(subareas).map((subarea: string) => {
          return (
            <div
              key={subarea}
              className="offlineSettingItem--header sub"
              onClick={() =>
                handleChange(type, subarea as OfflineSettingSubareaType)
              }>
              <p
                className="offlineSettingItem--title sub"
                dangerouslySetInnerHTML={{ __html: subareas[subarea] }}></p>
              <label className="switch">
                <input type="checkbox" checked={fieldType[subarea]} readOnly />
                <span className="slider round"></span>
              </label>
            </div>
          );
        })}
    </div>
  );
};
