import { Constants } from '../constants';
import { TrackingItem, TrackingState } from 'redux/reducers/types';

export function setTrackings(trackings: TrackingState) {
  return function (dispatch) {
    return dispatch({ type: Constants.SET_TRACKINGS, trackings });
  };
}

export function addTracking(tracking: TrackingItem) {
  return function (dispatch) {
    return dispatch({ type: Constants.ADD_TRACKING, tracking });
  };
}

// export function signIn({ user, token }) {
//   return function (dispatch) {
//     console.log('in signin func');
//     console.log(user);
//     const response = dispatch({ type: Constants.LOGIN, user, token });
//     const { id, first_name, last_name, email, avatar } = user;
//     localStorage.setItem(
//       'user',
//       JSON.stringify({
//         id,
//         first_name,
//         last_name,
//         email,
//         avatar,
//         token,
//       }),
//     );
//     console.log('inside signin query');
//     // const productId = user.product_id
//     //   ? user.product_id
//     //   : process.env.REACT_APP_PRODUCT_ID;
//     // client
//     //   .query({
//     //     query: Product.getQuery(),
//     //     variables: {
//     //       id: parseInt(productId),
//     //     },
//     //   })
//     //   .then((response) => {
//     //     dispatch(setProduct(response.data.product));
//     window.localStorage.setItem('login', 'true');
//     // });

//     return response;
//   };
// }

// export function updateUser(user) {
//   return function (dispatch) {
//     return dispatch({ type: Constants.UPDATE_USER, user });
//   };
// }
