import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from 'react-bootstrap-icons';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';

import { ResultQuestion } from '../../molecules/modal';
import { Circle } from '../../atoms';
import './result.scss';
import { getFormattedDate } from 'libs/utils';
import {
  ResultItem,
  ResultLearningArea,
  ResultQuestionItem,
} from 'types/result';

interface ResultPageProps {
  result: ResultItem;
}

const ResultPage: React.FunctionComponent<ResultPageProps> = ({ result }) => {
  const { t } = useTranslation();
  const RenderIcon = Icon['ChevronDown'];
  let [expandedLearningAreas, setExpandedLearningAreas] = useState<number[]>(
    [],
  );
  const [
    selectedQuestion,
    setSelectedQuestion,
  ] = useState<ResultQuestionItem | null>(null);

  function toggleLearningArea(id: number) {
    resetLearningAreaOnToggle(id);
    const newExpandedLearningAreas: number[] = [];
    expandedLearningAreas.map((id) => {
      newExpandedLearningAreas.push(id);
    });

    if (newExpandedLearningAreas.includes(id)) {
      const existingIndex = newExpandedLearningAreas.indexOf(id);
      if (existingIndex !== -1) {
        newExpandedLearningAreas.splice(existingIndex, 1);
      }
    } else {
      newExpandedLearningAreas.push(id);
    }

    setExpandedLearningAreas(newExpandedLearningAreas);
  }

  function isLearningAreaExpanded(id: number) {
    return expandedLearningAreas.includes(id);
  }

  function resetLearningAreaOnToggle(id: number) {
    if (isLearningAreaExpanded(id)) return;
    setExpandedLearningAreas([]);
  }

  function getLearningAreaClassNames(id: number) {
    return classNames({
      'result-learning-area': true,
      'result-learning-area--is-expanded': isLearningAreaExpanded(id),
    });
  }

  function getPointsClassNames(learningArea: ResultLearningArea | ResultItem) {
    return classNames({
      'result-learning-area__points': true,
      'result-learning-area__points--is-success':
        learningArea.is_success === true,
    });
  }

  function getQuestionTheme(question: ResultQuestionItem) {
    return question.is_correct ? 'success' : 'danger';
  }

  const getIncorrectQuestions: () => ResultQuestionItem[] = () => {
    let incorrectQuestions: ResultQuestionItem[] = [];
    result.learning_areas.map((learningArea) => {
      incorrectQuestions = incorrectQuestions.concat(
        learningArea.questions.filter(
          (question) => question.is_correct !== true,
        ),
      );
    });
    return incorrectQuestions;
  };

  const firstLearningArea = result.learning_areas[0];
  const hasMultipleLearningAreas = result.learning_areas.length > 1;
  const showWrongAnswersPanel =
    hasMultipleLearningAreas && result.count_wrong_questions > 0;
  const isExercise = result.type === 'exercise';

  return (
    <div className="result">
      <div className="result__section-header-wrapper">
        <p className="result__section-header">
          {isExercise ? t('resultDateExercise') : t('resultDateExam')}
          <span className="result__section-header__subinfo">
            {getFormattedDate(result.started_at * 1000)}
          </span>
        </p>
        {!hasMultipleLearningAreas && (
          <p className="result__section-header">
            <span className="result__section-header__label">
              {t('resultLearningArea')}
            </span>
            <span className="result__section-header__subinfo">
              {firstLearningArea.name}
            </span>
          </p>
        )}
        <p className="result__section-header">
          {t('resultIsSuccess')}
          <span
            className={`result__section-header__subinfo ${
              result.is_success ? 'success' : 'error'
            }`}>
            {result.is_success ? t('yes') : t('no')}
          </span>
        </p>
        {hasMultipleLearningAreas && (
          <p className="result__section-header">
            {t('resultAchievedPoints')}
            <span className={getPointsClassNames(result)}>
              {result.points_achieved} / {result.points}
            </span>
          </p>
        )}
      </div>
      {showWrongAnswersPanel && (
        <div
          className="result__item result__item__wrong"
          style={{
            paddingBottom: isLearningAreaExpanded(-1) ? 20 : 0,
          }}>
          <div
            className={getLearningAreaClassNames(-1)}
            onClick={() => toggleLearningArea(-1)}>
            <span className="result-learning-area__label">
              {t('resultWrongAnswers')}
            </span>
            <div>
              <span className="result-learning-area__points">
                {result.count_wrong_questions}
              </span>
              <span className="result-learning-area__arrow">
                <RenderIcon />
              </span>
            </div>
          </div>
          <AnimateHeight
            duration={250}
            height={isLearningAreaExpanded(-1) ? 'auto' : 0}>
            <div className="result-learning-area__questions">
              {getIncorrectQuestions().map((question) => (
                <Circle
                  key={`result-wrong-question-${question.id}`}
                  theme={getQuestionTheme(question)}
                  backgroundTheme={getQuestionTheme(question)}
                  onClick={(e) => {
                    setSelectedQuestion(question);
                    e.persist();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                  }}>
                  {question.index}
                </Circle>
              ))}
            </div>
          </AnimateHeight>
        </div>
      )}

      {result.learning_areas.map((learningArea) => (
        <div
          className={`result__item ${
            !hasMultipleLearningAreas ? 'no-dropdown' : ''
          }`}
          key={`result-learning-area-${learningArea.id}`}
          style={{
            paddingBottom: isLearningAreaExpanded(learningArea.id) ? 20 : 0,
          }}>
          <div
            className={getLearningAreaClassNames(learningArea.id)}
            onClick={() => toggleLearningArea(learningArea.id)}>
            <span className="result-learning-area__label">
              {!hasMultipleLearningAreas ? (
                t('resultAchievedPoints')
              ) : (
                <span dangerouslySetInnerHTML={{ __html: learningArea.name }} />
              )}
            </span>
            <div>
              <span className={getPointsClassNames(learningArea)}>
                {learningArea.points_achieved} / {learningArea.points}
              </span>
              <span className="result-learning-area__arrow">
                <RenderIcon />
              </span>
            </div>
          </div>
          <AnimateHeight
            duration={250}
            height={
              hasMultipleLearningAreas
                ? isLearningAreaExpanded(learningArea.id)
                  ? 'auto'
                  : 0
                : 'auto'
            }>
            <div className="result-learning-area__questions">
              {learningArea.questions.map((question) => (
                <Circle
                  key={`result-question-${question.id}`}
                  theme={getQuestionTheme(question)}
                  backgroundTheme={getQuestionTheme(question)}
                  onClick={(e) => {
                    setSelectedQuestion(question);
                    e.persist();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                  }}>
                  {question.index}
                </Circle>
              ))}
            </div>
          </AnimateHeight>
        </div>
      ))}
      {selectedQuestion && (
        <ResultQuestion
          key={`result-question-${selectedQuestion.id}-modal`}
          question={selectedQuestion}
          setModalIsOpen={(isOpen) => {
            if (!isOpen) {
              setSelectedQuestion(null);
            }
          }}
          modalIsOpen={!!selectedQuestion}
        />
      )}
    </div>
  );
};

export default ResultPage;
