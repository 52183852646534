import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Page from '../../components/pages';
import { signIn } from '../../redux/actions';
import { RegisterPage } from '../../common/pages';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { IonLoading, isPlatform } from '@ionic/react';
import { getAndStoreLicenseAction } from '../../redux/actions/license';
import { Device } from '@capacitor/device';
import { Error } from 'common/molecules/modal';
import Footer from 'components/pages/footers';
import { NOOP } from '../../libs/utils';
import { writeFile } from 'capacitor-blob-writer';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';

interface RegisterProps {
  appElement: any;
  signIn: any;
  product: any;
  storeLicense: any;
}

const apiUrl: string = process.env.REACT_APP_SERVER + '/api/register';
const PRODUCT_ID: any = process.env.REACT_APP_PRODUCT_ID
  ? process.env.REACT_APP_PRODUCT_ID
  : 0;

const Register: React.FC<RegisterProps> = ({
  appElement,
  product,
  signIn,
  storeLicense,
}) => {
  const [registerError, setRegisterError] = useState<null | string>(null);
  const [email, setEmail] = useState('');
  const [file, setFile] = useState(null);
  const [deviceId, setDeviceId] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();

  useEffect(() => {
    if (registerError === '') push(`/login${email && `?email=${email}`}`);
  }, [email, push, registerError]);

  const onAvatarUpload = (file) => {
    setFile(file);
  };

  Device.getInfo().then((device) => {
    setDeviceId(device.uuid);
  });

  function register(data) {
    setLoading(true);
    setEmail(data.email);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    let formData: any = new FormData();
    if (file) {
      formData = file;
    }

    let productId = product.id || PRODUCT_ID;

    formData.append('email', data.email);
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('password', data.password);
    formData.append('c_password', data.c_password);
    formData.append('product_id', productId);

    if (deviceId) {
      formData.append('device_id', deviceId);
    }
    if (isPlatform('ios')) {
      formData.append('platform', 'ios');
    }
    if (isPlatform('android')) {
      formData.append('platform', 'android');
    }

    axios
      .post(apiUrl, formData, config)
      .then((response) => {
        signIn({ user: response.data.user, token: response.data.user.token });
        setLoading(false);
        push('/learning');
        if (response.data.user && response.data.user.license) {
          storeLicense(response.data.user.license);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data) {
          if (error.response.data.hasSameProduct) {
            setRegisterError(
              'Die E-Mail Adresse wird bereits verwendet. Bitte loggen Sie sich stattdessen ein.',
            );
          } else if (error.response.data.hasOtherProduct) {
            setRegisterError(
              'Sie können Ihren APPucations-Account für alle gekauften Kurse verwenden. Loggen Sie sich hierfür einfach mit den bekannten Zugangsdaten ein.',
            );
          }
        }
      });
  }

  let setOnClick = () => push('/onboarding');
  if (!process.env.REACT_APP_PRODUCT_ID) {
    setOnClick = () => push('/login');
  }

  return (
    <Page title="register">
      <div className="banner-wrapper banner-register">
        <IonLoading isOpen={loading} />
        <Error
          appElement={appElement}
          title={'Nutzer existiert bereits'}
          description={registerError}
          modalIsOpen={!!registerError}
          setModalIsOpen={() => setRegisterError('')}
        />
        <RegisterPage
          onAvatarUpload={onAvatarUpload}
          appElement={appElement}
          handleSubmit={register}
          onClick={setOnClick}
          linkToAlreadyRegistered={'/login'}
        />
      </div>
      <Footer />
    </Page>
  );
};

async function getDeviceId() {
  return await Device.getInfo();
}

export default connect(({ appElement, product }) => ({ appElement, product }), {
  signIn,
  storeLicense: getAndStoreLicenseAction,
})(Register);
