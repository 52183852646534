import {Constants} from "../constants";

export function setQueueAction(queue) {
    return function (dispatch) {
        return dispatch({type: Constants.SET_QUEUE, payload: queue});
    }
}

export function removeFromQueueAction(queue) {
  return function (dispatch) {
    return dispatch({type: Constants.REMOVE_FROM_QUEUE, payload: queue});
  }
}
