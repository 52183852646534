export function removeUnUsedButtonOnMobile() {
  if (window.innerWidth <= 800) {
    const rightButtons = document.querySelectorAll(
      '.page-learning .banner .button.button--is-primary.button--has-icon-right',
    );
    if (rightButtons.length > 0) {
      Array.from(rightButtons).map((button) => button.remove());
    }
  }
}

export const logout = function () {
  localStorage.clear();
  window.location.href = '/login';
  if (window.parent) {
    window.parent.postMessage('logout', '*');
  }
  return null;
};

export function NOOP() {
  // No Operation
}

export function countUnansweredQuestions(
  questions: { answers: { is_correct: boolean }[] }[] = [],
) {
  let unansweredCount = 0;

  questions.forEach((question) => {
    unansweredCount = question.answers.some((answer) => answer.is_correct)
      ? unansweredCount
      : unansweredCount + 1;
  });

  return unansweredCount;
}

export function getFormattedDate(timestamp) {
  const date = new Date(timestamp);

  return (
    date.getDate().toString().padStart(2, '0') +
    '.' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '.' +
    date.getFullYear()
  );
}

export function getFormattedTime(timestamp) {
  const date = new Date(timestamp);

  return (
    date.getHours().toString().padStart(2, '0') +
    ':' +
    date.getMinutes().toString().padStart(2, '0') +
    ':' +
    date.getSeconds().toString().padStart(2, '0')
  );
}

export function formatTimestamp(timestamp) {
  const formattedDate = getFormattedDate(timestamp);

  // if (!isTabletOrBigger) {
  //   return formattedDate;
  // }

  const formattedTime = getFormattedTime(timestamp);

  return [formattedDate, formattedTime].join(' ');
}

export function getHumanReadableTimeDiff(
  startTimestamp,
  endTimestamp,
  translate,
) {
  const { t } = translate();
  const timeDiffInSeconds = Math.trunc((endTimestamp - startTimestamp) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = week * 4;
  const year = day * 365;

  let diff = Math.trunc(timeDiffInSeconds / year);
  if (diff >= 1) {
    return diff === 1
      ? t('yearAgo', { number: diff })
      : t('yearsAgo', { number: diff });
  }

  diff = Math.trunc(timeDiffInSeconds / month);
  if (diff >= 1) {
    return diff === 1
      ? t('monthAgo', { number: diff })
      : t('monthsAgo', { number: diff });
  }

  diff = Math.trunc(timeDiffInSeconds / week);
  if (diff >= 1) {
    return diff === 1
      ? t('weekAgo', { number: diff })
      : t('weeksAgo', { number: diff });
  }

  diff = Math.trunc(timeDiffInSeconds / day);
  if (diff >= 1) {
    return diff === 1
      ? t('dayAgo', { number: diff })
      : t('daysAgo', { number: diff });
  }

  diff = Math.trunc(timeDiffInSeconds / hour);
  if (diff >= 1) {
    return diff === 1
      ? t('hourAgo', { number: diff })
      : t('hoursAgo', { number: diff });
  }

  diff = Math.trunc(timeDiffInSeconds / minute);
  if (diff >= 1) {
    return diff === 1
      ? t('minuteAgo', { number: diff })
      : t('minutesAgo', { number: diff });
  }

  return t('now');
}

export const getStringSizeInBytes: (str: string) => number = (str: string) =>
  new Blob([str]).size;

export const bytesToMegabyte: (b: number) => string = (b: number) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (b === 0) return '0 Byte';
  var i: number = Math.floor(Math.log(b) / Math.log(1024));
  return (b / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

export const replaceHTMLEntitiesAndTags = (text) => {
  const textWithoutEntities = text.replace(/(&.+?;)/gi, '');
  return textWithoutEntities.replace(/<\/?[^>]+(>|$)/g, '');
};

export const blobToBase64: (blob: Blob) => Promise<string> = (blob: Blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};
