import React from 'react';
import classNames from 'classnames';
import * as Icon from 'react-bootstrap-icons';
import './roundbutton.scss';

interface RoundButtonProps {
  onClick?: React.MouseEventHandler;
  theme?: string;
  text?: string;
  icon: string;
  clazz?: string;
  transparent?: boolean;
  iconSize?: number;
  style?: {};
}

const RoundButton: React.FunctionComponent<RoundButtonProps> = ({
  onClick,
  theme,
  text,
  icon = 'Pencil',
  clazz = '',
  transparent = false,
  iconSize = 38,
  style = {},
}) => {
  const className = classNames({
    'round-button': true,
    'round-button--is-primary': theme === 'primary',
    'round-button--is-secondary': theme === 'secondary',
    'round-button--is-tertiary': theme === 'tertiary',
    'round-button--is-success': theme === 'success',
    'round-button--is-danger': theme === 'danger',
    'round-button--is-transparent': transparent,
    'round-button--is-slim': iconSize < 38,
    [clazz]: clazz !== null,
  });
  const RenderIcon = Icon[icon];
  return (
    <div className={className} onClick={onClick} style={style}>
      <div
        className="round-button-icon"
        style={{ width: iconSize + 'px', height: iconSize + 'px' }}>
        <RenderIcon style={{ width: iconSize / 2 + 'px' }} />
      </div>
      {text && <span className="round-button-text">{text}</span>}
    </div>
  );
};

export default RoundButton;
