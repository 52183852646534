import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '../../../organisms';
import { RoundButton, Circle } from '../../../atoms';
import { countUnansweredQuestions } from '../../../../libs/utils';
import './examNavigation.scss';

interface ExamNavigationProps {
  childrenBefore: JSX.Element;
  setModalIsOpen: Function;
  modalIsOpen: boolean;
  questions: { answers: { is_correct: boolean }[] }[];
  activeQuestionId: number;
  navigateTo: (question: number) => void;
}

const ExamNavigation: React.FunctionComponent<ExamNavigationProps> = ({
  childrenBefore,
  setModalIsOpen,
  modalIsOpen,
  questions,
  activeQuestionId,
  navigateTo,
}) => {
  const { t } = useTranslation();
  const unansweredCount = countUnansweredQuestions(questions);

  function handleNavigateTo(index: number) {
    navigateTo(index);
    setModalIsOpen(false);
  }

  function isActiveQuestion(question) {
    return question.id === activeQuestionId;
  }

  function hasQuestionCheckedAnswers(question) {
    return question.answers.some((answer) => answer.is_correct);
  }

  function getCircleTheme(question) {
    if (isActiveQuestion(question)) {
      return 'tertiary';
    }
    if (hasQuestionCheckedAnswers(question)) {
      return 'secondary';
    }
    return null;
  }

  return (
    <BaseModal
      baseClass="exam-navigation"
      childrenBefore={childrenBefore}
      setModalIsOpen={setModalIsOpen}
      modalIsOpen={modalIsOpen}>
      <div className="exam-navigation__header">
        <div className="exam-navigation__header-item exam-navigation__header-item--is-danger">
          {unansweredCount > 0 &&
            t('countUnansweredQuestions', { unansweredCount })}
        </div>
        <div className="exam-navigation__header-item">
          <RoundButton
            transparent
            theme="tertiary"
            icon="XCircle"
            iconSize={28}
            onClick={() => {
              setModalIsOpen(false);
            }}
          />
        </div>
      </div>
      <div className="exam-navigation__question-grid">
        {questions.map((question, index) => {
          return (
            <Circle
              key={`question-${index}`}
              theme={getCircleTheme(question)}
              backgroundTheme={
                hasQuestionCheckedAnswers(question) ? 'secondary' : null
              }
              onClick={() => handleNavigateTo(index)}>
              {index + 1}
            </Circle>
          );
        })}
      </div>
    </BaseModal>
  );
};

export default ExamNavigation;
