import React from 'react';
import { t } from '../../../config/translation';
import { NOOP } from '../../../libs/utils';
import Banner from '../../molecules/banner';
import FormWrapper from '../../organisms/formWrapper';
import LostPasswordForm from '../../organisms/lostPasswordForm';

export default function LostPassword({
  headline = 'resetPasswordHeadline',
  onClick = NOOP,
  handleSubmit = NOOP,
  description,
  appElement,
}) {
  const editable = {
    email: {
      name: 'email',
      labelText: t('email'),
    },
  };

  let user = {
    email: '',
  };

  return (
    <Banner headline={t(headline)} onClick={onClick}>
      <div className="lost-password-form">
        <p>{description}</p>
        <FormWrapper
          validation={null}
          initialValues={user}
          editable={editable}
          handleSubmit={handleSubmit}
          appElement={appElement}>
          <LostPasswordForm />
        </FormWrapper>
      </div>
    </Banner>
  );
}
