import React from 'react';

export const OfflineIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42">
    <g id="Gruppe_103" data-name="Gruppe 103" transform="translate(-189 -79)">
      <circle
        id="Ellipse_96"
        data-name="Ellipse 96"
        cx="21"
        cy="21"
        r="21"
        transform="translate(189 79)"
        fill="#fff"
      />
      <g id="wifi_off_black_24dp" transform="translate(196.754 87.754)">
        <path
          id="Pfad_44"
          data-name="Pfad 44"
          d="M0,0H26.492V26.492H0Z"
          fill="none"
        />
        <path
          id="Pfad_45"
          data-name="Pfad 45"
          d="M23.077,11.972l2.208-2.208A17.151,17.151,0,0,0,10.162,5.007L13.01,7.855A14.07,14.07,0,0,1,23.077,11.972ZM20.869,14.18a10.922,10.922,0,0,0-4.106-2.572L20.1,14.941l.773-.762ZM9.831,18.595l3.311,3.311,3.311-3.311A4.677,4.677,0,0,0,9.831,18.595ZM3.66,1.64,2.1,3.2,5.471,6.563A17.333,17.333,0,0,0,1,9.764l2.208,2.208A13.962,13.962,0,0,1,7.811,8.9l2.473,2.473a10.843,10.843,0,0,0-4.868,2.8l2.208,2.208a7.725,7.725,0,0,1,5.4-2.274l7.815,7.815,1.556-1.556Z"
          transform="translate(0.104 0.17)"
          fill="#34526b"
        />
      </g>
    </g>
  </svg>
);
