import React from 'react';
import classNames from 'classnames';
import './grid.scss';

export default function Grid({ size = 'col-12', children }) {
  const cols = ['col-12', 'col-6'];

  const className = classNames({
    grid: true,
    [`grid--is-${size}`]: cols.indexOf(size) > -1,
  });

  return <div className={className}>{children}</div>;
}
