import React from 'react';
import { RoundButton } from '../../atoms';
import './action.scss';

interface ActionsProps {
  isEditMode: boolean;
  edit: Function;
  save?: Function;
  remove?: Function;
  id?: number;
}

const Actions: React.FunctionComponent<ActionsProps> = ({
  isEditMode,
  edit,
  save,
  remove,
  id,
  children,
}) => {
  return (
    <div className="actions">
      <div className="actions_left">
        {remove && (
          <RoundButton
            icon="Trash"
            theme="danger"
            onClick={() => {
              remove(id);
            }}
          />
        )}
      </div>
      <div className="actions_right">
        {children}
        <RoundButton
          icon={isEditMode ? 'X' : 'Pencil'}
          theme={isEditMode ? 'secondary' : 'tertiary'}
          onClick={() => edit(!isEditMode)}
        />
        {isEditMode && save && (
          <RoundButton
            icon="FileEarmarkCheck"
            theme="success"
            onClick={() => save()}
          />
        )}
      </div>
    </div>
  );
};

export default Actions;
