import _, {cloneDeep, shuffle} from 'lodash';
import {Answer, Exercise, Question} from "./types";

export default class ExamQueries {
  learningAreasData: any = null;
  newSettings: any = null;
  areas: any = null;

  constructor(offlineData) {
    this.newSettings = offlineData.newSettings;
    this.learningAreasData = offlineData;
    this.newSettings = offlineData?.newSettings || null;
    this.areas = offlineData?.learning_areas ? offlineData.learning_areas : [];
  }

  getExam() {
    const questionPerExam = this.newSettings?.exam_max_questions
      ? this.newSettings.exam_max_questions
      : 100;

    const examClone = cloneDeep(this.learningAreasData.exam);
    examClone.questions = shuffle(examClone.questions).splice(
      0,
      questionPerExam,
    );
    for (let i = 0; i < examClone.questions.length; i++) {
      examClone.questions[i]['answers'] = shuffle(
        examClone.questions[i]['answers'],
      );
    }
    examClone.duration = this.newSettings?.exam_time;
    return examClone;
  }

  getExercise(learningAreaId) {
    const exerciseDataClone = cloneDeep(
      this.learningAreasData.exercises.find((exercise) => {
        return exercise.learning_area_id == learningAreaId;
      }),
    );
    exerciseDataClone.questions = shuffle(exerciseDataClone.questions).splice(
      0,
      10,
    );
    for (let i = 0; i < exerciseDataClone.questions.length; i++) {
      exerciseDataClone.questions[i]['answers'] = shuffle(
        exerciseDataClone.questions[i]['answers'],
      );
    }
    return exerciseDataClone;
  }

  getResultsData(type, learningAreaId: any = null) {
    try {
      const results = this.learningAreasData.results;
      return results.filter(
        (result) => result && result.type && result.type === type,
      );
    } catch (e) {
      // logout();
    }
  }

  isAnsweredCorrect(question: Question | false) {
    if (!question) {
      return false;
    }
    const examClone = cloneDeep(this.learningAreasData.exam);
    const examQuestion: any = Object.values(examClone.questions).find((q) => q.id == question.id);
    const parameterCorrects: Answer[] = question.answers.filter(
      (answer: Answer) => answer.is_correct,
    );
    const realCorrects: Answer[] = examQuestion.answers.filter(
      (answer: Answer) => answer.is_correct,
    );

    if (parameterCorrects.length !== realCorrects.length) {
      return false;
    }

    const parameterCorrectsIds: number[]  = parameterCorrects.map((pc) => {
      return pc.id;
    });

    const realCorrectsIds = realCorrects.map((rc: Answer) => {
      return rc.id;
    });

    return _.isEqual(parameterCorrectsIds.sort(), realCorrectsIds.sort());
  }

  isAnsweredCorrectExercise(learningAreaId: string, question: Question) {
    const exerciseDataClone: Exercise = cloneDeep(
      this.learningAreasData.exercises.find(
        (exercise: Exercise) => exercise.learning_area_id === +learningAreaId,
      ),
    );

    const exerciseQuestion: any = Object.values(exerciseDataClone.questions).find(
      (q: any) => q.id == question.id,
    );

    let parameterCorrects: Answer[] = question.answers.filter(
      (answer) => answer.is_correct,
    );
    let realCorrects: Answer[] = exerciseQuestion.answers.filter(
      (answer: Answer) => answer.is_correct,
    );

    if (parameterCorrects.length !== realCorrects.length) {
      return false;
    }

    const parameterCorrectsIds: number[]  = parameterCorrects.map((pc) => {
      return pc.id;
    });

    const realCorrectsIds = realCorrects.map((rc: Answer) => {
      return rc.id;
    });

    return _.isEqual(parameterCorrectsIds.sort(), realCorrectsIds.sort());
  }

  getCorrectAnswerFromQuestion(learningAreaId, questionId, answerId) {
    const learningArea = this.learningAreasData.learning_areas.find(
      (la) => la.id === learningAreaId,
    );
    const question = learningArea.questions.find(
      (question) => question.id === questionId,
    );
    return question.answers.find((answer) => answer.id === answerId);
  }
}
