import * as React from 'react';
import { useRef, useState } from 'react';
import axios from 'axios';
import { useMutation } from '@apollo/react-hooks';
import { User } from '../../../graphql';

interface InterfaceSideBarProfileImageProps {
  user: any;
  updateUser: any;
}

const apiUrl: string = process.env.REACT_APP_SERVER + '/api/file-upload';
//const apiUrl: string = 'https://core-test.appucations.de/api/file-upload';

const SideBarProfileImage: React.FunctionComponent<InterfaceSideBarProfileImageProps> = (
  props: InterfaceSideBarProfileImageProps,
) => {
  const { user, updateUser } = props;
  const fileInputRef: any = useRef();
  const [imgError, setImgError] = useState(false);

  const [updateUserData] = useMutation(User.updateMutation());

  function onFileSelect(fileEvent) {
    const file = fileEvent.target.files?.[0];

    if (!file) {
      return;
    }

    const formData: any = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    // const userId = (user.id + '').replace("'", ''); //todo: fix on api side
    axios
      .post(apiUrl, formData, config)
      .then((response) => {
        updateUserData({
          variables: {
            id: user.id,
            avatar: response.data.default,
          },
        })
          .then((response) => {
            updateUser({ ...response.data.updateUser, onboardingSeen: 1 });
          })
          .catch((e) => console.log(e));
      })
      .catch((error) => console.log(error));
  }

  function onAvatarClick() {
    if (fileInputRef) {
      fileInputRef.current.onchange = onFileSelect;
      fileInputRef.current.click();
    }
  }

  function renderProfileImage() {
    return (
      <div
        className="profile-image-wrapper"
        style={{ backgroundImage: `url(${user.avatar})` }}></div>
    );
  }

  return (
    <div onClick={onAvatarClick} className="sidebar__profile-image">
      <form className="image-upload" encType="multipart/form-data">
        <div className="upload-label">
          {renderProfileImage()}
          <div className="profile-image-name">
            {(user.first_name || '') + ' ' + (user.last_name || '')}
          </div>
        </div>
        <input
          ref={fileInputRef}
          type="file"
          id="image-upload-3"
          className="upload"
          accept="image/*"
          style={{ display: 'none' }}
        />
      </form>
    </div>
  );
};

SideBarProfileImage.defaultProps = {};

export default SideBarProfileImage;
