import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RouteTypes } from 'routes/types';

interface PrivatePageWrapperProps {
  component: any;
  user: { token?: string };
  offlineMode: any;
  type: RouteTypes;
  lastLocation: { pathname: string };
  isReachable?: boolean;
  alternativeRoute?: string;
}

const PrivatePageWrapper: React.FunctionComponent<PrivatePageWrapperProps> = ({
  component,
  user,
  offlineMode,
  isReachable = true,
  alternativeRoute = '',
  lastLocation,
  path,
  type,
}) => {
  // if (type === 'publiconly' && user.token) {
  //   return <Redirect to={lastLocation.pathname || '/learning'}/>;
  // }

  if (
    type === 'private' &&
    !user.token &&
    !window.location.pathname.includes('logout')
  ) {
    return <Redirect to="/login" />;
  }
  if (!isReachable) {
    return <Redirect to={alternativeRoute}/>;
  }

  return React.createElement(component);
};

const mapStateToProps = (state: any) => {
  return {
    offlineMode: state.offlineMode,
    user: state.user,
    lastLocation: state.lastLocation,
  };
};

export default connect(mapStateToProps, {})(PrivatePageWrapper);
