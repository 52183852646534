import { Constants } from '../constants';
import { TrackingItem, TrackingState } from './types';

const initialState: TrackingState = [];

export default function trackings(
  state = initialState,
  action: { trackings?: TrackingState; tracking?: TrackingItem; type: string },
) {
  switch (action.type) {
    case Constants.SET_TRACKINGS:
      const { trackings } = action;
      return trackings;
    case Constants.ADD_TRACKING:
      const { tracking } = action;
      return [...state, tracking];
    case Constants.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
