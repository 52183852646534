import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Paragraph, Progress, Span, Button, Circle } from '../../atoms';
import { PracticeIcon } from '../../atoms/icon/practice';
import { Actions } from '../';
import './listitem.scss';
import { NOOP } from '../../../libs/utils';

interface ListItemProps {
  progressValue?: number;
  title: string;
  subTitle?: string;
  buttonText?: string;
  onClick: React.MouseEventHandler;
  buttonIcon?: string;
  children: JSX.Element;
  removeButton: boolean;
  exerciseButton?: boolean;
  removeProgress?: boolean;
  showActionButtons?: boolean;
  editable?: {
    isEditMode: boolean;
    onChange: Function;
    name: 'string';
    labelText: string;
  };
  id?: number;
  save?: Function;
  remove?: Function;
  isSelected: boolean;
  theme: string;
}

const ListItem: React.FunctionComponent<ListItemProps> = ({
  progressValue,
  title,
  subTitle,
  buttonText,
  onClick,
  buttonIcon = 'learn',
  children,
  removeButton = false,
  exerciseButton = false,
  removeProgress = false,
  showActionButtons = false,
  editable,
  id,
  save,
  remove,
  isSelected = false,
  theme = null,
}) => {
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);
  const className = classNames({
    'list-item': true,
    'list-item--is-clickable': onClick,
    [`list-item--is-${theme}`]: theme !== null,
    'list-item--is-selected': isSelected,
  });

  const itemContentClassNames = classNames({
    'list-item_content': true,
    'list-item_full-width': removeProgress,
  });

  editable = editable || {
    isEditMode: false,
    onChange: NOOP,
    name: 'headline',
    labelText: t('headline'),
  };

  return (
    <div className={className} onClick={onClick}>
      {showActionButtons && (
        <Actions
          isEditMode={isEditMode}
          edit={setIsEditMode}
          id={id}
          save={save}
          remove={remove}
        />
      )}
      <div className={itemContentClassNames}>
        {children
          ? children
          : !removeProgress && <Progress type="static" value={progressValue} />}
        <div
          className={`${
            !subTitle
              ? 'list-item_content-description nosub'
              : 'list-item_content-description'
          }`}>
          <Paragraph
            editable={{
              ...editable,
              isEditMode: isEditMode,
            }}>
            <span
              className="inner"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Paragraph>
          {!!subTitle && <Span overflow>{subTitle}</Span>}
        </div>
        {!removeButton && (
          <Button theme="primary" text={buttonText} iconRight={buttonIcon} />
        )}
        {exerciseButton && (
          <Circle backgroundTheme="secondary" theme="secondary">
            <div
              style={{
                width: '28px',
                marginTop: '5px',
                marginLeft: '2px',
                cursor: 'pointer',
              }}>
              <PracticeIcon />
            </div>
          </Circle>
        )}
      </div>
    </div>
  );
};

export default ListItem;
