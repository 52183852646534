import React from 'react';

function ScreenImage() {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 628 292.2"
      xmlSpace="preserve">
      <style>{'.prefix__st0{fill:#fff}.prefix__st1{fill:#20415c}'}</style>
      <path
        className="prefix__st0"
        d="M274.2 13.1h298.9c9.4 0 17 7.6 17 17v230.6c0 9.4-7.6 17-17 17H274.2c-9.4 0-17-7.6-17-17V30.1c0-9.4 7.7-17 17-17z"
      />
      <path
        className="prefix__st1"
        d="M573.1 280.7H274.2c-11 0-20-9-20-20V30.1c0-11 9-20 20-20h298.9c11 0 20 9 20 20v230.6c0 11-9 20-20 20zM274.2 16.1c-7.7 0-14 6.3-14 14v230.6c0 7.7 6.3 14 14 14h298.9c7.7 0 14-6.3 14-14V30.1c0-7.7-6.3-14-14-14H274.2z"
      />
      <path
        className="prefix__st0"
        d="M247.3 245H601c9.4 0 17 7.5 17 16.8s-7.6 16.8-17 16.8H247.3c-9.4 0-17-7.5-17-16.8s7.6-16.8 17-16.8z"
      />
      <path
        className="prefix__st1"
        d="M601 281.6H247.3c-11 0-20-8.9-20-19.8s9-19.8 20-19.8H601c11 0 20 8.9 20 19.8s-9 19.8-20 19.8zM247.3 248c-7.7 0-14 6.2-14 13.8s6.3 13.8 14 13.8H601c7.7 0 14-6.2 14-13.8s-6.3-13.8-14-13.8H247.3z"
      />
      <path
        className="prefix__st0"
        d="M67 82.1h229.6c9.4 0 17 7.6 17 17v162.4c0 9.4-7.6 17-17 17H67c-9.4 0-17-7.6-17-17V99.1c0-9.4 7.6-17 17-17z"
      />
      <path
        className="prefix__st1"
        d="M296.6 281.5H67c-11 0-20-9-20-20V99.1c0-11 9-20 20-20h229.6c11 0 20 9 20 20v162.4c0 11-9 20-20 20zM67 85.1c-7.7 0-14 6.3-14 14v162.4c0 7.7 6.3 14 14 14h229.6c7.7 0 14-6.3 14-14V99.1c0-7.7-6.3-14-14-14H67z"
      />
      <path
        className="prefix__st0"
        d="M27 139.1h46.3c9.4 0 17 7.6 17 17v106c0 9.4-7.6 17-17 17H27c-9.4 0-17-7.6-17-17v-106c0-9.4 7.6-17 17-17z"
      />
      <path
        className="prefix__st1"
        d="M73.3 282.1H27c-11 0-20-9-20-20v-106c0-11 9-20 20-20h46.3c11 0 20 9 20 20v106c0 11.1-9 20-20 20zM27 142.1c-7.7 0-14 6.3-14 14v106c0 7.7 6.3 14 14 14h46.3c7.7 0 14-6.3 14-14v-106c0-7.7-6.3-14-14-14H27z"
      />
      <path
        className="prefix__st1"
        d="M50 267.1c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zm0-14c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zM287 190.1c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zm0-14c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4z"
      />
    </svg>
  );
}

export default React.memo(ScreenImage);
