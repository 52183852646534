import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { Button } from '../../common/atoms';
import { Error } from '../../common/molecules/modal';
import { Banner } from '../../common/molecules';
import { BaseModal } from '../../common/organisms';
import Page from '../../components/pages';

import './feedbackStyles.scss';

import IcoSuccess from '../../assets/icons/ico_success.svg';

interface InterfaceFeedbackProps {
  user: any;
  product: any;
}

const apiUrl: string = process.env.REACT_APP_SERVER + '/api/feedback-send';

const FeedbackPage: React.FunctionComponent<InterfaceFeedbackProps> = ({
  product,
  user,
}) => {
  const [feedbackValue, setFeedbackValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const sendFeedback = function () {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .post(
        apiUrl,
        {
          message: feedbackValue,
          product: product.name,
        },
        config,
      )
      .then(() => {
        setLoading(false);
        setHasError(false);
        setShowSuccessModal(true);
        setFeedbackValue('');
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setHasError(true);
        setShowSuccessModal(false);
      });
  };

  return (
    <Page>
      <div className="banner-wrapper banner-feedback">
        <Banner headline={t('Feedback')} onClick={goBack}>
          <div className="feedback">
            {/* <IonLoading isOpen={loading} /> */}
            <p>{t('feedbackDescription')}</p>
            <textarea
              value={feedbackValue}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setFeedbackValue(e.target.value)
              }
              className="feedback-form-textarea"
              name="message"
            />
            <div className="submit-button">
              <Button
                disabled={!feedbackValue.length || loading}
                onClick={sendFeedback}
                theme="primary"
                isLoading={loading}
                text={t('sendFeedback')}
              />
            </div>
            <Error
              title={t('feedbackErrorMessageTitle')}
              description={
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('feedbackErrorMessage'),
                  }}
                />
              }
              modalIsOpen={hasError}
              setModalIsOpen={() => setHasError(false)}
            />
            <BaseModal
              setModalIsOpen={setShowSuccessModal}
              modalIsOpen={showSuccessModal}
              className="modal modal-success">
              <div className="overlay">
                <img
                  src={IcoSuccess}
                  className="svg"
                  height="101"
                  alt=""
                />
                <h4>{t('feedbackSuccessHeadline')}</h4>
                <p>{t('feedbackSuccessful')}</p>
                <hr />
                <div className="actions single">
                  <button
                    onClick={() => setShowSuccessModal(false)}
                    className="confirm">
                    <strong>{t('further')}</strong>
                  </button>
                </div>
              </div>
            </BaseModal>
          </div>
        </Banner>
      </div>
    </Page>
  );
};

export default connect(
  ({ product, appDefaults, user }) => ({ product, appDefaults, user }),
  null,
)(FeedbackPage);
