import React from 'react';
import { connect } from 'react-redux';
import Page from '../../../components/pages';
import { useHistory, useParams } from 'react-router';
import {
  prepareExercise,
  startExercise,
} from '../../../redux/actions/exercise';
import { ExerciseSplash } from '../../../common/pages';
import ExamQueries from '../../../libs/ExamQueries';
import LearnQueries from '../../../libs/LearnQueries';

interface ExerciseStartProps {
  startExercise: () => void;
  prepareExercise: (exercise) => void;
  exercise: any;
  product: any;
  offlineMode: any;
}

const ExerciseStart: React.FC<ExerciseStartProps> = ({
  startExercise,
  exercise,
  product,
  prepareExercise,
  offlineMode,
}) => {
  const history = useHistory();
  const { learningAreaId } = useParams();
  const instanceExamQueries = new ExamQueries(offlineMode.data);
  const instanceLearnQueries = new LearnQueries(offlineMode.data);
  const exerciseData = instanceExamQueries.getExercise(learningAreaId);
  const learningAreaData = instanceLearnQueries.getLearningData(learningAreaId);
  exerciseData.name = learningAreaData.headline;
  function handleStartExercise() {
    prepareExercise(exerciseData);
    startExercise();
    history.push(`/exercise/${learningAreaId}/wizard`);
  }

  return (
    <Page isSplash>
      <ExerciseSplash
        key={`exercise-start-${exercise.timer.startDate}`}
        questionCount={10}
        correctAnswersCount={exerciseData.max_count_correct_answers}
        headline={learningAreaData.headline}
        onClick={handleStartExercise}
      />
    </Page>
  );
};

export default connect(
  ({ exercise, product, offlineMode }) => ({ exercise, product, offlineMode }),
  {
    startExercise,
    prepareExercise,
  },
)(ExerciseStart);
