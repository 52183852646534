import { Constants } from '../constants';

const initialState = {
  terms: null,
  imprint: null,
  privacy: null,
};

export default function legal(
  state = initialState,
  action: {
    type: string;
    payload: { terms: string; imprint: string; privacy: string };
  },
) {
  switch (action.type) {
    case Constants.SET_LEGAL_DATA:
      const { terms, imprint, privacy } = action.payload;
      return { ...state, terms, imprint, privacy };
    default:
      return state;
  }
}
