export default {
  translation: {
    '*': '*',
    examModeTitle: 'Prüfungsmodus',
    isExercisable: 'Frage darf in Übungen auftauchen',
    questionTitle: 'Frage',
    points: 'Punkte',
    countPoint: '{{count}} Punkt',
    countPoints: '{{count}} Punkte',
    answer: 'Antwort',
    order: 'Reihenfolge',
    answersTitle: 'Antworten',
    singleChoice: 'Single-Choice-Verfahren',
    multipleChoice: 'Multiple-Choice-Verfahren',
    completeText: 'Lückentextverfahren',
    orderTerms: 'Anordnungsverfahren (korrekte Reihenfolge finden)',
    assignment: '1 zu 1 Einzelzuordnung-Verfahren',
    assignmentMultiple: '1 zu N Mehrfachzuordnung-Verfahren',
    preview: 'Vorschau',
    onboarding: 'Onboarding',
    login: 'Anmelden',
    forgotPassword: 'Kennwort vergessen?',
    resetPassword: 'Zurücksetzen',
    resetPasswordHeadline: 'Kennwort Zurücksetzen',
    gotoLogin: 'Zur Anmeldung',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'Email',
    password: 'Kennwort',
    cPassword: 'Kennwort bestätigen',
    alreadyRegistered: 'Du hast bereits ein Konto?',
    register: 'Registrieren',
    proceed: 'Weiter',
    create: 'Erstellen',
    examSimulation: 'Prüfungssimulation',
    questionWithMinutes: '{{questionCount}} Fragen - {{timeInMinutes}} Minuten',
    examTypeAndCorrectAnswer:
      '{{examType}} (maximal {{correctAnswersCount}} korrekte Antwort)',
    examTypeAndCorrectAnswers:
      '{{examType}} (maximal {{correctAnswersCount}} korrekte Antworten)',
    begin: 'Beginnen',
    fieldIsRequired: '{{name}} ist ein Pflichtfeld',
    fieldIsNotAnEmail: '{{name}} ist keine gültige E-Mail-Adresse',
    fieldsAreNotMatching: 'Die Passwörter stimmen nicht überein',
    fieldIsTooShort: '{{name}} ist zu kurz (mind. {{minimumCount}} Zeichen)',
    ok: 'OK',
    loginFailedHeadline: 'Authentifizierung abgebrochen',
    loginFailedDescription: 'Deine Daten konnten nicht gesendet werden:',
    registrationFailedHeadline: 'Registrierung unvollständig',
    registrationFailedDescription: 'Deine Daten konnten nicht gesendet werden:',
    errorCode: '(Fehler Code {{code}})',
    error: 'Fehler',
    questionCountWithTotal: 'Frage {{count}} / {{totalCount}}',
    finishExam: 'Prüfung Beenden',
    correctAnswers: '(Richtige Antworten: {{countCorrectAnswers}})',
    showCorrectAnswersCount: 'Anzahl der richtigen Antworten je Frage anzeigen',
    duration: 'Dauer in min.',
    headline: 'Titel',
    description: 'Beschreibung',
    confirmFinishExam: 'Prüfung beenden?',
    attentionUnansweredQuestion:
      'Achtung, du hast noch eine unbeantwortete Frage.',
    attentionUnansweredQuestions:
      'Achtung, du hast noch {{unansweredQuestions}} unbeantwortete Fragen.',
    doYouReallyWantToFinishYourExam: 'Willst du die Prüfung wirklich beenden?',
    abort: 'Abbrechen',
    finish: 'Beenden',
    countUnansweredQuestions: '{{unansweredCount}} unbeantwortete Fragen',
    examSuccess: 'Prüfung bestanden',
    examFailed: 'Leider durchgefallen',
    details: 'Details',
    tryAgain: 'Erneut Versuchen',
    typeExam: 'Prüfung',
    typeExercise: 'Übung',
    questionAtIndex: 'Frage {{index}}',
    resultLearningAreas: 'Themen',
    resultWrongAnswers: 'Falsche Antworten',
    noDataFound: 'Keine Daten gefunden',
    yearAgo: 'vor {{number}} Jahr',
    yearsAgo: 'vor {{number}} Jahren',
    monthAgo: 'vor {{number}} Monat',
    monthsAgo: 'vor {{number}} Monaten',
    weekAgo: 'vor {{number}} Woche',
    weeksAgo: 'vor {{number}} Wochen',
    dayAgo: 'vor {{number}} Tag',
    daysAgo: 'vor {{number}} Tagen',
    hourAgo: 'vor {{number}} Stunde',
    hoursAgo: 'vor {{number}} Stunden',
    minuteAgo: 'vor {{number}} Minute',
    minutesAgo: 'vor {{number}} Minuten',
    now: 'gerade eben',
    exerciseTypeAndCorrectAnswer:
      '{{exerciseType}} (maximal {{correctAnswersCount}} korrekte Antwort)',
    exerciseTypeAndCorrectAnswers:
      '{{exerciseType}} (maximal {{correctAnswersCount}} korrekte Antworten)',
    questionWithoutMinutes: '{{questionCount}} Fragen - Ohne Zeitbegrenzung',
    confirmFinishExercise: 'Übung beenden?',
    doYouReallyWantToFinishYourExercise:
      'Willst du die Übung wirklich beenden?',
    correct: 'Gut',
    incorrect: 'Falsch',
    evaluate: 'Auswerten',
    exerciseSuccess: 'Übung bestanden',
    exerciseFailed: 'Leider durchgefallen',
    onBoardingDescriptionStep1:
      'Dein qualitätsgesicherter Lerninhalt für eine optimale Vorbereitung zum Bestehen deiner Prüfung',
    onBoardingDescriptionStep2:
      'Teste dein Wissen mit unseren Übungen und realitätsnahen Prüfungssimulationen',
    onBoardingDescriptionStep3:
      'Verfolge im Profilbereich deinen persönlichen Lernstand anhand detaillierter Statistiken',
    onBoardingDescriptionStep4:
      'Melde dich jetzt an, um die vielen Vorteile zu nutzen oder registriere dich kostenfrei und lerne auf deinem Tablet oder am PC',
    uploadImage: 'Bild hochladen',
    order: 'Order: {{count}}',
    question_count: 'Count: {{count}}',
  },
};
