import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { APP_MODE } from '../../../frontendConfig';
import LicenseHasExpired from '../../../components/licenseHasExpired/licenseHasExpired';
import { Device } from '@capacitor/device';
import { License, User } from '../../../graphql';
import { BaseModal } from 'common/organisms';
import licenseHasExpired from '../../../components/licenseHasExpired/licenseHasExpired';
import { Button, RoundButton } from 'common/atoms';
import { OfflineModeState, UserState } from 'redux/reducers/types';
import { Copyright } from 'common/molecules';
// import offlineMode from 'redux/reducers/offlineModus';

const LICENSE_CHECK = {
  LICENSE_VALID: 'LICENSE_VALID',
  LICENSE_INVALID: 'LICENSE_INVALID',
  LICENSE_CHECK_NOT_STARTED: 'LICENSE_CHECK_NOT_STARTED',
};

interface InterfaceSideBarUserDataProps {
  user: UserState;
  product: any;
  signOut: () => void;
  updateUser: (userData: any) => void;
  onChangePasswordClick: () => void;
  appDefaults: any;
  license: any;
  offlineMode: OfflineModeState;
}

const SideBarUserData: React.FunctionComponent<InterfaceSideBarUserDataProps> = (
  props: InterfaceSideBarUserDataProps,
) => {
  const {
    user,
    signOut,
    updateUser,
    onChangePasswordClick,
    product,
    appDefaults,
    license,
    offlineMode,
  } = props;
  const [userData, setUserData] = useState(user);

  // console.log(userData);
  // const [platform, setPlatform] = useState('');
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [showChangeDataModal, setShowChangeDataModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [checkLicense, setCheckLicense] = useState(
    LICENSE_CHECK.LICENSE_CHECK_NOT_STARTED,
  );

  const { t } = useTranslation();
  const { isOffline } = offlineMode;
  const [updateUserData] = useMutation(User.updateMutation());
  // const licenseQuery = useQuery(License.getUserQuery(), {
  //   variables: {
  //     userId: user.id,
  //     productId: product.id,
  //   },
  // });

  // useEffect(() => {
  //   disableLicenceButtonOnIos();
  // });

  // function disableLicenceButtonOnIos() {
  //   console.log('firing here in comp user data');

  //   Device.getInfo().then((info) => {
  //     setPlatform(info.operatingSystem);
  //   });
  // }

  function onInputChange(event, fieldKey) {
    const newUserData = cloneDeep(user);
    newUserData[fieldKey] = event.target.value;
    setUserData(newUserData);
    if (JSON.stringify(newUserData) === JSON.stringify(user)) {
      setShowSubmitBtn(false);
    } else {
      setShowSubmitBtn(true);
    }
  }

  function saveData() {
    updateUserData({
      variables: {
        id: userData.id,
        first_name: userData.first_name,
        last_name: userData.last_name,
      },
    })
      .then((response) => {
        updateUser(response.data.updateUser);
        setShowChangeDataModal(false);
        setShowSuccessModal(true);
        setShowSubmitBtn(false);
      })
      .catch((e) => console.log(e));
  }

  // function checkLicenceStatus() {
  //   if (!license || !license.time_to_live) {
  //     setCheckLicense(LICENSE_CHECK.LICENSE_INVALID);
  //     return;
  //   }
  //   setCheckLicense(LICENSE_CHECK.LICENSE_VALID);
  // }

  // const saveButtonClassNames = classNames({
  //   'field-item submit-btn animate': true,
  //   hidden: !showSubmitBtn,
  // });

  // function formatDate(dateString) {
  //   const date = new Date(dateString);
  //   return (
  //     (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
  //     '.' +
  //     (date.getMonth() + 1 < 10
  //       ? '0' + (date.getMonth() + 1)
  //       : date.getMonth() + 1) +
  //     '.' +
  //     date.getFullYear()
  //   );
  // }

  return (
    <>
      <div className="sidebar__profile-data">
        <div className="sidebar__links has-icon">
          <div className="user-email">
            <span>{t('loggedInAs')}</span>
            <span>{user.email}</span>
          </div>
          <Link
            className={isOffline ? 'link-disabled' : ''}
            to={isOffline ? '' : '/profile-settings'}>
            <span>{t('changePersonalData')}</span>
            <RoundButton icon="ChevronRight" transparent iconSize={24} />
          </Link>
          {appDefaults.mode === APP_MODE.DESKTOP && (
            <button onClick={onChangePasswordClick}>
              {t('changePassword')}
            </button>
          )}
          {(appDefaults.mode === APP_MODE.MOBILE ||
            appDefaults.mode === APP_MODE.TABLET) && (
            <Link
              className={`only-mobile ${isOffline ? 'link-disabled' : ''}`}
              to={isOffline ? '' : '/change-password'}>
              <span>{t('changePassword')}</span>{' '}
              <RoundButton icon="ChevronRight" transparent iconSize={24} />
            </Link>
          )}
          <Link
            className={`offline-mode ${isOffline ? 'link-disabled' : ''}`}
            to={'/offline-settings'}>
            <span>{t('offlineMode')}</span>
            <RoundButton icon="ChevronRight" transparent iconSize={24} />
          </Link>
          {!isOffline &&
            !process.env.REACT_APP_PRODUCT_ID &&
            userData?.licenses?.length > 1 && (
              <Link
                className={`${isOffline ? 'link-disabled' : ''}`}
                to={'/select-course'}>
                <span>{t('selectCourse')}</span>
                <RoundButton icon="ChevronRight" transparent iconSize={24} />
              </Link>
            )}
          <Link
            className={`${isOffline ? 'link-disabled' : ''} danger`}
            to={isOffline ? '' : '/delete-user-data'}>
            <span>{t('deleteAllMyData.headline')}</span>
            <RoundButton icon="ChevronRight" transparent iconSize={24} />
          </Link>
          <button onClick={signOut} className="danger">
            <span>{t('logout')}</span>
            <RoundButton icon="ChevronRight" transparent iconSize={24} />
          </button>
        </div>
      </div>
      <Copyright />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    offlineMode: state.offlineMode,
    user: state.user,
    appDefaults: state.appDefaults,
    license: state.license,
  };
};

export default connect(mapStateToProps)(SideBarUserData);
