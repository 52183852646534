import React from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import Page from '../../components/pages';
import {Banner} from 'common/molecules';
import Footer from '../../components/pages/footers';
import {getAndStoreLicenseAction} from '../../redux/actions/license';
import {dispatch} from '../../redux/store';
import {resetOfflineData,} from '../../redux/actions/offlineModus';
import {LicenseState, UserState} from 'redux/reducers/types';
import {NOOP} from '../../libs/utils';

interface SelectCourseProps {
  user: UserState;
  license: LicenseState | null;
  resetOfflineData: () => void;
}

const SelectCourse: React.FC<SelectCourseProps> = ({
  user,
  license,
  resetOfflineData,
}) => {
  const { licenses } = user;
  const { t } = useTranslation();
  const { push, goBack } = useHistory();

  const filterOutOldTypeCourses = (licenses: any) => {
    return licenses.filter((license: any) => {
      return !!license.product.settings_id;
    });
  };

  const handleLicenceButton = async (product_id: number) => {
    if (product_id === license?.product_id) {
      goBack();
    } else {
      localStorage.setItem(
        'license',
        JSON.stringify({ product_id: product_id }),
      );
      dispatch(getAndStoreLicenseAction({product_id: product_id}));
      window.localStorage.setItem('lastLocation', '/learning');
      window.location.href = "/learning";
    }
  };

  return (
    <Page title={t('selectCoursePageTitle')}>
      <div className={`banner-infopage banner-wrapper`}>
        <Banner
          headline={t('selectCoursePageTitle')}
          onClick={
            license?.product_id
              ? () => {
                  goBack();
                }
              : NOOP
          }>
          <div>
            {licenses &&
              filterOutOldTypeCourses(licenses).map((newLicense: any) => {
                console.log(newLicense, license, newLicense.product.id === license?.product_id);
                return (
                  <div
                    key={`license-selection-${newLicense.id}`}
                    className={`course-select list-item ${newLicense.product.id === license?.product_id ? 'active' : ''}`}
                    onClick={() => handleLicenceButton(newLicense.product.id)}>
                    <span>{newLicense.product.name}</span>
                  </div>
                )
              })}
          </div>
        </Banner>
      </div>

      <Footer />
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetOfflineData: () => dispatch(resetOfflineData()),
});

export default connect(
  ({ user, license }) => ({
    user,
    license,
  }),
  mapDispatchToProps,
)(SelectCourse);
