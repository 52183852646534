import { Constants } from '../constants';
import { APP_MODE, smartphoneWidth, tabletWidth } from '../../frontendConfig';

const initialState = {
  mode:
    window.innerWidth > smartphoneWidth
      ? window.innerWidth > tabletWidth
        ? APP_MODE.DESKTOP
        : APP_MODE.TABLET
      : APP_MODE.MOBILE,
};

export default function appDefaults(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_APP_MODE:
      const mode = action.mode;
      return {
        ...state,
        mode,
      };
    default:
      return state;
  }
}
