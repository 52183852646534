import { Constants } from '../constants';
import { cloneDeep } from 'lodash';

const initialState = {
  data: null,
  isOffline: false,
};

export default function offlineMode(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_OFFLINE_MODUS_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case Constants.SET_OFFLINE_MODUS_RESULT: {
      const newList = cloneDeep(state.data);
      newList.results.push(action.payload);
      return {
        data: newList,
      };
    }
    case Constants.RESET_OFFLINE_MODUS_DATA: {
      return {
        ...state,
        data: null,
      };
    }
    case Constants.UPDATE_IS_OFFLINE: {
      return {
        isOffline: action.payload,
      };
    }
    default:
      return state;
  }
}
