import React from 'react';
import { t } from '../../../config/translation';
import { Link, Button } from '../../atoms';

import './loginForm.scss';

interface LoginFormProps {
  editable?: {
    email: {
      labelText: string;
    };
    password: {
      labelText: string;
    };
    onChange: React.ChangeEventHandler<HTMLInputElement>;
  };
  values?: {
    email: string;
    password: string;
  };
  save?: React.MouseEventHandler;
  linkToForgotPassword: string;
  isLoading: boolean;
}

const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  editable,
  values,
  save,
  linkToForgotPassword = '#',
  isLoading,
}) => {
  return editable && values ? (
    <div className="login-form">
      <div className="login-form__inputs">
        <div className="login-form__field">
          <span className="label">{editable.email.labelText}</span>
          <input
            value={values.email}
            onChange={editable.onChange}
            {...editable.email}
          />
        </div>
        <div className="login-form__field">
          <span className="label">{editable.password.labelText}</span>
          <input
            value={values.password}
            type="password"
            onChange={editable.onChange}
            {...editable.password}
          />
        </div>
      </div>

      <div className="login-form__actions">
        <Button
          isLoading={isLoading}
          onClick={() => save()}
          text={'Anmelden'}
          loadingColor="#339bf2"
        />
        <Link to={linkToForgotPassword}>{t('forgotPassword')}</Link>
      </div>
    </div>
  ) : null;
};

export default LoginForm;
