import React from 'react';

export const ArrowIcon: React.FunctionComponent<{ iconSize?: string }> = ({
  iconSize = '34',
}) => {
  return (
    <svg
      style={{ left: '10px', height: iconSize + 'px' }}
      viewBox="0 -5 28 28"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.556 1.333l6.666 6.73L1.6 14.668"
        strokeWidth="2.222"
        stroke="#FFF"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// export const ArrowIcon;
