import React, { useState } from 'react';
import { connect } from 'react-redux';
import Page from '../../../../components/pages';
import { useHistory, useParams } from 'react-router';
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { useMutation } from '@apollo/react-hooks';
import { Result, ResultInput } from '../../../../graphql';
import {
  setExerciseResult,
  stopExercise,
  toggleExerciseAnswer,
} from '../../../../redux/actions/exercise';
import { ExerciseWizardPage } from '../../../../common/pages';
import ExamQueries from '../../../../libs/ExamQueries';
import { setQueueAction } from '../../../../redux/actions/queue';
import { setOfflineResults } from '../../../../redux/actions/offlineModus';
import LoadingOverlay from 'common/organisms/loadingOverlay';

interface ExerciseWizardProps {
  appElement: string;
  stopExercise: () => void;
  toggleExerciseAnswer: (payload) => void;
  setExerciseResult: (payload) => void;
  exercise: any;
  product: any;
  offlineMode: any;
  setOfflineResults: any;
  setQueue: any;
}

const ExerciseWizard: React.FC<ExerciseWizardProps> = ({
  appElement,
  offlineMode,
  stopExercise,
  toggleExerciseAnswer,
  setExerciseResult,
  exercise,
  product,
  setOfflineResults,
  setQueue,
}) => {
  const history = useHistory();
  const { learningAreaId } = useParams<any>();
  const [createResult] = useMutation(Result.createMutation());
  const [resultCreated, setResultCreated] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const question = exercise.questions[questionIndex] || null;
  const [exerciseFinished, setExerciseFinished] = useState(false);

  const instanceExamQueries = new ExamQueries(offlineMode.data);

  useIonViewWillEnter(() => {
    setExerciseFinished(false);
    setResultCreated(false);
  }, [setResultCreated]);

  useIonViewWillLeave(() => {
    if (!resultCreated) {
      onClickFinish();
    }
  }, [resultCreated]);

  let counter = 0;
  let point = 0;

  exercise.questions = exercise.questions.map((question, index) => {
    question.answers = question.answers.map((ans) => {
      const ansCorrect = instanceExamQueries.getCorrectAnswerFromQuestion(
        question.learning_area.id,
        question.id,
        ans.id,
      );

      ans.needs_check = ansCorrect.is_correct; // shows true options
      return ans;
    });
    return question;
  });

  const handleRequestOfflineStorage = (endDate: number) => {
    exercise.questions.forEach((question) => {
      if (
        instanceExamQueries.isAnsweredCorrectExercise(learningAreaId, question)
      ) {
        ++counter;
        point += question.points;
      }
    });

    if (!resultCreated) {
      let totalPoints = 0;
      exercise.questions.forEach(
        (question) => (totalPoints += question.points),
      );
      const id = Math.trunc(Date.now() / 1000 + Math.random() * 1000);

      exercise.points = totalPoints;
      exercise.points_achieved = point;
      exercise.questions = exercise.questions.map((question, index) => {
        question.index = index + 1;
        question.answers = question.answers.map((ans) => {
          return {
            ...ans,
            is_checked: ans.is_correct,
            is_correct: ans.is_correct && ans.needs_check,
          };
        });

        const availableCorrectAnswers = question.answers.filter(
          (question) => question.needs_check,
        ).length;
        const correctAnswers = question.answers.filter(
          (question) => question.is_correct,
        ).length;
        question.is_correct = correctAnswers === availableCorrectAnswers;

        return question;
      });

      const result = {
        id,
        count_correct_questions: counter,
        count_wrong_questions: exercise.questions.length - counter,
        duration:
          endDate / 1000 -
          Date.parse(exercise.timer.startDate.toString()) / 1000,
        finished_at: endDate / 1000,
        is_success: counter == exercise.questions.length,
        learning_areas: [
          {
            id: exercise.id,
            name: exercise.name,
            questions: exercise.questions,
            points_achieved: point,
            points: totalPoints,
          },
        ],
        points: totalPoints,
        name: exercise.name,
        points_achieved: point,
        started_at: Date.parse(exercise.timer.startDate.toString()) / 1000,
        type: 'exercise',
        __typename: 'Result',
      };

      setQueue({
        request: {
          variables: new ResultInput(
            exercise,
            product.id,
            'exercise',
          ).toGraphqlVariables(),
        },
        type: 'exerciseResult',
        id,
      });
      setExerciseResult(result);
      setOfflineResults(result);
      setResultCreated(true);
      stopExercise();
      setQuestionIndex(0);
      history.push(`/exercise/${learningAreaId}/finished`);
    }
  };

  async function onClickFinish() {
    setExerciseFinished(true);
    const endDate = Date.parse(new Date().toString());
    if (offlineMode.isOffline) handleRequestOfflineStorage(endDate);
    const response = await createResult({
      variables: new ResultInput(
        exercise,
        product.id,
        'exercise',
      ).toGraphqlVariables(),
    })
      .then((response) => {
        setExerciseResult(response.data.createResult);
        setOfflineResults(response.data.createResult);
        setResultCreated(true);
        stopExercise();
        setQuestionIndex(0);
        history.push(`/exercise/${learningAreaId}/finished`);
      })
      .catch((e) => handleRequestOfflineStorage(endDate));
  }

  function onClickNext() {
    if (!exerciseFinished) {
      if (questionIndex < exercise.questions.length - 1) {
        setQuestionIndex(questionIndex + 1);
      } else {
        onClickFinish();
      }
    }
  }

  let pointsAchieved = 0;
  let countTrue = 0;

  function getQuestionResult() {
    const isTrue = instanceExamQueries.isAnsweredCorrectExercise(
      learningAreaId,
      question,
    );
    if (isTrue) {
      countTrue++;
      pointsAchieved += question.points;
    }
    return {
      answers: exercise.questions[questionIndex].answers,
      id: exercise.questions[questionIndex].id,
      is_correct: isTrue,
    };
  }

  return (
    <>
      <Page title="simulateExercise" hideFooter>
        {question && (
          <ExerciseWizardPage
            appElement={appElement}
            exercise={exercise}
            questionIndex={questionIndex}
            onToggleAnswer={toggleExerciseAnswer}
            getQuestionResult={getQuestionResult}
            onClickNext={onClickNext}
            onClickFinish={onClickFinish}
          />
        )}
      </Page>
      {!resultCreated && exerciseFinished && (
        <LoadingOverlay color="rgba(245,173,36,.8)" />
      )}
    </>
  );
};

export default connect(
  ({ exercise, appElement, product, offlineMode }) => ({
    exercise,
    appElement,
    product,
    offlineMode,
  }),
  {
    stopExercise,
    toggleExerciseAnswer,
    setExerciseResult,
    setQueue: setQueueAction,
    setOfflineResults,
  },
)(ExerciseWizard);
