import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@appucations/ckeditor/build/ckeditor';
import './wysiwyg.scss';

interface WysiwygProps {
  data: any;
  config: any;
  onChange: any;
}

const Wysiwyg: React.FunctionComponent<WysiwygProps> = ({
  data,
  config,
  onChange,
}) => (
  <CKEditor
    editor={ClassicEditor}
    data={data}
    config={config}
    onChange={onChange}
  />
);

export default Wysiwyg;
