export const initializeSQLiteTables = `
CREATE TABLE IF NOT EXISTS
    offline_settings(
        type CHAR,
        stored BOOLEAN
    );

CREATE TABLE IF NOT EXISTS
    learning(
        version NUMBER,
        title TEXT,
        content LONGTEXT
    );

CREATE TABLE IF NOT EXISTS
    learning_images(
        id INT PRIMARY KEY NOT NULL,
        content LONGTEXT
    );

CREATE TABLE IF NOT EXISTS
    learning_videos(
        version NUMBER,
        content LONGTEXT
    );

CREATE TABLE IF NOT EXISTS
    exercise(
        version NUMBER,
        title TEXT,
        content LONGTEXT
    );

CREATE TABLE IF NOT EXISTS
    statistics(
        version NUMBER,
        content LONGTEXT
    );

CREATE TABLE IF NOT EXISTS
    product(
        version NUMBER,
        content LONGTEXT
    );
`;

export const clearTables = `
    DELETE FROM learning;
    DELETE FROM learning_images;
    DELETE FROM learning_videos;
    DELETE FROM exercise;
    DELETE FROM statistics;
    DELETE FROM product;
`;
