import {Constants} from '../constants';

const storedLicense = localStorage.getItem('license');

const initialState = process.env.REACT_APP_PRODUCT_ID ? {
  product_id: +process.env.REACT_APP_PRODUCT_ID
} : storedLicense ? (JSON.parse(storedLicense)) : {};

export default function license(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_LICENSE:
      return {
        ...state.license,
        ...action.license,
      };
    default:
      return state;
  }
}
