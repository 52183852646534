import axios from 'axios';
import { Button } from 'common/atoms';
import { Banner } from 'common/molecules';
import { Page } from 'components/pages';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { UserState } from 'redux/reducers/types';
import { store } from 'redux/store';

export const DeleteUserData = () => {
  const [confirmationField, setConfirmationField] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const { t } = useTranslation();
  const history = useHistory();

  const user = store.getState().user as UserState;
  const { token } = user;

  const validateDeletion = () => {
    if (
      token &&
      confirmationField.toLowerCase() === t('delete').toLowerCase()
    ) {
      axios
        .create({
          baseURL: `${process.env.REACT_APP_SERVER}/api`,
        })
        .delete('/me/delete', { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => res.data.success && history.push('/logout'));
    } else {
      setError(true);
    }
  };

  return (
    <Page>
      <div className="banner-wrapper banner-feedback">
        <Banner
          headline={t('deleteAllMyData.headline')}
          onClick={() => history.push(`/profile/`)}>
          <div className="profile-delete-user-data">
            <p>{t('deleteAllMyData.message')}</p>

            <div className={`field-item ${error ? 'has-error' : ''}`}>
              <input
                value={confirmationField}
                onChange={(e) => {
                  error && setError(false);
                  setConfirmationField(e.target.value);
                }}
              />
            </div>

            <Button
              disabled={
                confirmationField.length === 0 ||
                confirmationField.toLowerCase() !== t('delete').toLowerCase()
              }
              onClick={validateDeletion}
              theme="danger"
              text={t('delete')}
            />
          </div>
        </Banner>
      </div>
    </Page>
  );
};
