import React from 'react';
import { Headline, Divider, Button, Span } from '../../atoms';
import './examCard.scss';

export default function ExamCard({
  headline = null,
  description,
  buttonText,
  onClick,
  styleDescription = false,
  withMarker = true,
}) {
  return (
    <div className="exam-card">
      <div className="exam-card-body">
        {headline !== null && <Headline type="h2">{headline}</Headline>}
        {description &&
          (styleDescription ? (
            <Span withMarker={withMarker} type="secondary">
              {description}
            </Span>
          ) : (
            <Span>{description}</Span>
          ))}
        <Divider size="full" />
        <Button
          onClick={onClick}
          theme="secondary"
          text={buttonText}
          iconRight="practice"
        />
      </div>
    </div>
  );
}
