import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useIonViewWillLeave } from '@ionic/react';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';

import { BaseModal } from 'common/organisms';
import { Banner } from 'common/molecules';
import { Button } from 'common/atoms';
import { User } from '../../graphql';
import Page from '../../components/pages';
import { updateUser } from '../../redux/actions';
import { UserState } from 'redux/reducers/types';

import IcoSuccess from '../../assets/icons/ico_success.svg';

interface InterfaceChangePasswordProps {
  user: UserState;
  updateUser: (userData: UserState) => void;
}

const ChangePersonalData: React.FunctionComponent<InterfaceChangePasswordProps> = ({
  user,
  updateUser,
}) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const [firstName, setFirstName] = useState(user.first_name || '');
  const [lastName, setLastName] = useState(user.last_name || '');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [updateUserData] = useMutation(User.updateMutation());

  useIonViewWillLeave(() => {
    setFirstName(user.first_name || '');
    setLastName(user.last_name || '');
  });

  function submitNameChange() {
    updateUserData({
      variables: {
        id: user.id,
        first_name: firstName,
        last_name: lastName,
      },
    })
      .then((response) => {
        updateUser({ ...response.data.updateUser, onboardingSeen: 1 });
        setShowSuccessModal(true);
      })
      .catch((e) => console.log(e));
  }

  return (
    <Page>
      <div className="banner-wrapper banner-feedback">
        <Banner headline={t('changePersonalData')} onClick={goBack}>
          <div className="profile-edit-password-form">
            <div className="form-wrapper">
              <div
                className={
                  'field-item first-name' +
                  (!firstName.length ? ' has-error' : '')
                }>
                <span>{t('firstName')}</span>
                <input
                  value={firstName}
                  type="text"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div
                className={
                  'field-item last-name' +
                  (!lastName.length ? ' has-error' : '')
                }>
                <span>{t('lastName')}</span>
                <input
                  value={lastName}
                  type="text"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <Button
              disabled={
                !firstName.length ||
                !lastName.length ||
                (firstName === user.first_name && lastName === user.last_name)
              }
              onClick={submitNameChange}
              theme="primary"
              text={t('save')}
            />
          </div>
          <BaseModal
            appElement="#root"
            modalIsOpen={showSuccessModal}
            className="modal modal-success">
            <div className="overlay">
              <img
                src={IcoSuccess}
                className="svg"
                height="101"
                alt=""
              />
              <h4>{t('profileUpdateSuccessfulHeadline')}</h4>
              <p>{t('profileUpdateSuccessful')}</p>
              <hr />
              <div className="actions single">
                <button
                  onClick={() => {
                    setShowSuccessModal(false);
                    goBack();
                  }}
                  className="confirm">
                  <strong>{t('OK')}</strong>
                </button>
              </div>
            </div>
          </BaseModal>
        </Banner>
      </div>
    </Page>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    appDefaults: state.appDefaults,
  };
};

export default connect(mapStateToProps, { updateUser })(ChangePersonalData);
