import { Constants } from '../constants';

export function setLastLocation(location) {
  return function (dispatch) {
    // console.log(location);
    window.localStorage.setItem('lastLocation', location.pathname);
    return dispatch({ type: Constants.SET_LAST_LOCATION, location });
  };
}

export function resetLastLocation() {
  return function (dispatch) {
    window.localStorage.removeItem('lastLocation');
    return dispatch({ type: Constants.RESET_LAST_LOCATION });
  };
}
