import React from 'react';
import { useTranslation } from 'react-i18next';
import { Span, Headline, Divider, Button } from '../../atoms';
import { ContentCardProps } from '../learningAreaCard';
import '../learningAreaCard/card.scss';

const ExerciseCard: React.FunctionComponent<ContentCardProps> = ({
  headline,
  description,
  buttonText,
  onClick,
  totalCount,
  currentCount,
}) => {
  const { t } = useTranslation();
  return (
    <div className="content-card">
      <div className="content-card-header">
        <Span uppercase type="tertiary">
          <span className="tablet-only">{t('typeExercise')} </span>
          {currentCount} / {totalCount}
        </Span>
      </div>
      <div className="content-card-body">
        {headline !== null && (
          <div style={{ marginBottom: '60px', flexGrow: 1 }}>
            <Headline type="h2">
              <div
                style={{ minHeight: '60px' }}
                dangerouslySetInnerHTML={{ __html: headline }}
              />
            </Headline>
          </div>
        )}

        {description && (
          <Span withMarker={true} type="secondary">
            {description}
          </Span>
        )}

        <Divider size="auto" />
        <Button
          style={{
            gridTemplateColumns: '45px 1fr 45px',
            maxWidth: '248px',
          }}
          onClick={onClick}
          theme="secondary"
          text={buttonText}
          iconRight="arrow"
        />
      </div>
    </div>
  );
};

export default ExerciseCard;
