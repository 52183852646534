import {Constants} from "../constants";

const initialState = {
    pathname: '/learning',
};

export default function lastLocation(state = initialState, action) {
    switch (action.type) {
        case Constants.SET_LAST_LOCATION:
            const newPathname = action.location.pathname;
            return {
                ...state,
                pathname: newPathname === '/login' || newPathname === '/' ? initialState.pathname : newPathname,
            };
        case Constants.RESET_LAST_LOCATION:
            return initialState;
        default:
            return state;
    }
}
