import React, { useEffect, useState } from 'react';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { App as CapacitorApp } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { TranslationProvider } from './common/organisms';
import de from './i18n/de';
import en from './i18n/de';
/* Theme variables */
import './theme/fonts.scss';
import './theme/variables.css';
import './theme/common.scss';
import './theme/ios.scss';
import './theme/mobile.scss';
import './theme/tablet.scss';
import './theme/desktop.scss';
import './theme/android.scss';
import Routes from './routes';
import { client } from './graphql';
import { ApolloProvider } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { setLegalPages } from 'redux/actions/legal';
import axios from 'axios';
import { SQLiteHook, useSQLite } from 'react-sqlite-hook';
import { initializeSQLiteTables } from 'redux/queries';
import {
  CapacitorSQLite,
  SQLiteConnection,
  SQLiteDBConnection,
} from '@capacitor-community/sqlite';

interface JsonListenerInterface {
  jsonListeners: boolean;
  setJsonListeners: React.Dispatch<React.SetStateAction<boolean>>;
}
interface existingConnInterface {
  existConn: boolean;
  setExistConn: React.Dispatch<React.SetStateAction<boolean>>;
}

export let sqlite: SQLiteHook;
export let existingConn: existingConnInterface;

const App: React.FC<{
  legal: {
    terms: string | null;
    privacy: string | null;
    imprint: string | null;
  };
  setLegalPages: Function;
  license: any | null;
}> = ({ legal: legalPages, setLegalPages, license }) => {
  const [existConn, setExistConn] = useState(false);
  existingConn = { existConn: existConn, setExistConn: setExistConn };
  const {
    echo,
    getPlatform,
    createConnection,
    closeConnection,
    retrieveConnection,
    retrieveAllConnections,
    closeAllConnections,
    addUpgradeStatement,
    importFromJson,
    isJsonValid,
    copyFromAssets,
    isAvailable,
    isConnection,
    checkConnectionsConsistency,
  } = useSQLite();
  sqlite = {
    echo,
    isConnection,
    getPlatform,
    createConnection,
    closeConnection,
    retrieveConnection,
    retrieveAllConnections,
    closeAllConnections,
    addUpgradeStatement,
    importFromJson,
    isJsonValid,
    copyFromAssets,
    checkConnectionsConsistency,
    isAvailable,
  };

  const { terms, privacy, imprint } = legalPages;

  useEffect(() => {
    if (!terms || !privacy || !imprint) {
      axios
        .get(`${process.env.REACT_APP_SERVER}/api/legal`)
        .then((res: any) => {
          const { agb: terms, dse: privacy, impressum: imprint } = res.data;
          setLegalPages({ terms, privacy, imprint });
        })
        .catch((err) => console.log(err));
    }
  }, [imprint, privacy, setLegalPages, terms]);

  useEffect(() => {
    // initializeDatabase();
    CapacitorApp.addListener('backButton', window.history.back);
    document.addEventListener('deviceready', () => {
      setTimeout(() => {
        SplashScreen.hide({
          fadeOutDuration: 1000,
        });
      }, 2000);
    });
  }, []);

  // useEffect( () => {
  //   if(license.product_id) {
  //     initializeDatabase(license.product_id);
  //   }
  // }, [license] );

  // const initializeDatabase = async (productId?: number) => {
  //   try {
  //     let db;
  //     const ret = await sqlite.checkConnectionsConsistency();
  //     const isConn = await sqlite.isConnection(
  //       'appucations' + (process.env.REACT_APP_PRODUCT_ID || productId || ""),
  //     );
  //     if (!isConn.result && !ret.result) {
  //       db = await sqlite.createConnection(
  //         'appucations' + (process.env.REACT_APP_PRODUCT_ID || productId || ""),
  //         false,
  //         'no-encryption',
  //         1,
  //       );
  //     } else {
  //       db = await sqlite.retrieveConnection(
  //         'appucations' + (process.env.REACT_APP_PRODUCT_ID || productId || ""),
  //       );
  //     }
  //     await db.open();
  //     await db.execute(initializeSQLiteTables);
  //   } catch (e) {
  //     console.log(e);
  //   }

  // };

  // const initializeDatabase = async () => {
  //   const connection: SQLiteConnection = new SQLiteConnection(CapacitorSQLite);
  //   const connectionName =
  //     'appucations' + (process.env.REACT_APP_PRODUCT_ID || productId || '');
  //   try {
  //     const ret = await connection.checkConnectionsConsistency();
  //     const isConn = await connection.isConnection(connectionName);
  //     let db: SQLiteDBConnection;
  //     if (ret.result && isConn.result) {
  //       db = await connection.retrieveConnection(connectionName);
  //     } else {
  //       db = await connection.createConnection(
  //         connectionName,
  //         false,
  //         'no-encryption',
  //         1,
  //       );
  //     }
  //     await db.open();
  //     await db.execute(initializeSQLiteTables);
  //     await db.close();
  //     await connection.closeConnection(connectionName);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };;

  return (
    <IonApp>
      <TranslationProvider translationsObject={{ de, en }}>
        <IonReactRouter>
          <ApolloProvider client={client}>
            <Routes />
          </ApolloProvider>
        </IonReactRouter>
      </TranslationProvider>
    </IonApp>
  );
};

export default connect(({ legal, license }) => ({ legal, license }), {
  setLegalPages,
})(App);
