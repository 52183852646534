import React from 'react';
import './textarea.scss';
import { NOOP } from '../../../libs/utils';

interface TextAreaProps {
  name?: string;
  value: string;
  type?: string;
  labelText?: string;
  onChange: Function;
  noBar?: boolean;
  placeholder?: string;
  required?: boolean;
}

const TextArea: React.FunctionComponent<TextAreaProps> = ({
  name = '',
  value = '',
  type = 'text',
  labelText = '',
  required = false,
  onChange = NOOP,
  noBar = false,
  placeholder = '',
  ...restProps
}) => {
  return (
    <div className="group-border">
      <textarea
        value={value}
        name={name}
        onChange={() => onChange()}
        required={required}
        placeholder={' '}
      />
      <span className="highlight"></span>
      {!noBar && <span className="bar"></span>}
      <label>
        {labelText}
        {required && '*'}
      </label>
    </div>
  );
};

export default TextArea;
