import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { Button } from 'common/atoms';
import './tutorial.scss';
import {
  LearnIcon,
  PracticeIcon,
  ProfileIcon,
  AppucationsIcon,
  SuccessIcon,
  OfflineIcon,
} from 'common/atoms/icon/icons';
import { UserState } from 'redux/reducers/types';
import { setOnboardingSeen } from 'redux/actions';
import { getPlatforms } from '@ionic/react';

const Tutorial: React.FunctionComponent<{
  user: UserState;
  setOnboardingSeen: () => void;
}> = ({ user, setOnboardingSeen }) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [configureOfflineMode, setConfigureOfflineMode] = useState(false);

  const platforms = getPlatforms();
  const isWeb =
    platforms.includes('mobileweb') || platforms.includes('desktop');

  useEffect(() => {
    if (configureOfflineMode) setStep(5);
  }, [configureOfflineMode]);

  const handleFinishTutorial = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const apiUrl = process.env.REACT_APP_SERVER + '/api/onboardingSeen';
      try {
        await axios.put(apiUrl, null, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
      } catch (e) {
        console.log(e);
      }
      setOnboardingSeen(); // we fire this also if something fails!
      if (configureOfflineMode) push('offline-settings');
    }
  };

  const getButtonText = () => {
    let buttonText = t('further');
    if (step === 0) buttonText = t('start');
    if (step === 4) buttonText = t('configure');
    if (step === 5) buttonText = t('done');
    return buttonText;
  };

  const getSecondaryButtonText = () => {
    let secondaryButtonText: string | null = t('end');
    if (step === 0) secondaryButtonText = t('skip');
    if (step === 4) secondaryButtonText = t('notNow');
    if (step === 5) secondaryButtonText = null;
    return secondaryButtonText;
  };

  const getAccentColor = () => {
    let accentColor = '#20415D';
    if (step === 1) accentColor = '#369BF2';
    if (step === 2) accentColor = '#F5AD24';
    if (step === 3) accentColor = '#5AC221';
    return accentColor;
  };

  const getIcon = () => {
    let icon = <AppucationsIcon />;
    if (step === 1) icon = <LearnIcon />;
    if (step === 2) icon = <PracticeIcon />;
    if (step === 3) icon = <ProfileIcon />;
    if (step === 4) icon = <OfflineIcon />;
    if (step === 5) icon = <SuccessIcon />;
    return icon;
  };

  const goToNextStep = () => {
    if (isWeb && step === 3) setStep(5);
    else setStep(step + 1);
  };

  const backgroundColor = getAccentColor();
  const secondaryButtonText = getSecondaryButtonText();
  const buttonText = getButtonText();
  const icon = getIcon();
  const showNavigation = step === 1 || step === 2 || step === 3;

  return (
    <div className="tutorial" style={{ backgroundColor }}>
      <div className="tutorial-inner">
        <div className={`tutorial-icon icon-${step}`}>{icon}</div>
        <h1>{t(`tutorialHeader${step}`)}</h1>
        <div
          className="tutorial-content"
          dangerouslySetInnerHTML={{
            __html:
              step === 5 && configureOfflineMode
                ? t(`tutorialTextConfigurationOutro`)
                : step === 3 && isWeb
                ? t('tutorialText3Web')
                : t(`tutorialText${step}`),
          }}
        />
        <Button
          style={{ color: backgroundColor }}
          text={buttonText}
          isLoading={isSubmitting}
          loadingColor="20415D"
          onClick={
            step === 4
              ? () => setConfigureOfflineMode(true)
              : step === 5
              ? handleFinishTutorial
              : goToNextStep
          }
        />
        <div className="tutorial-button-wrapper">
          {!!secondaryButtonText && (
            <button
              className="tutorial-button button--secondary"
              onClick={step === 4 ? goToNextStep : handleFinishTutorial}>
              {secondaryButtonText}
            </button>
          )}
        </div>
      </div>
      <div className="tutorial-navigation">
        {showNavigation && (
          <div className="tutorial-navigation-inner">
            <div className="tutorial-navigation-left"></div>
            <div className="tutorial-navigation-center">
              <div
                className={`tutorial-navigation-item ${
                  step === 1 ? 'item-active' : ''
                }`}
                style={{
                  fill: step === 1 ? backgroundColor : '#748EB4',
                  borderBottomColor:
                    step === 1 ? backgroundColor : 'transparent',
                  color: step === 1 ? backgroundColor : '#20415d',
                }}>
                <LearnIcon />
                <span>{t('learning')}</span>
              </div>
              <div
                className={`tutorial-navigation-item ${
                  step === 2 ? 'item-active' : ''
                }`}
                style={{
                  fill: step === 2 ? backgroundColor : '#748EB4',
                  borderBottomColor:
                    step === 2 ? backgroundColor : 'transparent',
                  color: step === 2 ? backgroundColor : '#20415d',
                }}>
                <PracticeIcon />
                <span>{t('examine')}</span>
              </div>
              <div
                className={`tutorial-navigation-item ${
                  step === 3 ? 'item-active' : ''
                }`}
                style={{
                  fill: step === 3 ? backgroundColor : '#748EB4',
                  borderBottomColor:
                    step === 3 ? backgroundColor : 'transparent',
                  color: step === 3 ? backgroundColor : '#20415d',
                }}>
                <ProfileIcon />
              </div>
            </div>
            <div className="tutorial-navigation-right">
              <div
                className={`tutorial-navigation-item ${
                  step === 3 ? 'item-active' : ''
                }`}
                style={{
                  fill: step === 3 ? backgroundColor : '#748EB4',
                  borderBottomColor:
                    step === 3 ? backgroundColor : 'transparent',
                  color: step === 3 ? backgroundColor : '#20415d',
                }}>
                <ProfileIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setOnboardingSeen: () => dispatch(setOnboardingSeen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tutorial);
