import React from 'react';

function PracticeImage() {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 502 640.5"
      xmlSpace="preserve">
      <style>{'.prefix__st0{fill:#fff}.prefix__st1{fill:#ffac1b}'}</style>
      <title>{'img_practice'}</title>
      <path
        className="prefix__st0"
        d="M363.3 255.6c-27.3 0-57.6-15.4-90.4-45.8-28.1-26.2-47.8-54.5-48-54.7l4.3-4c.4.3 37.6 29.7 89.9 49.8 30.6 11.7 60.3 18 88.3 18.6 4 .1 7.9.1 11.8-.1 19.2-24.1 34.8-62.5 46.4-114.9C489.5-2.7 524.2-61.7 568.8-70.9c21.8-4.5 45.9 3.4 69.8 22.8 17.8 14.5 29.2 30 29.6 30.7l.8 1.1-.3 1.3c-.1.4-8.4 35.8-32.4 79.9-22.2 40.6-62.7 97.2-129.8 135.8-25.7 14.8-54 23-84.3 24.5-13.4 16.1-28.6 25.8-45.5 29.1-4.5.9-8.9 1.3-13.4 1.3zm-121.5-88.3c24.2 29.9 81.1 91.4 133.7 81.2 14.1-2.7 27-10.4 38.6-23h-6.8c-28.7-.7-59.1-7.1-90.4-19.1-32.4-12.5-59.1-28.5-75.1-39.1zM581-66.1c-3.6 0-7.3.4-11 1.1-18.8 3.9-36.1 17.9-51.4 41.7-18.5 28.8-34.4 72.2-47 129-11.2 50.3-26.1 88.1-44.5 113.1 27.4-2.1 53-9.9 76.4-23.3C569.4 157.6 609.2 102 631.1 62c21-38.5 29.8-70.2 31.5-77-5.8-7.4-41.2-51.1-81.6-51.1z"
      />
      <path
        className="prefix__st1"
        d="M259.5 91.4l-87.1 50.3-20.7-35.9 87.1-50.3 20.7 35.9zm-35.2-63.7l-2.1 5.6c-11.2-2.2-22.8-1.8-34.4 1.7l-3.1-5.3-28.9 16.7 3.1 5.3c-8.4 8-14.8 18-18.5 28.8l-5.8-1.1-5.7 32.8 8.3 1.5c1.2 9 4.2 17.9 9 26.3 19.9 34.5 63.9 46.3 98.4 26.4 34.4-19.9 46.2-63.9 26.3-98.4-4.8-8.3-11.1-15.4-18.2-20.9l2.9-7.9-31.3-11.5z"
      />
      <path
        className="prefix__st0"
        d="M208.8 179c-26 0-51.2-13.5-65.1-37.5-4.5-7.9-7.6-16.3-9.1-25.2l-6.2-1.1c-1.6-.3-2.7-1.8-2.4-3.5l5.7-32.8c.1-.8.6-1.5 1.2-1.9.7-.5 1.5-.6 2.3-.5l3.3.6c3.7-9.7 9.4-18.6 16.7-25.9l-1.9-3.3c-.4-.7-.5-1.5-.3-2.3.2-.8.7-1.4 1.4-1.8l28.9-16.7c.7-.4 1.5-.5 2.3-.3.8.2 1.4.7 1.8 1.4l1.9 3.3c10.1-2.7 20.5-3.2 31-1.6l1.2-3.2c.6-1.5 2.3-2.3 3.8-1.8l31.3 11.4c1.6.6 2.4 2.3 1.8 3.8l-2.1 5.9c6.9 5.7 12.7 12.6 17.3 20.4 20.7 35.8 8.4 81.8-27.4 102.5-11.8 6.9-24.7 10.1-37.4 10.1zm-76.4-69.2l5.4.9c1.3.2 2.3 1.3 2.5 2.5 1.2 8.9 4.1 17.3 8.6 25.2 19 33 61.3 44.3 94.3 25.3 32.9-19 44.2-61.3 25.2-94.3-4.5-7.8-10.4-14.6-17.5-20.1-1-.8-1.4-2.2-1-3.4l1.9-5.1-25.8-9.3-1 2.8c-.5 1.4-1.9 2.2-3.4 1.9-11.2-2.2-22.2-1.6-32.9 1.6-1.3.4-2.8-.2-3.5-1.4l-1.6-2.7L160 47.4l1.6 2.7c.7 1.2.5 2.7-.5 3.7-8 7.6-14.2 17.2-17.7 27.6-.5 1.4-1.9 2.2-3.4 2l-2.8-.5-4.8 26.9zm40 34.9c-.3 0-.5 0-.8-.1-.8-.2-1.4-.7-1.8-1.4l-20.7-35.9c-.8-1.4-.3-3.3 1.1-4.1l87.1-50.3c.7-.4 1.5-.5 2.3-.3.8.2 1.4.7 1.8 1.4l20.7 35.9c.8 1.4.3 3.3-1.1 4.1l-87.1 50.3c-.5.3-1 .4-1.5.4zm-16.6-37.8l17.7 30.7 81.9-47.3-17.7-30.7-81.9 47.3z"
      />
      <path
        className="prefix__st1"
        d="M36 244.2h165.7c9.4 0 17 7.6 17 17v337.4c0 9.4-7.6 17-17 17H36c-9.4 0-17-7.6-17-17V261.2c0-9.3 7.6-17 17-17z"
      />
      <path
        className="prefix__st0"
        d="M201.7 618.7H36c-11 0-20-9-20-20V261.2c0-11 9-20 20-20h165.7c11 0 20 9 20 20v337.4c0 11.1-9 20.1-20 20.1zM36 247.2c-7.7 0-14 6.3-14 14v337.4c0 7.7 6.3 14 14 14h165.7c7.7 0 14-6.3 14-14V261.2c0-7.7-6.3-14-14-14H36z"
      />
      <circle className="prefix__st1" cx={118.5} cy={560.9} r={17.4} />
      <path
        className="prefix__st0"
        d="M118.5 581.3c-11.3 0-20.4-9.2-20.4-20.4s9.2-20.4 20.4-20.4 20.4 9.2 20.4 20.4-9.1 20.4-20.4 20.4zm0-34.8c-7.9 0-14.4 6.5-14.4 14.4s6.5 14.4 14.4 14.4 14.4-6.5 14.4-14.4-6.4-14.4-14.4-14.4zM111.6 415.7c-.8 0-1.5-.3-2.1-.9l-17.6-17.6c-1.2-1.2-1.2-3.1 0-4.2 1.2-1.2 3.1-1.2 4.2 0l15.5 15.5 30.3-30.3c1.2-1.2 3.1-1.2 4.2 0 1.2 1.2 1.2 3.1 0 4.2l-32.4 32.4c-.6.6-1.4.9-2.1.9z"
      />
    </svg>
  );
}

export default React.memo(PracticeImage);
