import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Page from '../../../components/pages';
import { Banner, LearningAreaCard, ListItem } from '../../../common/molecules';
import { List } from '../../../common/organisms';
import LearnQueries from '../../../libs/LearnQueries';
import { removeUnUsedButtonOnMobile } from '../../../libs/utils';
import './subAreaStyles.scss';
import { ProductState, TrackingState, UserState } from 'redux/reducers/types';
import offlineMode from 'redux/reducers/offlineModus';
import { OfflineDataType } from 'pages/data-loader/types';

interface SubAreaProps {
  product: ProductState;
  offlineData: OfflineDataType;
  user: UserState;
  trackings: TrackingState;
}

const SubArea: React.FC<SubAreaProps> = ({ offlineData, user, trackings }) => {
  const hasProduct = !!offlineData && !!offlineData?.learning_areas.length;
  const { t } = useTranslation();
  const { learningAreaId, subAreaId } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const instanceLearnQueries = new LearnQueries(offlineData);
  const learningAreaData = hasProduct
    ? instanceLearnQueries.getLearningData(learningAreaId)
    : {};
  const subAreasData = hasProduct
    ? instanceLearnQueries.getSubAreasData(learningAreaId, subAreaId)
    : [];

  const subAreaData = hasProduct
    ? instanceLearnQueries.getSubAreaData(subAreaId)
    : null;

  const isMainArea = pathname === `/learning/${learningAreaId}`;

  setTimeout(() => {
    removeUnUsedButtonOnMobile();
  }, 500);

  const getMatchingExerciseForArea = () => {
    const exerciseLearningAreaSibling = offlineData.learning_areas.find(
      (learningArea) =>
        learningArea.headline === subAreaData.headline &&
        !!learningArea.questions.length,
    );

    return exerciseLearningAreaSibling ? exerciseLearningAreaSibling.id : 0;
  };

  const exerciseContentId = hasProduct ? getMatchingExerciseForArea() : 0;

  function goTo(isContent, currentSubAreaId) {
    if (isContent) {
      const subAreaIdNew = subAreaId ? subAreaId : 0;
      history.push(
        `/learning-content/${learningAreaId}/${subAreaIdNew}/${currentSubAreaId}`,
      );
    } else {
      history.push(`/learning/${learningAreaId}/${currentSubAreaId}`);
    }
  }

  function getChildCountLabel(isLearningContentsSet, isSubAreasSet, subArea) {
    let learningContentCount = 0;
    let subAreasCount = 0;
    subArea.child_categories.forEach((item) => {
      const childitem = instanceLearnQueries.getSubAreaData(item.id);
      if (!!childitem.child_categories.length) {
        subAreasCount++;
      } else {
        learningContentCount++;
      }
    });
    let readingTime = 0;
    const labelParts = [
      subAreasCount > 0 &&
        ' ' +
          `${subAreasCount} ` +
          (subAreasCount > 1 ? t('learningContents') : t('learningContent')),
      learningContentCount > 0 &&
        learningContentCount +
          ' ' +
          (learningContentCount === 1
            ? t('learningSubArea')
            : t('learningSubAreas')),
      subArea &&
        subArea.learning_contents &&
        subArea.learning_contents[0] &&
        subArea.learning_contents[0].reading_time &&
        subArea.learning_contents[0].reading_time !== '0' &&
        subArea.learning_contents.map((content) => {
          return ((readingTime + Number(content.reading_time)) / 60).toFixed();
        }) + ' Minuten',
    ];

    const filteredArray = labelParts.filter(
      (item) => !!item && item !== '1 Bereich',
    );

    return filteredArray.join(', ');
  }

  function getBodyHeadline() {
    if (subAreaData) {
      return subAreaData.headline;
    }

    return learningAreaData.headline;
  }

  function countLearningContents(subareas) {
    let count = 0;
    if (!subareas) return 0;
    subareas.forEach((subarea, i) => {
      if (!subarea.child_categories.length) {
        count += 1;
      }
    });
    return count;
  }

  function goBack() {
    history.goBack();
  }

  const learningContentCount = countLearningContents(subAreasData);

  const learningContentDescription =
    `${learningContentCount} ` +
    t(learningContentCount === 1 ? 'learningSubArea' : 'learningSubAreas');

  const subAreas = subAreasData.filter(
    (item) => item.child_categories.length > 0,
  );

  const subAreasDataDescription =
    subAreas.length +
    ' ' +
    t(subAreas.length === 1 ? 'learningContent' : 'learningContents');

  const bodyDescription = `${
    subAreas.length > 0 ? subAreasDataDescription : ''
  }${!!subAreas.length && !!learningContentCount ? ' - ' : ''}${
    learningContentCount > 0 ? learningContentDescription : ''
  }`;
  const secondaryBodyDescription = isMainArea
    ? `${learningAreaData.points} ` +
      t(learningAreaData.points === 1 ? 'point' : 'points')
    : '';

  function getTimeSpentOnContent(subArea) {
    let tracks = trackings.filter((track) => {
      return track.learning_content_id === subArea.id;
    });
    return tracks.reduce((prev, current) => prev + current.time_spent, 0);
  }

  function getProgressValueContent(subArea) {
    return (
      (subArea.learning_contents[0] &&
        subArea.learning_contents[0].reading_time) ||
      0
    );
  }

  const goToPage = (url) => {
    history.push(url);
  };

  const checkIfSubareaHasIncompletedSubareas = (isChildSubAreasComplete) => {
    return isChildSubAreasComplete.filter((item) => {
      if (Array.isArray(item)) {
        const isIncompleted = checkIfSubareaHasIncompletedSubareas(item);
        return !!isIncompleted.length;
      } else return item === 0;
    });
  };
  const setProgressValue = (subArea) => {
    if (subArea.child_categories.length === 0) {
      return setCompleteTime(subArea);
    } else {
      const isChildSubAreasComplete = checkSubAreaIsComplete(subArea);
      const subAreasLength = isChildSubAreasComplete.length;
      const leftSubAreas = checkIfSubareaHasIncompletedSubareas(
        isChildSubAreasComplete,
      );
      const leftSubAreasLength = leftSubAreas.length;
      const progressValue = 100 - leftSubAreasLength * (100 / subAreasLength);
      return progressValue;
    }
  };

  const checkSubAreaIsComplete = (subArea) => {
    return subArea.child_categories.map((area) => {
      const areaData = instanceLearnQueries.getSubAreaData(area.id);
      if (areaData.child_categories.length > 0) {
        return checkSubAreaIsComplete(areaData);
      } else {
        return setCompleteTime(areaData);
      }
    });
  };

  const setCompleteTime = (subArea) => {
    const timeSpentOnContent = getTimeSpentOnContent(subArea);
    const timeProgressValueContent = getProgressValueContent(subArea);

    const time = timeSpentOnContent
      ? (timeSpentOnContent / timeProgressValueContent) * 100
      : 0;

    return time;
  };

  let headline = isMainArea ? t('topic') : learningAreaData.headline;
  if (subAreaData !== null && subAreaData.sub_area_id !== null) {
    const parentSubAreaData = instanceLearnQueries.getSubAreaData(
      subAreaData.sub_area_id,
    );
    headline = parentSubAreaData.headline;
  }

  return (
    <Page title="subarea" hideFooter>
      <div className="banner-wrapper banner-subpage">
        <Banner
          headline={headline}
          bodyHeadline={getBodyHeadline()}
          bodyDescription={bodyDescription}
          secondaryBodyDescription={secondaryBodyDescription}
          descriptionWithMarker
          onClick={goBack}
          hasPracticeButton={
            !!exerciseContentId && !!offlineData.exercises.length
          }
          onClickPractice={() =>
            goToPage(
              `/exercise/${exerciseContentId}/start?callAgain=${
                Math.random() * 1000
              }`,
            )
          }
          buttonPracticeText={t('practiceTopic')}>
          <List>
            {subAreasData.map((subArea) => {
              const isLearningContentsSet =
                subArea &&
                subArea.learning_contents &&
                subArea.learning_contents.length;
              const isContent =
                subArea.child_categories &&
                !subArea.child_categories.length &&
                isLearningContentsSet;
              const isSubAreasSet =
                subArea &&
                subArea.child_categories &&
                subArea.child_categories.length;
              if (
                !subArea.child_categories.length &&
                !subArea.learning_contents.length
              )
                return null;
              return (
                <ListItem
                  key={subArea.id}
                  progressValue={setProgressValue(subArea)}
                  title={subArea.headline}
                  subTitle={getChildCountLabel(
                    isLearningContentsSet,
                    isSubAreasSet,
                    subArea,
                  )}
                  buttonText={
                    isContent ? t('openLearningContent') : t('openSubArea')
                  }
                  onClick={() => goTo(isContent, subArea.id)}
                />
              );
            })}
          </List>
        </Banner>

        {isMainArea && (
          <div className="info-link-wrapper">
            <div
              className="info-badge-link"
              onClick={() => goToPage('/info-pages/instructions')}>
              <span className="text-message">
                {t('infoLinkMessage', { points: learningAreaData.points })}
              </span>
              <span className="label-wrapper">
                <span className="label">
                  <span>Hinweise</span>
                  <div className="right_arrow" />
                </span>
              </span>
            </div>
          </div>
        )}

        {/* <div style={{ padding: 30 }} /> */}
      </div>
    </Page>
  );
};

export default connect(
  ({ offlineMode, user, trackings }) => ({
    offlineData: offlineMode.data,
    user,
    trackings,
  }),
  null,
)(SubArea);
