import React from 'react';
import Page from '../../components/pages';
import { Banner } from '../../common/molecules';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { defaultImprint } from './content';

function Imprint({ legal, user }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { token } = user;
  return (
    <Page title="imprint">
      <div
        className={`banner-wrapper banner-legal ${
          !token ? 'banner-legal--onboarding' : ''
        }`}>
        <Banner onClick={() => {
          history.goBack();
        }} headline={t('imprint')}>
          <section
            dangerouslySetInnerHTML={{
              __html: legal.imprint || defaultImprint,
            }}
          />
        </Banner>
      </div>
    </Page>
  );
}
export default connect(({ legal, user }) => ({ legal, user }), null)(Imprint);
