import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import './progress.scss';
import Progress0Img from './progressicons/progress0@2x.png';
import Progress12Img from './progressicons/progress12@2x.png';
import Progress25Img from './progressicons/progress25@2x.png';
import Progress33Img from './progressicons/progress33@2x.png';
import Progress50Img from './progressicons/progress50@2x.png';
import Progress66Img from './progressicons/progress66@2x.png';
import Progress75Img from './progressicons/progress75@2x.png';
import Progress88Img from './progressicons/progress88@2x.png';
import Progress100Img from './progressicons/progress100@2x.png';

interface ProgressProps {
  type?: string;
  value: number;
}

const Progress: React.FunctionComponent<ProgressProps> = ({ value, type }) => {
  if (type === 'static') {
    let src = Progress0Img;
    if (value > 0) {
      src = Progress12Img;
    }
    if (value > 12) {
      src = Progress25Img;
    }
    if (value > 25) {
      src = Progress33Img;
    }
    if (value > 33) {
      src = Progress50Img;
    }
    if (value > 50) {
      src = Progress66Img;
    }
    if (value > 66) {
      src = Progress75Img;
    }
    if (value > 75) {
      src = Progress88Img;
    }
    if (value === 100 || value === Infinity) {
      src = Progress100Img;
    }

    return <img src={src} alt="" />;
  } else {
    return (
      <CircularProgressbar counterClockwise value={value} strokeWidth={5} />
    );
  }
};

export default Progress;
