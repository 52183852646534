import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER}/api/tracking/`,
});

export default class Trackings {
  static getUserTrackings(token: string, productId: number) {
    const product_id = productId.toString() || process.env.REACT_APP_PRODUCT_ID || '';
    return api
      .get(product_id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res.data.success);
  }
}
