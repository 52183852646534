import React from 'react';
import { Formik } from 'formik';
import { Error } from '../../molecules/modal';
import { Span } from '../../atoms';

export default function FormWrapper({
  children,
  handleSubmit,
  initialValues,
  editable,
  validation,
  appElement,
}) {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  // console.log(handleSubmit);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation ? validation.schema : null}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={true}>
      {({
        values,
        handleSubmit,
        handleChange,
        errors,
        isValid,
        isSubmitting,
        setFieldValue,
        setSubmitting,
      }) => (
        <form>
          {isSubmitting && !isValid && setModalIsOpen(true)}
          <Error
            appElement={appElement}
            title={validation ? validation.headline : null}
            description={validation ? validation.description : null}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}>
            {Object.keys(errors).map((key, i) => (
              <Span key={`error-${i}`}>- {errors[key]}</Span>
            ))}
          </Error>

          {React.cloneElement(children, {
            values,
            save: () => handleSubmit(),
            editable: {
              ...editable,
              setFieldValue,
              onChange: (values) => {
                handleChange(values);
                setSubmitting(false);
              },
            },
            ...values,
          })}
        </form>
      )}
    </Formik>
  );
}
