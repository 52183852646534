import React from 'react';
import classNames from 'classnames';

import './tabNavigation.scss';

interface TabInterface {
  text: string;
  index: number[];
}

interface TabNavigationProps {
  tabs: TabInterface[];
  selectedIndex: number;
  onSelectTab: Function;
}

const TabNavigation: React.FunctionComponent<TabNavigationProps> = ({
  tabs,
  selectedIndex,
  onSelectTab,
}) => {
  function getTabClassNames(tab: TabInterface) {
    return classNames({
      'tab-navigation__item': true,
      'tab-navigation__item--is-selected':
        tab.index.indexOf(selectedIndex) > -1,
    });
  }

  return (
    <ul className="tab-navigation">
      {tabs.map((tab, i) => (
        <li
          key={`tab-navigation-item-${i}`}
          className={getTabClassNames(tab)}
          onClick={() => onSelectTab(i)}>
          {tab.text}
        </li>
      ))}
    </ul>
  );
};

export default TabNavigation;
