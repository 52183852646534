declare class answer {
  id: number;
  is_checked: boolean;
}

declare class question {
  id: number;
  answers: Array<answer>;
}

export default class ResultInput {
  private startTime: number = 0;
  private endTime: number = 0;
  private questions: Array<question> = [];
  private productId: number = 0;
  private type: string = '';

  constructor(exam, productId, type) {
    this.setStartTime(exam.timer.startDate.getTime());
    this.setEndTime(new Date().getTime());
    this.setQuestions(exam.questions);
    this.setProductId(productId);
    this.setType(type);
  }

  getStartTime(): number {
    return this.startTime;
  }

  setStartTime(value: number) {
    this.startTime = Math.floor(value / 1000);
  }

  getProductId(): number {
    return this.productId;
  }

  setProductId(value: number) {
    this.productId = value;
  }

  getEndTime(): number {
    return this.endTime;
  }

  setEndTime(value: number) {
    this.endTime = Math.floor(value / 1000);
  }

  getQuestions(): Array<question> {
    return this.questions;
  }

  setQuestions(questions) {
    this.questions = questions.map((question) => ({
      id: question.id,
      answers: question.answers.map((answer) => ({
        id: answer.id,
        is_checked: answer.is_correct,
      })),
    }));
  }

  getType(): string {
    return this.type;
  }

  setType(value: string) {
    this.type = value;
  }

  toGraphqlVariables() {
    return {
      result: {
        started_at: this.getStartTime(),
        finished_at: this.getEndTime(),
        questions: this.getQuestions(),
        product_id: this.getProductId(),
        type: this.getType(),
      },
    };
  }
}
