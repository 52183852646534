import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Headline, Divider, Paragraph } from '../../atoms';
import { Actions } from '../';
import { NOOP } from '../../../libs/utils';
import './learningcontent.scss';

export default function LearningContent({
  headline,
  children,
  editable,
  id,
  save,
  remove,
  showActionButtons,
  values = {},
}) {
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);

  editable = editable || {
    isEditMode: false,
    onChange: NOOP,
    headline: {
      name: 'headline',
      labelText: t('headline'),
    },
    description: {
      name: 'description',
      labelText: t('description'),
    },
  };

  const { headline: headlineData, description } = editable;

  return (
    <div className="learning-content">
      {showActionButtons && (
        <Actions
          isEditMode={isEditMode}
          edit={setIsEditMode}
          id={id}
          save={save}
          remove={remove}
        />
      )}
      <Headline
        type="h1"
        editable={{
          ...editable,
          isEditMode: isEditMode,
          name: headlineData.name,
          labelText: headlineData.labelText,
        }}
        values={values}>
        <div dangerouslySetInnerHTML={{ __html: headline }} />
      </Headline>
      <Divider size="small" />
      <div>
        <Paragraph
          editable={{
            ...editable,
            isEditMode: isEditMode,
            name: description.name,
            labelText: description.labelText,
          }}>
          {children}
        </Paragraph>
      </div>
    </div>
  );
}
