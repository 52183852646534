import React from 'react';
import './spinner.scss';

export const Spinner: React.FunctionComponent<{
  color?: string;
  tiny?: boolean;
}> = ({ color, tiny }) => {
  return (
    <div className={`lds-ellipsis ${tiny ? 'tiny' : ''}`}>
      <div style={{ background: color || '#339bf2 ' }}></div>
      <div style={{ background: color || '#339bf2 ' }}></div>
      <div style={{ background: color || '#339bf2 ' }}></div>
      <div style={{ background: color || '#339bf2 ' }}></div>
    </div>
  );
};
