import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import appReducer from './reducers';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist-indexeddb-storage';
import { APP_MODE } from 'frontendConfig';

const initialState = {
  trackings: [],
  user: {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    avatar: null,
    token: null,
    licenses: null
  },
  product: {},
  lastLocation: {
    pathname: '/learning',
  },
  legal: {
    terms: null,
    imprint: null,
    privacy: null,
  },
  exam: {
    timer: {
      endDate: new Date(),
      startDate: new Date(),
      isRunning: false,
    },
    maxQuestions: 0,
    maxTimeInMinutes: 0,
    maxCorrectAnswersCount: 0,
    questions: [],
    result: null,
  },
  exercise: {
    timer: {
      endDate: new Date(),
      startDate: new Date(),
    },
    maxQuestions: 0,
    maxCorrectAnswersCount: 0,
    questions: [],
    name: '',
    result: null,
  },
  globalError: {
    errors: [],
  },
  appDefaults: {
    mode: APP_MODE.MOBILE,
  },
  license: {
    license: null,
  },
  offlineMode: { isOffline: false, data: null },
  queue: { list: [] },
};

// const middleware = [thunk]; // thunk for async actions

// const replacer = (key, value) =>
//   value instanceof Date ? value.toISOString() : value;

// const reviver = (key, value) =>
//   typeof value === 'string' &&
//   value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
//     ? new Date(value)
//     : value;

// export const encode = (toDehydrate) => JSON.stringify(toDehydrate, replacer);

// export const decode = (toRehydrate) => JSON.parse(toRehydrate, reviver);
// /**
//  * PERSIST STORE
//  */
// const persistConfig = {
//   key: 'root',
//   storage: storage('APPucations' + process.env.REACT_APP_PRODUCT_ID),
//   whitelist: [
//     'user',
//     'lastLocation',
//     'legal',
//     'product',
//     'exam',
//     'exercise',
//     'license',
//     'offlineMode',
//     'queue',
//   ],
//   transforms: [createTransform(encode, decode)],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);
// const store = createStore(
//   persistedReducer,
//   compose(composeWithDevTools(applyMiddleware(...middleware))),
// );
// let persistor = persistStore(store);

// export { store, persistor };

const rootReducer = (state, action) => {

  if (action.type === 'USER_LOGOUT') {
    state = initialState;
  }
  if (action.type === 'CHANGE_COURSE') {

    state = {
      ...state,
      product: initialState.product,
      exam: initialState.exam,
      exercises: initialState.exercises,
      offlineMode: initialState.offlineMode
    };
  }
  return appReducer(state, action);
};

const middleware = [thunk]; // thunk for async actions
/**
 * PERSIST STORE
 */

// const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  rootReducer,
  compose(composeWithDevTools(applyMiddleware(...middleware))),
);
// let persistor = persistStore(store);

const { dispatch } = store;

export { store, dispatch };
