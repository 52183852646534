import React from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Page from '../../components/pages';
import { Banner } from '../../common/molecules';
import { defaultTerms } from './content';

function ConditionTerms({ legal, user }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { token } = user;

  return (
    <Page title="conditionTerms">
      <div
        className={`banner-wrapper banner-legal ${
          !token ? 'banner-legal--onboarding' : ''
        }`}>
        <Banner onClick={() => {
          history.goBack()
        }} headline={t('conditionTerms')}>
          <section
            dangerouslySetInnerHTML={{ __html: legal.terms || defaultTerms }}
          />
        </Banner>
      </div>
    </Page>
  );
}

export default connect(
  ({ legal, user }) => ({ legal, user }),
  null,
)(ConditionTerms);
