import { SearchItemType } from 'pages/learning/searchBox/searchBox';
import React from 'react';
import { Link } from 'react-router-dom';
import './learningSearch.scss';

import SearchIcon from '../../../assets/icons/ico_magnifier.svg';
import CrossIcon from '../../../assets/icons/ico_cross.svg';
import IcoMagnifierActive from '../../../assets/icons/ico_magnifier_active.svg';

interface LearningSearchProps {
  items: SearchItemType[];
  inputActive: boolean;
  searchValue: string;
  noSearchResultsLabelText: string;
  noSearchResultsTypeText: string;
  searchStarted: boolean;
  handleSearch: Function;
  handleInputActive: Function;
}

const LearningSearch: React.FunctionComponent<LearningSearchProps> = ({
  items = [],
  inputActive = false,
  searchValue = '',
  noSearchResultsLabelText = '',
  noSearchResultsTypeText = '',
  searchStarted = false,
  handleSearch = () => {},
  handleInputActive = () => {},
}) => {
  const replaceHTMLEntitiesAndTags = (text: string) => {
    const textWithoutEntities = text.replace(/(&.+?;)/gi, '');
    return textWithoutEntities.replace(/<\/?[^>]+(>|$)/g, '');
  };

  return (
    <div className="search-bar">
      <form
        className={'searchBar' + (inputActive ? ' focused' : '')}
        id="search">
        {!!searchValue && (
          <p className="cross">
            <img
              alt=""
              src={CrossIcon}
              className="svg"
              width="16"
              height="16"
              onClick={() => {
                handleSearch('');
              }}
            />
          </p>
        )}
        {inputActive ? (
          <img
            alt=""
            src={IcoMagnifierActive}
            className="svg glass active"
            width="27"
            height="20"
          />
        ) : (
          <img
            alt=""
            src={SearchIcon}
            className="svg glass active"
            width="27"
            height="20"
          />
        )}
        <input
          type="text"
          onChange={(e) => handleSearch(e.target.value)}
          value={searchValue}
          onClick={() => handleInputActive(true)}
        />
      </form>
      {items.length > 0 && (
        <section className="search-bar-results">
          <ul>
            {items.map((item) => {
              const replacedContent = replaceHTMLEntitiesAndTags(item?.wysiwyg);
              return (
                <li key={item.linkUrl}>
                  <Link to={item.linkUrl}>
                    <div className="search-bar-results-label">
                      {replaceHTMLEntitiesAndTags(item?.headline)}
                    </div>
                    <div className="search-bar-results-type">
                      {replacedContent.length > 80
                        ? `${replacedContent.substr(0, 80)}...`
                        : replacedContent}
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </section>
      )}
      {items.length < 1 && searchStarted && (
        <section className="search-bar-results">
          <ul>
            <li>
              <a href="#">
                <div className="search-bar-results-label">
                  {noSearchResultsLabelText}
                </div>
                <div className="search-bar-results-type">
                  {noSearchResultsTypeText}
                </div>
              </a>
            </li>
          </ul>
        </section>
      )}
    </div>
  );
};

export default LearningSearch;
