export const defaultImprint = `<h3>Verantwortlich f&uuml;r den Inhalt dieser Anwendung:</h3>
          <p>
            APPucations GmbH
            <br />
            Gesch&auml;ftsf&uuml;hrer: Holger K&ouml;nnecke
            <br />
            Ruppiner Chaussee 19a
            <br />
            16761 Hennigsdorf
            <br />
            kontakt(at)appucations.de
            <br />
            www.appucations.de
          </p>
          <h3>Haftungsausschluss</h3>
          <p>
            Diese App gibt Ihnen wichtige und hilfreiche Informationen zur
            Vorbereitung auf die Wissensfeststellung zum Ausbilder
            gem&auml;&szlig; Ausbildereignungsverordnung. Sie versteht sich als
            Unterst&uuml;tzungswerkzeug und bietet lediglich einen
            Orientierungsrahmen. Die App erhebt keinen Anspruch auf
            Vollst&auml;ndigkeit. Trotz sorgf&auml;ltiger Recherchen bei der
            Erstellung der Inhalte in dieser App wird keinerlei Haftung f&uuml;r
            den Inhalt sowie das Nichtbestehen einer Wissensfeststellung
            gem&auml;&szlig; Ausbildereignungsverordnung f&uuml;r Ausbilder
            &uuml;bernommen. Die in dieser App &bdquo;AEVO Coach&ldquo;
            dargestellten Fragen und Erl&auml;uterungen erfolgen vorbehaltlich
            etwaiger &Auml;nderungen durch anstehende verordnungsrechtliche oder
            gesetzliche &Auml;nderungen.
          </p>
          <p>
            Haftungshinweis: Trotz sorgf&auml;ltiger inhaltlicher Kontrolle
            &uuml;bernehmen wir keine Haftung f&uuml;r die Inhalte externer
            Links. F&uuml;r den Inhalt der verlinkten Seiten sind
            ausschlie&szlig;lich deren Betreiber verantwortlich.
          </p>
          <p>
            <strong>
              Wird in der App von Personen geredet, so ist in jedem Fall
              nat&uuml;rlich auch die weibliche Form gemeint!
            </strong>
          </p>`;

export const defaultTerms = `<p>
            <strong>
              Ruppiner Chaussee 19a
              <br />
              16761 Hennigsdorf
              <br />
              Telefon: +49 (0) 3302 866 00 57
            </strong>
          </p>
          <p>
            E-Mail: info@appucations.de
            <br />
            Internet: www.appucations.de
            <br />
            vertreten durch den Gesch&auml;ftsf&uuml;hrer: Holger K&ouml;nnecke
            <br />
            Registergericht: Neuruppin Registernummer: HRB 11337 NP
            <br />
            Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
            Umsatzsteuergesetz: DE292301505
            <br />
            f&uuml;r Nutzer des Angebots von APPucations.de
            <br />
            Stand: Januar 2017
          </p>
          <h3>&sect; 1 Pr&auml;ambel</h3>
          <p>
            1.1 Die APPucations GmbH, vertreten durch ihren
            Gesch&auml;ftsf&uuml;hrer Holger K&ouml;nnecke stellt &uuml;ber ihr
            Internetportal www.appucations.de (im Folgenden: Datenbank)
            Online-Trainings mit Pr&uuml;fungsfragen, -simulationen sowie andere
            Lerninhalte in digitaler Form (im Folgenden: Inhalte) zur
            Verf&uuml;gung. Diese k&ouml;nnen teils unentgeltlich, teils
            entgeltlich von jedermann angefordert und heruntergeladen werden.
            Des Weiteren werden Gutscheine (im Folgenden: Waren) f&uuml;r diese
            Inhalte &uuml;ber dritte Internetportale angeboten.
          </p>
          <p>
            1.2 Als Gesch&auml;ftsbedingungen gelten die Bestimmungen des BGB.
            Der Richter wertet im Einzelfall. Die folgenden allgemeinen
            Gesch&auml;ftsbedingungen regeln das Rechtsverh&auml;ltnis zwischen
            dem Nutzer und der APPucations GmbH und bilden die Grundlage
            f&uuml;r s&auml;mtliche Leistungen der APPucations GmbH.
            Entgegenstehende kundenseitige Bedingungen werden nicht anerkannt,
            es sei denn die APPucations GmbH hat denen ausdr&uuml;cklich
            schriftlich zugestimmt.
          </p>
          <h3>&sect; 2 Vertragsschluss</h3>
          <p>
            2.1 Die von der APPucations GmbH angebotenen Inhalte und Waren
            stellen ein rechtsverbindliches Angebot an den Nutzer dar, diese
            Inhalte und Gutscheine in dem jeweils angegebenen Dateiformat zu
            kaufen und gegebenenfalls herunterzuladen. Der Kaufvertrag kommt
            zustande, sobald der Nutzer dieses Angebot durch Abgabe seiner
            Bestellung annimmt. Die Inhalte sind ausschlie&szlig;lich online zu
            beziehen. Ein Anspruch auf Lieferung per Post, Fax etc. besteht
            nicht. Technische und gestalterische Abweichungen von Beschreibungen
            und Angaben in Prospekten, Katalogen und schriftlichen Unterlagen
            bleiben vorbehalten, ohne, dass der Nutzer hieraus Rechte gegen die
            APPucations GmbH herleiten kann. Nach Abschluss der Bestellung
            erh&auml;lt der Nutzer eine Online-Rechnung per E- Mail und kann die
            Bestelldaten in seinem Mitgliedskonto in der &Uuml;bersicht
            einsehen.
          </p>
          <p>
            2.2 Der Nutzer best&auml;tigt die Richtigkeit und
            Vollst&auml;ndigkeit seiner angegebenen Daten. Ebenso hat er
            sicherzustellen, dass auf seiner Seite Voraussetzungen f&uuml;r den
            Empfang der Dateien und Inhalte gegeben sind. APPucations GmbH
            benutzt f&uuml;r seinen Service die aktuell und allgemein
            verwendeten Technologien. Um das Angebot von APPucations GmbH in
            vollem Umfang nutzen zu k&ouml;nnen, muss der Nutzer ebenfalls diese
            Technologien (z.B. aktuelle Browsertechnologien, Adobe Flash)
            verwenden oder deren Verwendung auf seinem Computer erm&ouml;glichen
            (z.B. Aktivierung von Java Skript, Cookies, Pop-ups). Bei Benutzung
            &auml;lterer oder nicht allgemein gebr&auml;uchlicher Technologien
            kann es sein, dass der Nutzer die Leistungen von APPucations GmbH
            nur eingeschr&auml;nkt nutzen kann. Sind diese Voraussetzungen nicht
            erf&uuml;llt, ist dies ohne Einfluss auf das Zustandekommen des
            Vertrages zwischen dem Nutzer und der APPucations GmbH.
          </p>
          <p>
            2.3 Abgesehen von einzelnen kostenfreien Angeboten sowie
            Probekapiteln ist die Nutzung der Inhalte f&uuml;r den Nutzer
            kostenpflichtig. Anfallende Verbindungskosten f&uuml;r den Zugang
            zur Datenbank tr&auml;gt der Nutzer in jedem Fall selbst;
            insbesondere tr&auml;gt er die anfallenden Telekommunikations- und
            Internetkosten. Entstehende Kosten aus Sonderleistungen sowie
            Leistungen aufgrund unrichtiger oder unvollst&auml;ndiger
            Nutzerangaben oder nicht nachpr&uuml;fbarer M&auml;ngelr&uuml;gen
            oder unsachgem&auml;&szlig;en Systemgebrauchs sind in vollem Umfang
            vom Kunden zu tragen.
          </p>
          <p>
            2.4 APPucations GmbH bietet bei technischen Problemen mit Inhalten
            in der Regel Support. Hierf&uuml;r stellt die APPucations GmbH die
            E-Mail-Adresse support@appucations.de zur Verf&uuml;gung. Ein
            rechtlicher Anspruch auf Support kann daraus nicht abgeleitet
            werden. Da es sich um Online-Services handelt, kann dieser in
            Ausnahmef&auml;llen auch offline sein.
          </p>
          <h3>&sect; 3 Vertragslaufzeit, K&uuml;ndigung</h3>
          <p>
            3.1 Die Laufzeit des Vertrages f&uuml;r die Waren von APPucations
            GmbH wird im jeweils einzelnen Vertrag selbst festgelegt, der auf
            der Grundlage dieser AGB geschlossen wird. Es sei denn, die
            Vertragsgestaltung kommt &uuml;ber Online-Shops wie den Appstores
            von Google, Apple, Amazon oder Windows zustande. Sofern nicht anders
            vermerkt, werden Vertr&auml;ge &uuml;ber die Nutzung der Datenbank
            auf unbestimmte Zeit geschlossen. Eine K&uuml;ndigung des Vertrages
            seitens des Nutzers ist jederzeit ohne Angabe von Gr&uuml;nden zwei
            Wochen zum Monatsende m&ouml;glich. Eine R&uuml;ckerstattung des
            Kaufpreises erfolgt jedoch nur im Rahmen des Paragraphen &sect; 5.
          </p>
          <p>
            3.2 Die APPucations GmbH beh&auml;lt sich das Recht vor, bei
            erwiesenem Missbrauch oder Weitergabe der Zugangsdaten den Vertrag
            fristlos zu k&uuml;ndigen, den Nutzer zu sperren und entsprechende
            Schadensersatzanspr&uuml;che geltend zu machen. Bei Lizenzmissbrauch
            hat die APPucations GmbH das Recht Vertragsstrafen in H&ouml;he des
            vierfachen Verkaufspreises geltend zu machen, wovon die
            Schadensersatzanspr&uuml;che unber&uuml;hrt bleiben. Zudem
            k&ouml;nnen bei der Vermutung von Lizenzmissbrauch
            unangek&uuml;ndigte Vorortkontrollen durch zust&auml;ndige Personen
            (beispielsweise Auditoren) durchgef&uuml;hrt werden und sich von der
            regelkonformen Lizenznutzung &uuml;berzeugen. Ferner ist die
            APPucations GmbH berechtigt, Konten von Nutzern, die die Datenbank
            l&auml;nger als 6 Monate nicht genutzt und keine Inhalte erworben
            haben, zu l&ouml;schen.
          </p>
          <p>
            3.3 Die APPucations GmbH weist ausdr&uuml;cklich darauf hin, dass je
            nach Vertragsabschluss die angegebenen Preise eine einmalige Zahlung
            oder keine einmalige Zahlung darstellen. Ist es keine einmalige
            Zahlung, handelt es sich um ein Abonnement.
          </p>
          <p>
            3.4 Bei einem Kauf &uuml;ber einen Appstore erhalten Privatkunden
            automatisch die Berechtigung ihre zeitlich fixierten
            Lizenzzug&auml;nge auf maximal 3 Endger&auml;ten zu nutzen. Eine
            zeitgleiche Nutzung wird aus Sicherheitsgr&uuml;nden kategorisch
            ausgeschlossen und von unserem System unterbunden.
            Gew&auml;hrleistet wird jedoch die ad hoc Synchronisierung der
            Statistiken, damit Endger&auml;t&uuml;bergreifend auf aktuellstem
            Niveau der Lernstand vorangetrieben werden kann.
          </p>
          <h3>&sect; 4 Zahlungsbedingungen</h3>
          <p>
            4.1 Die Preise ergeben sich aus der jeweiligen
            Produktbeschreibungsseite und / oder dem jeweiligen Bestellformular.
            Alle angegebenen Preise sind inklusive der jeweils zum Zeitpunkt des
            Vertragsschlusses gesetzlich g&uuml;ltigen Mehrwertsteuer. Soweit
            laufende Leistungen geschuldet sind, ist der im Zeitpunkt der
            F&auml;lligkeit der jeweiligen Forderung geltende Mehrwertsteuersatz
            entscheidend. Erf&uuml;llung erfolgt sp&auml;testens zwei Werktage
            nach Zahlungseingang.
          </p>
          <p>
            4.2 Der Nutzer hat bei der Bestellung daf&uuml;r Sorge zu tragen,
            dass die Angaben s&auml;mtlicher Daten mit den gew&uuml;nschten
            Daten auf der Rechnung &uuml;bereinstimmen. Nachtr&auml;gliche
            &Auml;nderungen auf Rechnungen k&ouml;nnen nicht erfolgen.
          </p>
          <p>
            4.3 Die APPucations GmbH kann sich Diensten von Drittunternehmen
            bedienen, um Zahlungsvorg&auml;nge abzuwickeln. Sofern dies
            geschieht, wird das jeweils beauftragte Unternehmen im
            Bestellprozess bezeichnet. Zus&auml;tzlich zu den allgemeinen
            Gesch&auml;ftsbedingungen von APPucations GmbH gelten dann die
            allgemeinen Gesch&auml;ftsbedingungen des beauftragten Unternehmens.
          </p>
          <p>
            4.4 Beim Kauf von Inhalten kann wahlweise per
            Vorkasse/&Uuml;berweisung oder PayPal bezahlt werden. Die Belastung
            des PayPal-Kontos erfolgt in der Regel mit Abschluss der Bestellung.
          </p>
          <p>
            4.5 Alle Zahlungen sind sofort f&auml;llig. Es tritt Verzug ein, 14
            Tage nach Zustellung oder F&auml;lligkeit der Rechnung oder wenn
            eine Abbuchung des Kaufpreises fehlschl&auml;gt oder die Abbuchung
            vom Nutzer ohne Rechtsgrund storniert wird. Ferner kann APPucations
            GmbH unbeschadet aller sonstigen Rechte den Zugriff auf Inhalte
            unterbinden, solange der Nutzer sich im Verzug befindet. Ist der
            Nutzer im Verzug, ist die APPucations GmbH berechtigt, den Kauf zu
            stornieren und die beiderseits empfangenen Leistungen zur&uuml;ck zu
            gew&auml;hren.
          </p>
          <p>
            4.6 Die APPucations GmbH ger&auml;t erst dann in Verzug, wenn der
            Nutzer schriftlich eine Nachfrist von mindestens 7 Tagen gesetzt
            hat. In diesem Fall hat der Nutzer h&ouml;chstens einen Anspruch auf
            die R&uuml;ckerstattung des gesamten Rechnungsbetrags der vom Verzug
            betroffenen Leistung. Dar&uuml;berhinausgehende Anspr&uuml;che,
            insbesondere Schadensersatzanspr&uuml;che sind ausgeschlossen. Der
            Nutzer ist nicht berechtigt, Zahlungen zur&uuml;ckzuhalten, sofern
            ihm nicht ein gesetzliches Zur&uuml;ckbehaltungsrecht zusteht.
          </p>
          <h3>&sect; 5 Belehrung zum Widerrufsrecht</h3>
          <p>- Widerrufsbelehrung -</p>
          <p>
            <strong>5.1 Widerrufsrecht</strong>
          </p>
          <p>
            Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von
            Gr&uuml;nden diesen Vertrag zu widerrufen.
          </p>
          <p>
            Die Widerrufsfrist betr&auml;gt vierzehn Tage ab dem Tag des
            Vertragsabschlusses. Um Ihr Widerrufsrecht auszu&uuml;ben,
            m&uuml;ssen Sie uns (APPucations GmbH, Ruppiner Chaussee 19a,
            D-16761 Hennigsdorf, E-Mail: info@appucations.de) mittels einer
            eindeutigen Erkl&auml;rung (z. B. ein mit der Post versandter Brief
            oder E-Mail) &uuml;ber Ihren Entschluss, diesen Vertrag zu
            widerrufen, informieren. Sie k&ouml;nnen daf&uuml;r das
            beigef&uuml;gte Muster-Widerrufsformular verwenden, das jedoch nicht
            vorgeschrieben ist.
          </p>
          <p>
            Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
            Mitteilung &uuml;ber die Aus&uuml;bung des Widerrufsrechts vor
            Ablauf der Widerrufsfrist absenden.
          </p>
          <p>
            <strong>5.2 Folgen des Widerrufs</strong>
          </p>
          <p>
            Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen,
            die wir von Ihnen erhalten haben, einschlie&szlig;lich der
            Lieferkosten (mit Ausnahme der zus&auml;tzlichen Kosten, die sich
            daraus ergeben, dass Sie eine andere Art der Lieferung als die von
            uns angebotene, g&uuml;nstigste Standardlieferung gew&auml;hlt
            haben), unverz&uuml;glich und sp&auml;testens binnen vierzehn Tagen
            ab dem Tag zur&uuml;ckzuzahlen, an dem die Mitteilung &uuml;ber
            Ihren Widerruf dieses Vertrags bei uns eingegangen ist. F&uuml;r
            diese R&uuml;ckzahlung verwenden wir dasselbe Zahlungsmittel, das
            Sie bei der urspr&uuml;nglichen Transaktion eingesetzt haben, es sei
            denn, mit Ihnen wurde ausdr&uuml;cklich etwas anderes vereinbart; in
            keinem Fall werden Ihnen wegen dieser R&uuml;ckzahlung Entgelte
            berechnet.
          </p>
          <p>
            <strong>5.3 Besondere Hinweise</strong>
          </p>
          <p>
            Ihr Widerrufsrecht erlischt vorzeitig, wenn der Vertrag von beiden
            Seiten auf Ihren ausdr&uuml;cklichen Wunsch vollst&auml;ndig
            erf&uuml;llt ist, bevor Sie Ihr Widerrufsrecht ausge&uuml;bt haben.
          </p>
          <p>
            <strong>5.4 Ausschluss des Widerrufsrechts</strong>
          </p>
          <p>
            Das Widerrufsrecht besteht nicht, wenn Sie Unternehmer
            gem&auml;&szlig; &sect; 14 Abs. 1 BGB sind und Sie in Aus&uuml;bung
            ihrer gewerblichen oder selbst&auml;ndigen T&auml;tigkeit handeln.
          </p>
          <p>
            <strong>5.5 Muster-Widerrufsformular</strong>
          </p>
          <p>
            (Wenn Sie den Vertrag widerrufen wollen, dann k&ouml;nnen Sie dieses
            Formular ausf&uuml;llen und es z.B. per Post oder E- Mail an uns
            zur&uuml;cksenden. Sie k&ouml;nnen aber auch ganz formlos z.B. per
            Post oder E-Mail widerrufen.)
          </p>
          <p>
            An APPucations GmbH, Ruppiener Chaussee 19a, D-16761 Hennigsdorf,
            E-Mail: info@appucations.de
          </p>
          <p>
            Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
            Vertrag &uuml;ber den Kauf der folgenden Waren (*)/die Erbringung
            der folgenden Dienstleistung (*)
          </p>
          <p>
            Bestellt am ..................... /erhalten am .....................
          </p>
          <p>Name des/der Verbraucher(s)</p>
          <p>Anschrift des/der Verbraucher(s)</p>
          <p>
            Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
            Datum
          </p>
          <p>(*) Unzutreffendes streichen.</p>
          <p>- Ende der Widerrufsbelehrung -</p>
          <h3>&sect; 6 Partnerangebote, Links</h3>
          <p>
            APPucations GmbH stellt dem Nutzer m&ouml;glicherweise Links auf
            bzw. Inhalte von Drittseiten und / oder Partnerangebote zur
            Verf&uuml;gung. F&uuml;r die entsprechenden Inhalte, deren
            Rechtm&auml;&szlig;igkeit und Richtigkeit ist APPucations GmbH nicht
            verantwortlich. Insofern distanziert sich APPucations GmbH
            vorsorglich von den dort angebotenen Inhalten. Im &Uuml;brigen
            gelten die jeweiligen Gesch&auml;ftsbedingungen der Betreiber der
            verlinkten Drittseiten / Partnerangebote.
          </p>
          <h3>&sect; 7 Copyright</h3>
          <p>
            7.1 Alle Urheber-, Nutzungs- und sonstigen Schutzrechte an den
            Inhalten verbleiben bei APPucations GmbH. Der Nutzer darf die
            Inhalte nur zu eigenen Zwecken nutzen und ist nicht berechtigt, sie
            im Internet oder auf sonstige Weise Dritten zur Verf&uuml;gung zu
            stellen, sei es entgeltlich oder unentgeltlich, soweit nicht der
            Zweck der Inhalte dies gebietet. Die gewerbliche
            Vervielf&auml;ltigung und der Weiterverkauf der Inhalte weder im
            Ganzen noch in Teilen sind ausgeschlossen. Dem Nutzer ist es nicht
            gestattet, vorhandene Kennzeichnungen, Schutzrechtvermerke oder
            Eigentumshinweise des Anbieters in der Software und in gespeicherten
            Daten zu beseitigen. APPucations GmbH ist berechtigt, auf eigene
            Kosten notwendige &Auml;nderungen aufgrund der
            Schutzrechtsbehauptungen Dritter durchzuf&uuml;hren. Der Nutzer kann
            hieraus keine vertraglichen Rechte ableiten.
          </p>
          <p>
            7.2 Die Datenbank enth&auml;lt Inhalte von Drittanbietern. Diese
            sind gesondert gekennzeichnet und unterliegen den
            Copyrightbestimmungen des jeweiligen Anbieters, die unter den
            angegebenen Links abgerufen werden k&ouml;nnen. Zus&auml;tzlich zu
            den Copyrightbestimmungen der APPucations GmbH gelten dann die
            Bestimmungen des jeweiligen Unternehmens.
          </p>
          <p>
            7.3 Der Nutzer haftet gegen&uuml;ber APPucations GmbH f&uuml;r alle
            Sch&auml;den, die sich aus der Verletzung der vorgenannten
            Verpflichtungen ergeben. APPucations GmbH beh&auml;lt sich das Recht
            von stichprobenartigen Kontrollen zur Kontrolle der korrekten
            Verwendung bei unternehmerisch T&auml;tigen Kunden.
          </p>
          <h3>&sect; 8 Gew&auml;hrleistung, Haftung</h3>
          <p>
            8.1 APPucations GmbH wendet gr&ouml;&szlig;te Sorgfalt bei der
            Erstellung und Pflege der Inhalte auf. Gleichwohl kann APPucations
            GmbH keine Gew&auml;hr daf&uuml;r &uuml;bernehmen, dass die Inhalte
            f&uuml;r die konkreten Bed&uuml;rfnisse des Nutzers passend und
            richtig sind. Der Nutzer w&auml;hlt die Inhalte eigenverantwortlich
            aus und verwendet diese eigenverantwortlich. Die Haftung von
            APPucations GmbH f&uuml;r die falsche Verwendung der Inhalte ist
            ausgeschlossen.
          </p>
          <p>
            8.2 Die angebotenen Inhalte dienen lediglich als Erg&auml;nzung des
            Lernstoffes und ersetzen keinesfalls eine fachkundige Schulung. Der
            Nutzer wird deshalb aufgefordert eine entsprechende Schulung zu
            absolvieren, bevor er die Inhalte verwendet. Unterl&auml;sst der
            Nutzer dies und entsteht aufgrund dessen ein Schaden, ist die
            Haftung von APPucations GmbH hierf&uuml;r ausgeschlossen.
          </p>
          <p>
            8.3 Die Haftung von APPucations GmbH ist im &Uuml;brigen auf Vorsatz
            und grobe Fahrl&auml;ssigkeit beschr&auml;nkt. Davon ausgenommen ist
            die Haftung f&uuml;r Sch&auml;den an K&ouml;rper, Leben und
            Gesundheit, f&uuml;r die die gesetzlichen Haftungsregeln
            uneingeschr&auml;nkt gelten. Des Weiteren steht APPucations GmbH
            nicht f&uuml;r Fehler, St&ouml;rungen oder Sch&auml;den ein, die auf
            unsachgem&auml;&szlig;e Bedienung, Verwendung ungeeigneter
            Betriebsmittel oder un&uuml;bliche Betriebsbedingungen
            zur&uuml;ckzuf&uuml;hren sind.
          </p>
          <p>
            8.4 Fehler in EDV Programmen k&ouml;nnen nach dem Stand der Technik
            und unter Aufwendung gr&ouml;&szlig;ter Sorgfalt niemals vollkommen
            ausgeschlossen werden. Der Nutzer nimmt von diesem Umstand hiermit
            Kenntnis.
          </p>
          <p>
            8.5 Sollten M&auml;ngel auftreten, unterrichtet der Nutzer
            APPucations GmbH umgehend mit einer genauen M&auml;ngelbeschreibung.
            Wenn diese M&auml;ngel nicht auf fehlerhafte Bedienung oder
            St&ouml;rungen zur&uuml;ckzuf&uuml;hren sind, die APPucations GmbH
            nicht zu vertreten hat, wird APPucations GmbH die M&auml;ngel
            anschlie&szlig;end in angemessener Zeit beseitigen.
          </p>
          <p>
            8.6 Im &Uuml;brigen gelten die gesetzlichen
            Gew&auml;hrleistungsvorschriften. Die vertragliche
            M&auml;ngelhaftung ist mit Abschluss des Vertrages auf 24 Monate
            beschr&auml;nkt. F&uuml;r erfolgte
            M&auml;ngelbeseitigungsma&szlig;nahmen gew&auml;hrt APPucations GmbH
            eine zus&auml;tzliche M&auml;ngelhaftung von sechs Monaten ab dem
            Zeitpunkt der Durchf&uuml;hrung.
          </p>
          <h3>&sect; 9 SCHLUSSBESTIMMUNGEN</h3>
          <p>
            9.1 Es gilt ausschlie&szlig;lich das Recht der Bundesrepublik
            Deutschland unter Ausschluss des Internationalen Privatrechts und
            des UN-Kaufrechts. Die Angebote der APPucations GmbH gelten
            ausschlie&szlig;lich f&uuml;r Deutschland. Vertragsabschl&uuml;sse
            mit Nutzern, die Ihren Sitz in anderen L&auml;ndern haben,
            beh&auml;lt sich APPucations GmbH f&uuml;r den Einzelfall vor.
          </p>
          <p>
            9.2 APPucations GmbH beh&auml;lt sich das Recht vor,
            Kundenantr&auml;ge im Einzelfall ohne Angabe von Gr&uuml;nden
            abzulehnen.
          </p>
          <p>
            9.3 APPucations GmbH kann diese Vertragsbedingungen jederzeit
            &auml;ndern. In diesem Fall k&ouml;nnten die jetzt auf Ihre
            Bestellung anwendbaren AGB zu einem sp&auml;teren Zeitpunkt nicht
            mehr abrufbar sein. Sie haben die M&ouml;glichkeit die aktuellen AGB
            am Ende des Dokuments zu speichern.
          </p>
          <p>
            9.4 Die Nichtigkeit oder Unwirksamkeit einzelner Bestimmungen dieses
            Vertrags ber&uuml;hren die G&uuml;ltigkeit der &uuml;brigen
            Bestimmungen nicht. Sie haben nicht die die Nichtigkeit oder
            Unwirksamkeit des gesamten Vertrags zur Folge. Die nichtigen oder
            unwirksamen Bestimmungen sind so umzudeuten, dass der mit ihnen
            beabsichtigte wirtschaftliche Zweck erreicht wird. Ist eine
            Umdeutung nicht m&ouml;glich, sind die Vertragschlie&szlig;enden
            verpflichtet, eine Vereinbarung zu treffen, die dem wirtschaftlichen
            Zweck der nichtigen oder unwirksamen Bestimmung m&ouml;glichst
            nahekommt.
          </p>
          <p>- Ende der Allgemeine Gesch&auml;ftsbedingungen &ndash;</p>
          <p>Stand 10. Januar 2017</p>
          <p>
            <a href="https://www.appucations.de/agb/" target="_blank">
              Dokument als PDF herunterladen
            </a>
          </p>`;

export const defaultPrivacy = `<p>
            APPucations GmbH nimmt den Schutz Ihrer pers&ouml;nlichen Daten sehr
            ernst und wird sich strikt an die Regeln der Datenschutzgesetze
            halten. Personenbezogene Daten werden auf dieser Webseite nur im
            technisch notwendigen Umfang erhoben. In keinem Fall werden die
            erhobenen Daten verkauft oder aus anderen Gr&uuml;nden an Dritte
            weitergegeben.
          </p>
          <p>
            Die nachfolgende Erkl&auml;rung gibt Ihnen einen &Uuml;berblick
            dar&uuml;ber, wie wir diesen Schutz gew&auml;hrleisten und welche
            Art von Daten zu welchem Zweck erhoben werden.
          </p>
          <h3>Erheben und Nutzen von Daten</h3>
          <p>
            APPucations GmbH erhebt, verarbeitet und nutzt personenbezogene
            Daten nur, soweit sie f&uuml;r die Begr&uuml;ndung, inhaltliche
            Ausgestaltung und Abwicklung Ihrer Bestellung erforderlich sind
            (Bestandsdaten). Personenbezogene Daten &uuml;ber die
            Inanspruchnahme unserer Internetseiten oder Applikationen
            (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies
            erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu
            erm&ouml;glichen oder abzurechnen.
          </p>
          <h3>&Uuml;bermitteln von Daten</h3>
          <p>
            Wir &uuml;bermitteln personenbezogene Daten an Dritte nur dann, wenn
            dies im Rahmen der Zahlungsabwicklung notwendig ist, zum Beispiel an
            das beauftragte Kreditinstitut.
          </p>
          <p>
            Eine Weitergabe Ihrer Daten an Dritte ohne ausdr&uuml;ckliche
            Einwilligung zu Zwecken der Werbung erfolgt nicht.
          </p>
          <h3>Datenverarbeitung</h3>
          <p>
            APPucations GmbH erhebt und speichert automatisch in Ihren Server
            Log Files Informationen, die Ihr Browser an uns &uuml;bermittelt.
            Dies sind:
          </p>
          <ul>
            <li>Browsertyp/ -version</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL (die zuvor besuchte Seite)</li>
            <li>Hostname des zugreifenden Rechners (IP Adresse)</li>
            <li>Uhrzeit der Serveranfrage</li>
          </ul>
          <p>
            Diese Daten sind f&uuml;r APPucations GmbH nicht bestimmten Personen
            zuordenbar. Eine Zusammenf&uuml;hrung dieser Daten mit anderen
            Datenquellen wird nicht vorgenommen.
          </p>
          <h3>Cookies</h3>
          <p>
            Die Internetseiten / Applikation verwenden an mehreren Stellen so
            genannte Cookies. Sie dienen dazu, unser Angebot nutzerfreundlicher,
            effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
            die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
            Die meisten der von uns verwendeten Cookies sind so genannte
            "Session-Cookies". Sie werden nach Ende Ihres Besuchs automatisch
            gel&ouml;scht. Cookies richten auf Ihrem Rechner keinen Schaden an
            und enthalten keine Viren.
          </p>
          <h3>Auskunftsrecht</h3>
          <p>
            Sie haben jederzeit das Recht auf Auskunft &uuml;ber die
            bez&uuml;glich Ihrer Person gespeicherten Daten, deren Herkunft und
            Empf&auml;nger sowie den Zweck der Speicherung. Auskunft &uuml;ber
            die gespeicherten Daten gibt Holger K&ouml;nnecke &ndash;
            kontakt(at)appucations.de.
          </p>
          <h3>Auskunftsrecht, L&ouml;schung, Sperrung</h3>
          <p>
            Sie haben jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber
            Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
            Empf&auml;nger und den Zweck der Datenverarbeitung sowie ein Recht
            auf Berichtigung, Sperrung oder L&ouml;schung dieser Daten.
          </p>
          <p>
            Hierzu, sowie zu weiteren Fragen zum Thema personenbezogene Daten,
            wenden Sie sich bitte jederzeit an Holger K&ouml;nnecke &ndash;
            kontakt(at)appucations.de.
          </p>
          <h3>Nutzung von Facebook-Plugins</h3>
          <p>
            Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook,
            1601 South California Avenue, Palo Alto, CA 94304, USA integriert.
            Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem
            "Like-Button" ("Gef&auml;llt mir") auf unserer Seite. Eine
            &Uuml;bersicht &uuml;ber die Facebook-Plugins finden Sie hier:
            http://developers.facebook.com/docs/plugins/. Wenn Sie unsere
            Internetseiten / Applikation besuchen, wird &uuml;ber das Plugin
            eine direkte Verbindung zwischen Ihrem Browser und dem
            Facebook-Server hergestellt. Facebook erh&auml;lt dadurch die
            Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht
            haben. Wenn Sie den Facebook "Like-Button" anklicken w&auml;hrend
            Sie in Ihrem Facebook-Account eingeloggt sind, k&ouml;nnen Sie die
            Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch
            kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto
            zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten
            keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie deren
            Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden
            Sie in der Datenschutzerkl&auml;rung von facebook unter
            http://de-de.facebook.com/policy.php
          </p>
          <p>
            Wenn Sie nicht w&uuml;nschen, dass Facebook den Besuch unserer
            Seiten Ihrem Facebook- Nutzerkonto zuordnen kann, loggen Sie sich
            bitte aus Ihrem Facebook-Benutzerkonto aus.
          </p>
          <h3>Nutzung von Google Analytics</h3>
          <p>
            Diese Internetseite / Applikation nutzt Google Analytics, einen
            Webanalysedienst der Google Inc. ("Google"). Google Analytics
            verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer
            gespeichert werden und die eine Analyse der Benutzung der Website
            durch Sie erm&ouml;glichen. Die durch den Cookie erzeugten
            Informationen &uuml;ber Ihre Benutzung dieser Website werden in der
            Regel an einen Server von Google in den USA &uuml;bertragen und dort
            gespeichert. Durch die Aktivierung der IP-Anonymisierung auf dieser
            Internetseite / Applikation wird Ihre IP-Adresse von Google jedoch
            innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in
            anderen Vertragsstaaten des Abkommens &uuml;ber den
            Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur in
            Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von
            Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Im Auftrag
            des Betreibers dieser Website wird Google diese Informationen
            benutzen, um Ihre Nutzung der Website auszuwerten, um Reports
            &uuml;ber die Websiteaktivit&auml;ten zusammenzustellen und um
            weitere mit der Websitenutzung und der Internetnutzung verbundene
            Dienstleistungen gegen&uuml;ber dem Websitebetreiber zu erbringen.
            Die im Rahmen von Google Analytics von Ihrem Browser
            &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google
            zusammengef&uuml;hrt.
          </p>
          <p>
            Wir verwenden Google Analytics einschlie&szlig;lich der Funktionen
            von Universal Analytics. Universal Analytics erlaubt es uns, die
            Aktivit&auml;ten auf unseren Seiten ger&auml;te&uuml;bergreifend zu
            analysieren (z.B. bei Zugriffen mittels Laptop und sp&auml;ter
            &uuml;ber ein Tablet). Dies wird durch die pseudonyme Zuweisung
            einer User-ID zu einem Nutzer erm&ouml;glicht. Eine solche Zuweisung
            erfolgt etwa, wenn Sie sich f&uuml;r ein Kundenkonto registrieren
            bzw. sich bei Ihrem Kundenkonto anmelden. Es werden jedoch keine
            personenbezogenen Daten an Google weitergeleitet. Auch wenn mit
            Universal Analytics zus&auml;tzliche Funktionen zu Google Analytics
            hinzukommen, bedeutet dies nicht, dass damit eine Einschr&auml;nkung
            von Ma&szlig;nahmen zum Datenschutz wie IP-Masking oder das
            Browser-Add-on verbunden ist.
          </p>
          <p>
            Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
            s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden
            nutzen k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die
            Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
            Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
            Verarbeitung dieser Daten durch Google verhindern, indem sie
            folgenden Link anklicken:
          </p>
          <h3>Nutzung von Google +1</h3>
          <p>
            Erfassung und Weitergabe von Informationen: Mithilfe der Google
            +1-Schaltfl&auml;che k&ouml;nnen Sie Informationen weltweit
            ver&ouml;ffentlichen. &uuml;ber die Google +1-Schaltfl&auml;che
            erhalten Sie und andere Nutzer personalisierte Inhalte von Google
            und unseren Partnern. Google speichert sowohl die Information, dass
            Sie f&uuml;r einen Inhalt +1 gegeben haben, als auch Informationen
            &uuml;ber die Seite, die Sie beim Klicken auf +1 angesehen haben.
            Ihre +1 k&ouml;nnen als Hinweise zusammen mit Ihrem Profilnamen und
            Ihrem Foto in Google-Diensten, wie etwa in Suchergebnissen oder in
            Ihrem Google-Profil, oder an anderen Stellen auf Websites und
            Anzeigen im Internet eingeblendet werden. Google zeichnet
            Informationen &uuml;ber Ihre +1-Aktivit&auml;ten auf, um die
            Google-Dienste f&uuml;r Sie und andere zu verbessern. Um die Google
            +1-Schaltfl&auml;che verwenden zu k&ouml;nnen, ben&ouml;tigen Sie
            ein weltweit sichtbares, &ouml;ffentliches Google-Profil, das
            zumindest den f&uuml;r das Profil gew&auml;hlten Namen enthalten
            muss. Dieser Name wird in allen Google-Diensten verwendet. In
            manchen F&auml;llen kann dieser Name auch einen anderen Namen
            ersetzen, den Sie beim Teilen von Inhalten &uuml;ber Ihr
            Google-Konto verwendet haben. Die Identit&auml;t Ihres Google-
            Profils kann Nutzern angezeigt werden, die Ihre E-Mail-Adresse
            kennen oder &uuml;ber andere identifizierende Informationen von
            Ihnen verf&uuml;gen.
          </p>
          <p>
            Verwendung der erfassten Informationen: Neben den oben
            erl&auml;uterten Verwendungszwecken werden die von Ihnen
            bereitgestellten Informationen gem&auml;&szlig; den geltenden
            Google-Datenschutzbestimmungen genutzt. Google ver&ouml;ffentlicht
            m&ouml;glicherweise zusammengefasste Statistiken &uuml;ber die
            +1-Aktivit&auml;ten der Nutzer bzw. gibt diese an Nutzer und Partner
            weiter, wie etwa Publisher, Inserenten oder verbundene Websites.
          </p>
          <h3>Weitere Informationen</h3>
          <p>
            Wenn Sie Fragen haben, die Ihnen diese Datenschutzerkl&auml;rung
            nicht beantworten konnte oder wenn Sie zu einem Punkt vertiefte
            Informationen w&uuml;nschen, wenden Sie sich bitte jederzeit an
            Holger K&ouml;nnecke &ndash; kontakt(at)appucations.de.
          </p>
          `;
