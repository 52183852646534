import { Constants } from '../constants';

const initialState = {
  errors: [],
};

export default function globalError(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_GLOBAL_ERROR: {
      let allErrors = [];

      const graphQLErrors = action.payload.graphQLErrors;
      if (graphQLErrors) {
        allErrors.push.apply(
          allErrors,
          graphQLErrors.map((error) => ({
            statusCode: 200,
            message: error.message,
            debugMessage: error.debugMessage,
          })),
        );
      }

      const networkError = action.payload.networkError;
      if (networkError) {
        // console.log({ networkError });
        // window.location.href = '/login';
        allErrors.push({
          statusCode: networkError.statusCode,
          message: networkError.name,
          //debugMessage: networkError.result ? networkError.result.message : 'unknown error',
        });
      }

      return {
        ...state,
        errors: allErrors,
      };
    }
    case Constants.RESET_GLOBAL_ERRORS: {
      return initialState;
    }
    default:
      return state;
  }
}
