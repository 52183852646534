import Login from '../pages/login';
import Onboarding from '../pages/onboarding';
import Register from '../pages/register';
import Learning from '../pages/learning';
import Exam from '../pages/exam';
import Profile from '../pages/profile';
import StatisticsHistory from '../pages/profile/statistics/list';
import StatisticsItem from '../pages/profile/statistics/detail';
import ExamStart from '../pages/exam/start';
import Imprint from '../pages/footer/imprint';
import ConditionTerms from '../pages/footer/conditionTerms';
import DataProtectionPolicy from '../pages/footer/dataProtectionPolicy';
import SubArea from '../pages/learning/subarea';
import LearningContent from '../pages/learning/subarea/content';
import ExamWizard from '../pages/exam/start/wizard';
import ExamFinish from '../pages/exam/finish';
import ExerciseStart from '../pages/exercise/start';
import ExerciseWizard from '../pages/exercise/start/wizard';
import ExerciseFinish from '../pages/exercise/finish';
import LostPassword from '../pages/lostPassword';
import FeedbackPage from '../pages/feedback/feedback';
import ChangePassword from '../pages/profile/changePassword';
import InfoPages from '../pages/infoPage/infoPages';
import OfflineSettings from 'pages/offline-settings';
import ChangePersonalData from 'pages/profile/changePersonalData';
import { RouteItem } from './types';
import Logout from 'pages/logout';
import SelectCourse from '../pages/selectCourse';
import { DeleteUserData } from 'pages/profile/deleteUserData';

export const routeConfig: RouteItem[] = [
  { path: '/logout', component: Logout, type: 'public' },
  { path: '/login', component: Login, type: 'publiconly' },
  { path: '/lost-password', component: LostPassword, type: 'publiconly' },
  { path: '/register', component: Register, type: 'publiconly' },
  { path: '/onboarding', component: Onboarding, type: 'publiconly' },
  { path: '/select-course', component: SelectCourse, type: 'private' },
  { path: '/learning', component: Learning, type: 'private' },
  { path: '/learning/:learningAreaId', component: SubArea, type: 'private' },
  {
    path: '/learning/:learningAreaId/:subAreaId',
    component: SubArea,
    type: 'private',
  },
  {
    path: '/learning-content/:learningAreaId/:parentSubAreaId/:subAreaId',
    component: LearningContent,
    type: 'private',
  },
  { path: '/exam', component: Exam, type: 'private' },
  {
    path: '/exam/start',
    component: ExamStart,
    type: 'private',
    requiresOfflineData: true,
    alternativeRoute: '/exam',
  },
  {
    path: '/exam/wizard',
    component: ExamWizard,
    type: 'private',
    requiresOfflineData: true,

    alternativeRoute: '/exam',
  },
  {
    path: '/exam/finished',
    component: ExamFinish,
    type: 'private',
    requiresOfflineData: true,

    alternativeRoute: '/exam',
  },
  {
    path: '/exercise/:learningAreaId/start',
    component: ExerciseStart,
    type: 'private',
    requiresOfflineData: true,

    alternativeRoute: '/exam',
  },
  {
    path: '/exercise/:learningAreaId/wizard',
    component: ExerciseWizard,
    type: 'private',
    requiresOfflineData: true,

    alternativeRoute: '/exam',
  },
  {
    path: '/exercise/:learningAreaId/finished',
    component: ExerciseFinish,
    type: 'private',
    requiresOfflineData: true,
    alternativeRoute: '/exam',
  },
  { path: '/profile', type: 'private', component: Profile },
  {
    path: '/profile/statistics/history',
    type: 'private',
    component: StatisticsHistory,
  },
  {
    path: '/profile/statistics/result/:resultId',
    type: 'private',
    component: StatisticsItem,
  },
  {
    path: '/feedback',
    type: 'private',
    component: FeedbackPage,
  },
  { path: '/terms-of-service', component: ConditionTerms, type: 'public' },
  { path: '/imprint', component: Imprint, type: 'public' },
  {
    path: '/data-protection-policy',
    component: DataProtectionPolicy,
    type: 'public',
  },
  { path: '/offline-settings', component: OfflineSettings, type: 'private' },
  { path: '/info-pages/:page', component: InfoPages, type: 'private' },
  { path: '/change-password', component: ChangePassword, type: 'private' },
  { path: '/delete-user-data', component: DeleteUserData, type: 'private' },
  {
    path: '/profile-settings',
    component: ChangePersonalData,
    type: 'private',
  },
];
