import {Constants} from "../constants";
import {cloneDeep, shuffle} from 'lodash';

const initialState = {
  timer: {
    endDate: new Date(),
    startDate: new Date(),
    isRunning: false,
  },
  maxQuestions: 0,
  maxTimeInMinutes: 0,
  maxCorrectAnswersCount: 0,
  questions: [],
  result: null,
};

export default function exam(state = initialState, action) {
  switch (action.type) {
    case Constants.PREPARE_EXAM: {
      const {questions, max_count_correct_answers, duration} = action.payload;
      const now = new Date();

      return {
        ...state,
        timer: {
          ...state.timer,
          startDate: now,
          endDate: new Date(now.getTime() + duration * 60 * 1000),
        },
        maxQuestions: questions.length,
        maxTimeInMinutes: duration,
        maxCorrectAnswersCount: max_count_correct_answers,
        questions: shuffle(questions.map(question => (
          {
            ...question,
            answers: question.answers.map(answer => (
              {
                ...answer,
                is_correct: false,
              }
            ))
          }
        ))),
      }
    }
    case Constants.START_EXAM: {
      const {duration} = action.payload;
      const now = new Date();

      return {
        ...state,
        timer: {
          ...state.timer,
          startDate: now,
          endDate: new Date(now.getTime() + duration * 60 * 1000),
          isRunning: true,
        }
      };
    }
    case Constants.TOGGLE_EXAM_ANSWER: {
      const {answerIndex, questionIndex} = action.payload;
      return {
        ...state,
        questions: state.questions.map((question, index) => {
            if (index !== questionIndex) return question;
            return {
              ...question,
              answers: question.answers.map((answer, i) => {
                if (i !== answerIndex) return answer;
                return {
                  ...answer,
                  is_correct: !answer.is_correct,
                };
              })
            }
          }
        )
      }
    }
    case Constants.STOP_EXAM: {
      const newState = cloneDeep(state);
      newState.timer = {
        endDate: new Date(),
        startDate: new Date(),
        isRunning: false,
      };
      newState.questions = [];
      return newState;
    }
    case Constants.SET_EXAM_RESULT: {
      return {
        ...state,
        result: action.payload,
      };
    }
    case Constants.RESET_EXAM: {
      return initialState;
    }
    default:
      return state;
  }
}
