import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import { writeFile } from 'capacitor-blob-writer';
import {
  CapacitorSQLite,
  SQLiteConnection,
  SQLiteDBConnection,
} from '@capacitor-community/sqlite';

import { Banner } from 'common/molecules';
import { Page } from 'components/pages';
import { OfflineSetting } from './components/offlineSetting';

import './offlineSettingStyles.scss';

import {
  OfflineSettingsSizesState,
  OfflineSettingsState,
  OfflineSettingSubareaType,
  OfflineSettingType,
} from './types';

import { Button } from 'common/atoms';
import {
  deleteTableDataMutation,
  getOfflineDataQuery,
  setOfflineDataMutation,
  storeExerciseData,
  storeImageData,
  storeLearningData,
  storeTextData,
} from './queries';
import {
  blobToBase64,
  bytesToMegabyte,
  getStringSizeInBytes,
} from 'libs/utils';
import { clearTables, initializeSQLiteTables } from 'redux/queries';
import { OfflineModeState } from 'redux/reducers/types';
import { isPlatform } from '@ionic/core';
import { useIonViewWillEnter } from '@ionic/react';
import { Spinner } from 'common/atoms/spinner';
import { Directory } from '@capacitor/filesystem';

const OfflineSettings: React.FunctionComponent<{
  offlineMode: OfflineModeState;
  license: any;
}> = ({ offlineMode, license }) => {
  const [currentProduct, setCurrentProduct] = useState<number | null>(
    license.product_id || null,
  );
  const [
    initialCheckedState,
    setInitialCheckedState,
  ] = useState<OfflineSettingsState | null>(null);
  const [
    checkedFields,
    setCheckedFields,
  ] = useState<OfflineSettingsState | null>(null);
  const initialSizesState = {
    product: null,
    learning: null,
    learning_images: null,
    exercise: null,
    statistics: null,
  };
  const [sizes, setSizes] = useState<OfflineSettingsSizesState>(
    initialSizesState,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { goBack } = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    console.log(license);
    if (license.product_id !== currentProduct) {
      setInitialCheckedState(null);
      setCheckedFields(null);
      setCheckedFields(null);
      setSizes(initialSizesState);
      setCurrentProduct(license.product_id);
    }
  }, [license.product_id]); //eslint-disable-line

  useEffect(() => {
    if (offlineMode.data && !sizes.learning && !!checkedFields)
      calculateSizes();
  }, [offlineMode, sizes, checkedFields]); //eslint-disable-line

  const savePicture = async (img: string, fileName: string) => {
    try {
      const response = await fetch(img);
      const imgBlob = await response.blob();
      const { uri } = await writeFile({
        path:
          'appucations' +
          (currentProduct || process.env.REACT_APP_PRODUCT_ID) +
          '/' +
          fileName,
        data: imgBlob,
        directory: Directory.Data,
        recursive: true,
      });
      return Capacitor.convertFileSrc(uri);
    } catch (err) {
      return '';
    }
  };

  useIonViewWillEnter(async () => {
    const initialCheckedState = {
      learning: { all: false, images: false },
      exercise: { all: false },
      statistics: { all: false },
    };
    const connectionResult = await connectToDatabase(currentProduct);
    try {
      if (connectionResult?.db && connectionResult?.connection) {
        const { db, connection } = connectionResult;
        const res = await db.query(getOfflineDataQuery);
        if (res.values?.length) {
          res.values.map((value: { type: string; stored: number }) => {
            const { type, stored } = value;
            const [mainArea, subArea] = type.split('_');
            if (subArea) {
              initialCheckedState[mainArea][subArea] = !!stored;
            } else {
              initialCheckedState[mainArea].all = !!stored;
            }
          });
        }
        await db.close();
        await connection.closeConnection(
          'appucations' + (currentProduct || process.env.REACT_APP_PRODUCT_ID),
        );
      }
    } catch (err) {
      console.log('err!');
      console.log(err);
    }

    setCheckedFields(initialCheckedState);
    setInitialCheckedState(initialCheckedState);
  });

  const connectToDatabase = async (currentProduct: number | null) => {
    if (isPlatform('mobile') && !isPlatform('mobileweb')) {
      const connection: SQLiteConnection = new SQLiteConnection(
        CapacitorSQLite,
      );
      const connectionName =
        'appucations' + (currentProduct || process.env.REACT_APP_PRODUCT_ID);

      try {
        const ret = await connection.checkConnectionsConsistency();
        const isConn = await connection.isConnection(connectionName);
        let db: SQLiteDBConnection;
        if (ret.result && isConn.result) {
          db = await connection.retrieveConnection(connectionName);
        } else {
          db = await connection.createConnection(
            connectionName,
            false,
            'no-encryption',
            1,
          );
        }
        await db.open();
        return { db, connection };
      } catch (err) {
        console.log('ERR!');
        console.log(err);
      }
    }
  };

  const calculateSizes = async () => {
    const newSizes = { ...sizes };
    const {
      product,
      exercises,
      learning_areas,
      learning_contents,
      images,
      results,
    } = offlineMode.data;

    //learning content sizes
    const learningAreasString = JSON.stringify(learning_areas);
    let learningContentsString = JSON.stringify(learning_contents);

    // without images
    newSizes.learning = getStringSizeInBytes(
      learningAreasString + learningContentsString,
    );

    // images
    let imageSizes = 0;

    try {
      await Promise.all(
        images && Object.values(images).map(async (image: string) => {
          try {
            console.log(image);
            const imageData = await fetch(image);
            const imageBlob = await imageData.blob();
            const base64 = await blobToBase64(imageBlob);
            imageSizes += getStringSizeInBytes(base64);
          } catch (err) {
            console.error(err);
          } // just ignore that image when calculating the size
        }),
      );
    } catch (e) {
      console.error(e)
    }


    newSizes.learning_images = imageSizes;

    //product (for all!)
    newSizes.product = getStringSizeInBytes(JSON.stringify(product));

    //exercise content sizes
    newSizes.exercise = getStringSizeInBytes(JSON.stringify(exercises));

    //statistics
    newSizes.statistics = getStringSizeInBytes(JSON.stringify(results));
    setSizes(newSizes);
  };

  const handleChangeSetting = (
    area: OfflineSettingType,
    subarea: keyof OfflineSettingsState[OfflineSettingType],
  ) => {
    const selectedArea: OfflineSettingsState[OfflineSettingType] =
      checkedFields[area];
    let newSubarea = {
      ...selectedArea,
      [subarea]: !selectedArea[subarea],
    };
    if (subarea === 'all' && area === 'learning' && selectedArea.all) {
      newSubarea = { ...newSubarea, images: false };
    }
    setCheckedFields({ ...checkedFields, [area]: newSubarea });
  };

  const handleSaveOfflineData = async () => {
    setIsSubmitting(true);
    const dbres = await connectToDatabase(currentProduct);
    let db, connection;
    if (dbres) {
      db = dbres.db;
      connection = dbres.connection;
    }
    if (db && !!checkedFields) {
      try {
        await db.execute(deleteTableDataMutation('offline_settings'));
      } catch (err) {
        console.log('no offline data');
      }
      try {
        await db.execute(initializeSQLiteTables);
      } catch (err) {
        console.log(err);
      }

      await db.execute(setOfflineDataMutation(checkedFields));
      await db.execute(clearTables); // clear all tables first
      await Promise.all(
        Object.keys(checkedFields).map(async (area) => {
          const selectedArea = (checkedFields as any)[area];
          return Promise.all(
            Object.keys(selectedArea).map(async (subarea) => {
              if (selectedArea[subarea]) {
                try {
                  return storeData(
                    area as OfflineSettingType,
                    subarea as OfflineSettingSubareaType,
                    db,
                  );
                } catch (err) {
                  console.log(err);
                }
              }
            }),
          );
        }),
      );
    }
    await db.close();
    await connection.closeConnection(
      'appucations' + (currentProduct || process.env.REACT_APP_PRODUCT_ID),
    );
    setIsSubmitting(false);
    setInitialCheckedState(checkedFields);
  };

  const storeData = async (
    area: OfflineSettingType,
    subarea: OfflineSettingSubareaType,
    db: SQLiteDBConnection,
  ) => {
    const {
      exam, // EXERCISE TABLE
      exercises, // EXERCISE TABLE
      newSettings, // EXERCISE TABLE
      learning_areas, // LEARNING TABLE
      learning_contents, // LEARNING TABLE
      sub_areas, // LEARNING TABLE
      images,
      results,
      product,
    } = offlineMode.data;

    const { version } = product;
    const imagesArray = Object.values(images);
    if (subarea === 'all') {
      await db.execute(
        storeTextData({
          tableName: 'product',
          version,
          content: JSON.stringify(product),
        }),
      );
      if (area === 'learning') {
        let learningContentsString = JSON.stringify(learning_contents);
        if (checkedFields?.learning.images) {
          await db.execute(
            storeImageData({ index: 0, url: imagesArray.length.toString() }),
          );
          await Promise.all(
            imagesArray.map(async (url, index) => {
              try {
                await savePicture(url, `img-src-${index}.jpg`);
                learningContentsString = learningContentsString.replace(
                  url,
                  `img-src-${index}`,
                );
              } catch (err) {}
            }),
          );
        }
        await db.execute(
          storeLearningData({
            version,
            title: 'learningcontent',
            content: learningContentsString,
          }),
        );
        await db.execute(
          storeLearningData({
            version,
            title: 'learningareas',
            content: JSON.stringify(learning_areas),
          }),
        );
        await db.execute(
          storeLearningData({
            version,
            title: 'subareas',
            content: JSON.stringify(sub_areas),
          }),
        );
      } else if (area === 'exercise') {
        if (!checkedFields?.learning.all) {
          // we also need learningareas for exercises! So if we haven't stored it already, store it now
          await db.execute(
            storeLearningData({
              version,
              title: 'learningareas',
              content: JSON.stringify(learning_areas),
            }),
          );
        }
        await db.execute(
          storeExerciseData({
            version,
            title: 'exam',
            content: JSON.stringify(exam),
          }),
        );
        await db.execute(
          storeExerciseData({
            version,
            title: 'exercises',
            content: JSON.stringify(exercises),
          }),
        );
        await db.execute(
          storeExerciseData({
            version,
            title: 'settings',
            content: JSON.stringify(newSettings),
          }),
        );
      } else if (area === 'statistics') {
        await db.execute(
          storeTextData({
            tableName: 'statistics',
            content: JSON.stringify(results),
            version,
          }),
        );
      }
    }
  };

  const calculateDisplayedDiskSpace = () => {
    if (!sizes.product) return null;
    let sum = 0;
    Object.keys(checkedFields).map((area: any) => {
      const areaTitle = area;
      area = (checkedFields as any)[area];
      Object.keys(area).map((subarea: any) => {
        const sizeAreaTitle = `${areaTitle}${
          subarea !== 'all' ? '_' + subarea : ''
        }`;
        if (area[subarea]) {
          sum += (sizes as any)[sizeAreaTitle];
        }
      });
    });
    if (sum !== 0) sum += sizes.product;
    return bytesToMegabyte(sum);
  };

  const diskSpace = calculateDisplayedDiskSpace();

  return (
    <Page>
      <div className="banner-wrapper banner-offlinemode lineheight">
        <Banner headline={t('offlineMode')} onClick={goBack}>
          <h3 style={{ margin: 0 }}>{t('offlineModeHeadline')}</h3>
          <p>{t('offlineModeContent')}</p>
          <p>{t('offlineModeContentLoadingTime')}</p>
          {checkedFields ? (
            <div className="offlinesetting-wrapper">
              <OfflineSetting
                type="learning"
                title={t('offlineModeLearning')}
                checkedFields={checkedFields}
                handleChange={handleChangeSetting}
                subareas={{
                  images: t('offlineModeLearningImages'),
                }}
              />
              <OfflineSetting
                type="exercise"
                title={t('offlineModeExercises')}
                checkedFields={checkedFields}
                handleChange={handleChangeSetting}
              />
              <OfflineSetting
                type="statistics"
                title={t('offlineModeStatistics')}
                checkedFields={checkedFields}
                handleChange={handleChangeSetting}
              />
            </div>
          ) : null}
          <div className="offlineSettings--actions">
            <div className="offlineSettings--diskspace">
              <p>{t('offlineModeDiskSpace')}:</p>
              {diskSpace ? (
                <p className="space">{diskSpace}</p>
              ) : (
                <div className="offlineSettings--diskspace-spinner">
                  <Spinner tiny />
                </div>
              )}
            </div>
            <Button
              disabled={
                isSubmitting ||
                JSON.stringify(initialCheckedState) ===
                JSON.stringify(checkedFields)
              }
              isLoading={isSubmitting}
              text={t('update')}
              onClick={handleSaveOfflineData}
            />
          </div>
        </Banner>
      </div>
    </Page>
  );
};

export default connect(
  ({ offlineMode, license }) => ({ offlineMode, license }),
  null,
)(OfflineSettings);
