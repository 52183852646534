/* eslint no-restricted-globals:0 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { useHistory } from 'react-router';
import { cloneDeep } from 'lodash';
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { useMutation } from '@apollo/react-hooks';

import { ExamWizardPage } from '../../../../common/pages';
import Page from '../../../../components/pages';
import {
  setExamResult,
  stopExam,
  toggleExamAnswer,
} from '../../../../redux/actions/exam';
import { Result, ResultInput } from '../../../../graphql';
import '../../../../theme/pages/examWizard.scss';
import { setQueueAction } from '../../../../redux/actions/queue';
import { setOfflineResults } from '../../../../redux/actions/offlineModus';
import ExamQueries from '../../../../libs/ExamQueries';
import { BaseModal } from '../../../../common/organisms';
import LoadingOverlay from 'common/organisms/loadingOverlay';
import IcoSuccess from '../../../../assets/icons/ico_success.svg';

interface ExamWizardProps {
  stopExamAction: () => void;
  setQueue: (request: any) => void;
  setOfflineResults: (data: any) => void;
  toggleExamAnswer: (answer, question) => void;
  setExamResult: (payload) => void;
  exam: any;
  offlineMode: any;
  product: any;
}

let pushEventCreated = false;

const ExamWizard: React.FC<ExamWizardProps> = ({
  stopExamAction,
  toggleExamAnswer,
  setExamResult,
  exam,
  offlineMode,
  product,
  setOfflineResults,
  setQueue,
}) => {
  const { push, goBack } = useHistory();
  const { t } = useTranslation();
  const [resultCreated, setResultCreated] = useState(false);
  const [createResult] = useMutation(Result.createMutation());
  const [questionIndex, setQuestionIndex] = useState(0);
  const [storingOfflineResults, setStoringOfflineResults] = useState(false);
  const [examFinished, setExamFinished] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  useIonViewWillEnter(() => {
    setExamFinished(false);
    setResultCreated(false);
  }, [setResultCreated]);

  useEffect(() => {
    if (!!exam.questions.length && !examFinished) {
      checkAnswerAmount();
    }
  }, [exam, examFinished]); //eslint-disable-line

  useIonViewWillLeave(() => {
    setExamFinished(true);
    stopExamAction();
    window.removeEventListener('popstate', eventListener);
  }, [location.pathname]);

  const quoteToSucceed =
    offlineMode.data?.newSettings?.exam_percentage_for_pass || 100;
  const instanceExamQueries = new ExamQueries(offlineMode.data);

  exam.questions = exam.questions.map((question, index) => {
    question.answers = question.answers.map((ans) => {
      const ansCorrect = instanceExamQueries.getCorrectAnswerFromQuestion(
        question.learning_area.id,
        question.id,
        ans.id,
      );
      ans.needs_check = ansCorrect.is_correct; // shows true options
      return ans;
    });
    return question;
  });

  let eventListener = function (event: any) {
    if (location.pathname === '/exam/wizard') {
      return;
    }
    const confirmed = confirm(t('cancelExamWizard'));
    if (!confirmed) {
      //window.history.pushState(null, document.title, window.location.href);
      push(location.pathname);
      event.preventDefault();
      return;
    } else {
      onClickFinish();
    }
  };

  if (location.pathname === '/exam/wizard' && !pushEventCreated) {
    // window.history.pushState(null, document.title, window.location.href);
    push(location.pathname);
    pushEventCreated = true;
    window.addEventListener('popstate', eventListener);
    window.onbeforeunload = function (event) {
      return false;
    };
  }

  function onClickPrevious() {
    onSwiped({
      dir: 'Right',
    });
  }

  function onClickNext() {
    if (!examFinished) {
      onSwiped({
        dir: 'Left',
      });
    }
  }

  function handleToggleAnswer(answer, question) {
    toggleExamAnswer(answer, question);
  }

  function checkAnswerAmount() {
    const rightAnswerCount =
      exam.questions[questionIndex].count_correct_answers;

    const checkedAnswers = exam.questions[questionIndex].answers.filter(
      (answer) => answer.is_correct === true,
    );
    if (rightAnswerCount > 0 && rightAnswerCount < checkedAnswers.length) {
      setShowModal(true);
    }
  }

  let counter = 0;
  let point = 0;

  const handleRequestOfflineStorage = (endDate: number) => {
    setStoringOfflineResults(true);
    // alert('storing offline!');
    exam.questions.forEach((question) => {
      if (question && instanceExamQueries.isAnsweredCorrect(question)) {
        ++counter;
        point += question.points;
      }
    });

    if (!resultCreated) {
      let index = 0;
      let totalPoints = 0;
      const allQuestions = cloneDeep(exam.questions);
      exam.questions.forEach((question) => (totalPoints += question.points));
      const id = Math.trunc(Date.now() / 1000 + Math.random() * 1000);
      exam.points = totalPoints;
      exam.points_achieved = point;
      let overallPoints = 0;
      let maximumPoints = 0;
      const result = {
        id,
        count_correct_questions: counter,
        count_wrong_questions: exam.questions.length - counter,
        duration:
          endDate / 1000 - Date.parse(exam.timer.startDate.toString()) / 1000,
        finished_at: endDate / 1000,

        learning_areas: offlineMode.data.learning_areas.map(
          (learningArea: any) => {
            let questionsOfThisLearningarea = allQuestions.filter(
              (question) => question.learning_area.id === learningArea.id,
            );

            questionsOfThisLearningarea = questionsOfThisLearningarea.map(
              (question) => {
                index++;
                question.answers = question.answers.map((ans) => {
                  return {
                    ...ans,
                    is_checked: ans.is_correct,
                    is_correct: ans.is_correct && ans.needs_check,
                  };
                });
                question.is_correct =
                  question.answers.filter((question) => question.needs_check)
                    .length ===
                  question.answers.filter((question) => question.is_correct)
                    .length;
                return { ...question, index };
              },
            );
            const totalPointsOfThisLearningarea = exam.questions.reduce(
              (currentPoints, question) => {
                if (
                  questionsOfThisLearningarea
                    .map((q) => q.id)
                    .includes(question.id)
                ) {
                  return currentPoints + question.points;
                }
                return currentPoints;
              },
              0,
            );

            const pointsOfThisLearningarea = exam.questions.reduce(
              (currentPoints, question) => {
                const foundQuestion = questionsOfThisLearningarea.find(
                  (q) => q.id === question.id,
                );

                if (foundQuestion && foundQuestion.is_correct) {
                  return currentPoints + question.points;
                }
                return currentPoints;
              },
              0,
            );
            overallPoints += pointsOfThisLearningarea;
            const correctQuestions = questionsOfThisLearningarea.filter(
              (question) => question.is_correct,
            );
            return {
              id: learningArea.id,
              name: learningArea.headline,
              questions: questionsOfThisLearningarea,
              points_achieved: pointsOfThisLearningarea,
              points: totalPointsOfThisLearningarea,
              count_correct_questions: correctQuestions.length,
              count_wrong_questions:
                questionsOfThisLearningarea.length - correctQuestions.length,
              is_success:
                (pointsOfThisLearningarea / totalPointsOfThisLearningarea) *
                  100 >=
                quoteToSucceed,
            };
          },
        ),
        points: totalPoints,
        name: exam.name,
        points_achieved: point,
        is_success: (overallPoints / totalPoints) * 100 >= quoteToSucceed,
        started_at: Date.parse(exam.timer.startDate.toString()) / 1000,
        type: 'exam',
        __typename: 'Result',
      };

      setQueue({
        request: {
          variables: new ResultInput(
            exam,
            product.id,
            'exam',
          ).toGraphqlVariables(),
        },
        type: 'examResult',
        id,
      });

      setExamResult(result);
      setOfflineResults(result);
      setResultCreated(true);
      setQuestionIndex(0);
      stopExamAction();
      stopExam();
      push('/exam/finished');
    }
  };

  function onClickFinish() {
    setExamFinished(true);
    const endDate = Date.parse(new Date().toString());
    if (offlineMode.isOffline) handleRequestOfflineStorage(endDate);
    // avoid waiting for result to fail, if we're offline anyways
    else {
      createResult({
        variables: {
          ...new ResultInput(exam, product.id, 'exam').toGraphqlVariables(),
        },
      })
        .then((response) => {
          setExamResult(response.data.createResult);
          setOfflineResults(response.data.createResult);
          setResultCreated(true);
          setQuestionIndex(0);
          stopExamAction();
          push('/exam/finished');
        })
        .catch((e) => {
          handleRequestOfflineStorage(endDate);
        });
    }
  }

  function navigateTo(index) {
    setQuestionIndex(index);
  }

  const onSwiped = function (swipeEvent) {
    if (swipeEvent.dir === 'Right') {
      const newIndex = questionIndex - 1;
      if (exam.questions.length > 0 && exam.questions[newIndex]) {
        setQuestionIndex(questionIndex - 1);
      } else {
        setQuestionIndex(exam.questions.length - 1);
      }
      instanceExamQueries.isAnsweredCorrect(exam.questions[newIndex]);
    }

    if (swipeEvent.dir === 'Left') {
      const newIndex = questionIndex + 1;
      if (exam.questions.length > 0 && exam.questions[newIndex]) {
        setQuestionIndex(questionIndex + 1);
      } else {
        setQuestionIndex(0);
      }
      instanceExamQueries.isAnsweredCorrect(exam.questions[newIndex]);
    }
  };

  const question =
    exam.questions.length > 0 && exam.questions[questionIndex]
      ? exam.questions[questionIndex]
      : null;

  return (
    <>
      <BaseModal
        setModalIsOpen={setShowModal}
        modalIsOpen={showModal}
        className="modal">
        <div className="overlay">
          <img src={IcoSuccess} className="svg" height="101" alt="" />
          <h4>{'Zu viele Antworten ausgewählt'}</h4>
          <p>
            {
              'Achte auf die maximale Anzahl deiner Antworten. Es wurden zu viele ausgewählt'
            }
          </p>
          <hr />
          <div className="actions single">
            <button onClick={() => setShowModal(false)} className="abort">
              {'VERSTANDEN'}
            </button>
          </div>
        </div>
      </BaseModal>

      <Page title="simulateExam" hideFooter>
        {question && (
          <ExamWizardPage
            key={question.id}
            questionIndex={questionIndex}
            showCorrectAnswersCount={question.count_correct_answers > 0}
            exam={exam}
            onToggleAnswer={handleToggleAnswer}
            onClickFinish={onClickFinish}
            onExpire={onClickFinish}
            onClickPrevious={onClickPrevious}
            onClickNext={onClickNext}
            navigateTo={navigateTo}
          />
        )}
      </Page>
      {!resultCreated && examFinished && (
        <LoadingOverlay color="rgba(245,173,36,.8)" />
      )}
    </>
  );
};

export default connect(
  ({ exam, product, offlineMode }) => ({
    exam,
    product,
    offlineMode,
  }),
  {
    stopExamAction: stopExam,
    toggleExamAnswer,
    setExamResult,
    setOfflineResults,
    setQueue: setQueueAction,
  },
)(ExamWizard);
