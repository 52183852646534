import * as React from 'react';
import { connect } from 'react-redux';

import './sideBarStyles.scss';
import SideBarUserData from './compUserData';
import SideBarProfileImage from './compProfileImage';
import SideBarInformationBox from './compInformationBox';
import { ProductState, UserState } from 'redux/reducers/types';

interface InterfaceSideBarProps {
  user: UserState;
  appDefaults: any;
  product: ProductState;
  signOut: () => void;
  updateUser: (userData: any) => void;
  onChangePasswordClick: () => void;
  onFeedbackClick: () => void;
  onInfoAreaClicked: () => void;
  onStatisticClick: () => void;
}

const SideBar: React.FunctionComponent<InterfaceSideBarProps> = ({
  user,
  signOut,
  updateUser,
  onChangePasswordClick,
  product,
  onFeedbackClick,
  onInfoAreaClicked,
  onStatisticClick,
}) => (
  <div className="sidebar">
    <SideBarProfileImage user={user} updateUser={updateUser} />
    <SideBarUserData
      onChangePasswordClick={onChangePasswordClick}
      updateUser={updateUser}
      signOut={signOut}
      product={product}
    />
    <SideBarInformationBox
      onStatisticClick={onStatisticClick}
      onInfoAreaClicked={onInfoAreaClicked}
      onFeedbackClick={onFeedbackClick}
    />
  </div>
);

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    product: state.product,
    appDefaults: state.appDefaults,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
