import React from 'react';
import './copyright.scss';

const Copyright = () => {
  const version = process.env.REACT_APP_VERSION_NO;
  return (
    <div className="copyright__wrapper">
      {version && <p>Version: {version}</p>}
      <p>
        <a href="https://www.appucations.de/" target="_blank">
          &copy; {new Date().getFullYear()} APPucations GmbH
        </a>
      </p>
    </div>
  );
};

export default Copyright;
