import {Constants} from "../constants";


export default function product(state = {}, action) {
    switch (action.type) {
        case Constants.SET_PRODUCT: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case Constants.RESET_PRODUCT: {
            return {};
        }
        default:
            return state;
    }
}
