import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { Spinner } from 'common/atoms/spinner';
import { useHistory } from 'react-router';
import { signOut } from 'redux/actions';

const LogoutPage: React.FC<any> = ({ signOut }) => {
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(true);

  useIonViewWillEnter(() => setIsVisible(true));

  useEffect(() => {
    localStorage.clear();
    localStorage.setItem('login', 'true');
    signOut();
    setTimeout(() => {
      history.push('/login');
      setIsVisible(false);
    }, 1000);
  }, []); //eslint-disable-line

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: isVisible ? 300 : -1,
        top: 'env(safe-area-inset-top)',
        bottom: 'env(safe-area-inset-bottom)',
        left: 0,
        right: 0,
        background: '#2f9cf2',
        textAlign: 'center',
      }}>
      <div id="loading-overlay">
        <Spinner color="#fff" />
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
});

export default connect(null, mapDispatchToProps)(LogoutPage);
