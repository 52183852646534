import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RoundButton, Button, Headline, Checkbox, Span } from '../../atoms';
import { Stopwatch, ListItem } from '../../molecules';
import { ExamNavigation, ConfirmFinishExam } from '../../molecules/modal';
import { List } from '../../organisms';
import { countUnansweredQuestions } from '../../../libs/utils';
import './examWizard.scss';

interface ExamWizardProps {
  exam: any;
  questionIndex: number;
  showCorrectAnswersCount: boolean;
  onToggleAnswer: (index: number, questionIndex: number) => void;
  onExpire: () => void;
  onClickFinish: () => void;
  onClickPrevious: () => void;
  onClickNext: () => void;
  navigateTo: (questionIndex: number) => void;
}

const ExamWizardPage: React.FunctionComponent<ExamWizardProps> = ({
  exam,
  questionIndex,
  showCorrectAnswersCount = false,
  onToggleAnswer,
  onExpire,
  onClickFinish,
  onClickPrevious,
  onClickNext,
  navigateTo,
}) => {
  const { t } = useTranslation();
  const question = exam.questions[questionIndex];
  const unansweredCount = countUnansweredQuestions(exam.questions);
  const [leftNavigationModalIsOpen, setLeftNavigationModalIsOpen] = useState(
    false,
  );
  const [rightNavigationModalIsOpen, setRightNavigationModalIsOpen] = useState(
    false,
  );
  const [hasShownCompletedModal, setHasShownCompletedModal] = useState(false);
  const [bottomFinishModalIsOpen, setBottomFinishModalIsOpen] = useState(false);

  useEffect(() => {
    if (!unansweredCount && !hasShownCompletedModal) {
      setHasShownCompletedModal(true);
      setBottomFinishModalIsOpen(true);
    }
  }, [questionIndex]); //eslint-disable-line

  return (
    <div className="exam-wizard">
      <div className="exam-wizard__panel">
        <div className="exam-wizard__header">
          <div className="exam-wizard__header-item exam-wizard__header-item--is-left">
            <ExamNavigation
              childrenBefore={
                <RoundButton
                  iconSize={32}
                  transparent
                  theme="tertiary"
                  icon="Grid3x3GapFill"
                  text={t('questionCountWithTotal', {
                    count: questionIndex + 1,
                    totalCount: exam.questions.length,
                  })}
                  onClick={() => setLeftNavigationModalIsOpen(true)}
                />
              }
              setModalIsOpen={setLeftNavigationModalIsOpen}
              modalIsOpen={leftNavigationModalIsOpen}
              navigateTo={navigateTo}
              activeQuestionId={question.id}
              questions={exam.questions}
            />
            <Span type="tertiary">{`${questionIndex + 1} / ${
              exam.questions.length
            }`}</Span>
          </div>
          <div className="exam-wizard__header-item __header-item--is-middle">
            <Stopwatch
              timer={{
                ...exam.timer,
                onExpire: onExpire,
              }}
            />
          </div>
          <div className="exam-wizard__header-item exam-wizard__header-item--is-right">
            <ExamNavigation
              childrenBefore={
                <RoundButton
                  transparent
                  theme="tertiary"
                  icon="Grid3x3GapFill"
                  onClick={() => setRightNavigationModalIsOpen(true)}
                />
              }
              setModalIsOpen={setRightNavigationModalIsOpen}
              modalIsOpen={rightNavigationModalIsOpen}
              navigateTo={navigateTo}
              activeQuestionId={question.id}
              questions={exam.questions}
            />
          </div>
        </div>
        <Headline type="h2">
          {question.question}
          {showCorrectAnswersCount && (
            <div className="correct-answers-count">
              {t('correctAnswers', {
                countCorrectAnswers: question.count_correct_answers,
              })}
            </div>
          )}
        </Headline>
      </div>
      <List>
        {question.answers.map((answer, index) => (
          <ListItem
            key={`exam-question-answer-${index}`}
            title={answer.text}
            removeButton
            isSelected={answer.is_correct}
            theme="secondary"
            onClick={() => onToggleAnswer(index, questionIndex)}>
            <Checkbox
              value={answer.is_correct}
              name={`answers.${index}`}
              type="secondary"
            />
          </ListItem>
        ))}
      </List>
      <div className="exam-wizard__footer">
        <div className="exam-wizard__footer-item">
          <RoundButton
            transparent
            theme="tertiary"
            icon="ChevronLeft"
            onClick={onClickPrevious}
          />
        </div>
        <div className="exam-wizard__footer-item">
          <ConfirmFinishExam
            setModalIsOpen={setBottomFinishModalIsOpen}
            modalIsOpen={bottomFinishModalIsOpen}
            childrenBefore={
              <Button
                transparent
                theme="tertiary"
                text={t('finishExam')}
                onClick={() => {
                  setBottomFinishModalIsOpen(true);
                }}
              />
            }
            unansweredQuestions={unansweredCount}
            onFinishExam={onClickFinish}
          />
        </div>
        <div className="exam-wizard__footer-item">
          <RoundButton
            transparent
            theme="tertiary"
            icon="ChevronRight"
            onClick={onClickNext}
          />
        </div>
      </div>
    </div>
  );
};

export default ExamWizardPage;
