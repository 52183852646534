import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Button, Headline, Span } from '../../atoms/';
import { replaceHTMLEntitiesAndTags } from 'libs/utils';
import './splash.scss';

interface ExerciseSplashProps {
  questionCount: number;
  correctAnswersCount: number;
  headline: string;
  onClick: () => void;
}

const ExerciseSplash: React.FunctionComponent<ExerciseSplashProps> = ({
  questionCount,
  correctAnswersCount,
  headline,
  onClick,
}) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  return (
    <div className="splash">
      <Headline type="h2">{replaceHTMLEntitiesAndTags(headline)}</Headline>
      <div className="splash__description">
        <Span>
          {t('typeExercise')}
          <br />
          {t('questionWithoutMinutes', { questionCount })}
          <br />
          {correctAnswersCount === 1
            ? t('exerciseTypeAndCorrectAnswer', {
                correctAnswersCount,
              })
            : t('exerciseTypeAndCorrectAnswers', {
                correctAnswersCount,
              })}
        </Span>
      </div>
      <Button theme="secondary" text={t('begin')} onClick={onClick} />
      <p className="splash__goback" onClick={goBack}>
        {t('back')}
      </p>
    </div>
  );
};
export default ExerciseSplash;
