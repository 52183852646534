import React from 'react';

import './loadingOverlay.scss';
import { Spinner } from 'common/atoms/spinner';

const LoadingOverlay: React.FunctionComponent<{ color: string }> = ({
  color,
}) => {
  return (
    <div className="loadingOverlay" style={{ background: color }}>
      <Spinner color="#fff" />
    </div>
  );
};

export default LoadingOverlay;
