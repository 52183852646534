import {Constants} from "../constants";

const initialState = {
    timer: {
        endDate: new Date(),
        startDate: new Date(),
    },
    maxQuestions: 0,
    maxCorrectAnswersCount: 0,
    questions: [],
    name: '',
    result: null,
};

export default function exercise(state = initialState, action) {
    switch (action.type) {
        case Constants.PREPARE_EXERCISE: {
            const {questions, max_count_correct_answers} = action.payload;
            const now = new Date();

            return {
                ...state,
                timer: {
                    startDate: now,
                    endDate: now,
                },
                maxQuestions: questions.length,
                maxCorrectAnswersCount: max_count_correct_answers,
                result: null,
                name: action.payload.name,
                questions: questions.map(question => (
                    {
                        ...question,
                        answers: question.answers.map(answer => (
                            {
                                ...answer,
                                is_correct: false,
                            }
                        ))
                    }
                )),
            }
        }
        case Constants.START_EXERCISE: {
            const now = new Date();

            return {
                ...state,
                timer: {
                    ...state.timer,
                    startDate: now,
                    endDate: now,
                }
            };
        }
        case Constants.TOGGLE_EXERCISE_ANSWER: {
            const {answerIndex, questionIndex} = action.payload;
            return {
                ...state,
                questions: state.questions.map((question, index) => {
                        if (index !== questionIndex) return question;
                        return {
                            ...question,
                            answers: question.answers.map((answer, i) => {
                                if (i !== answerIndex) return answer;
                                return {
                                    ...answer,
                                    is_correct: !answer.is_correct,
                                };
                            })
                        }
                    }
                )
            }
        }
        case Constants.STOP_EXERCISE: {
            return {
                ...state,
                timer: initialState.timer,
                questions: initialState.questions,
            };
        }
        case Constants.SET_EXERCISE_RESULT: {
            return {
                ...state,
                result: action.payload,
            };
        }
        case Constants.RESET_EXERCISE: {
            return initialState;
        }
        default:
            return state;
    }
}
