import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '../../../organisms';
import { Button, RoundButton, Headline } from '../../../atoms';
import './confirmFinishExam.scss';

interface ConfirmFinishExamProps {
  childrenBefore: Element;
  unansweredQuestions: number;
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  onFinishExam: () => void;
}

const ConfirmFinishExam: React.FunctionComponent<ConfirmFinishExamProps> = ({
  childrenBefore,
  unansweredQuestions,
  setModalIsOpen,
  modalIsOpen,
  onFinishExam,
}) => {
  const { t } = useTranslation();
  const hasAnsweredAllQuestions = unansweredQuestions === 0;
  return (
    <BaseModal
      baseClass="confirm-finish-exam"
      childrenBefore={childrenBefore}
      setModalIsOpen={setModalIsOpen}
      modalIsOpen={modalIsOpen}>
      {!hasAnsweredAllQuestions && (
        <div className="confirm-finish-exam__icon">
          <RoundButton icon="ExclamationCircleFill" />
        </div>
      )}
      <div className="confirm-finish-exam__headline">
        <Headline type="h2">{t('confirmFinishExam')}</Headline>
      </div>
      {!hasAnsweredAllQuestions ? (
        <>
          <div className="confirm-finish-exam__attention">
            {unansweredQuestions === 1
              ? t('attentionUnansweredQuestion')
              : t('attentionUnansweredQuestions', { unansweredQuestions })}
          </div>{' '}
          <div className="confirm-finish-exam__question">
            {t('doYouReallyWantToFinishYourExam')}
          </div>
        </>
      ) : (
        <div className="confirm-finish-exam__question">
          {t('noUnansweredQuestions')}
        </div>
      )}

      <div className="confirm-finish-exam__actions">
        <Button
          theme="tertiary"
          transparent
          text={t('abort')}
          removeBorder
          onClick={() => setModalIsOpen(false)}
        />
        <Button
          theme={hasAnsweredAllQuestions ? 'primary' : 'danger'}
          transparent
          text={t(hasAnsweredAllQuestions ? 'yesFinish' : 'finish')}
          removeBorder
          onClick={() => {
            onFinishExam();
            setModalIsOpen(false);
          }}
        />
      </div>
    </BaseModal>
  );
};

export default ConfirmFinishExam;
