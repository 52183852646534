import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { ExamCard, ExerciseCard } from '../../common/molecules';
import { Grid } from '../../common/organisms';
import Page from '../../components/pages';
import ExamQueries from '../../libs/ExamQueries';
import NoDataPage from 'pages/NoDataPage/NoDataPage';
import { OfflineModeState } from 'redux/reducers/types';

interface ExamProps {
  offlineMode: OfflineModeState;
}

const Exam: React.FunctionComponent<ExamProps> = ({ offlineMode }) => {
  const hasProduct =
    offlineMode.data && Object.keys(offlineMode.data?.exam).length;
  const { t } = useTranslation();
  const history = useHistory();
  const instanceExamQueries = new ExamQueries(
    hasProduct ? offlineMode.data : {},
  );
  const examData = hasProduct ? instanceExamQueries.getExam() : {};
  const learningAreasData = hasProduct ? offlineMode.data.learning_areas : [];

  function goToStartExam() {
    history.push('/exam/start?callAgain' + Math.random());
  }

  function goToStartExercise(learningAreaId: number) {
    history.push(`/exercise/${learningAreaId}/start?callAgain${Math.random()}`);
  }

  const questionCount = offlineMode.data?.newSettings?.exercise_max_questions || 10;

  const filteredLearningAreas = hasProduct
    ? learningAreasData.filter(
        (area) =>
          area.questions.length > 0 &&
          !!area.questions.find((item) => !!item.is_exercisable),
      )
    : [];

  return (
    <Page>
      {hasProduct ? (
        <div className="cards-page">
          <ExamCard
            headline={t('simulateExam')}
            description={
              examData.duration === 1
                ? t('examDescriptionWithMinute', {
                    duration: examData.duration,
                  })
                : t('examDescriptionWithMinutes', {
                    duration: examData.duration,
                  })
            }
            buttonText={t('startExam')}
            onClick={goToStartExam}
          />
          <Grid size="col-6">
            {filteredLearningAreas.map((learningArea, index) => {
              return (
                <ExerciseCard
                  key={learningArea.id}
                  headline={t('exerciseName', {
                    name: learningArea.headline,
                  })}
                  currentCount={index + 1}
                  totalCount={filteredLearningAreas.length}
                  description={
                    questionCount === 1
                      ? t('countQuestion', {
                          count: questionCount,
                        })
                      : t('countQuestions', {
                          count: questionCount,
                        })
                  }
                  buttonText={t('startExercise')}
                  onClick={() => goToStartExercise(learningArea.id)}
                />
              );
            })}
          </Grid>
        </div>
      ) : (
        <NoDataPage color="#F5AD24" type="exercise" />
      )}
    </Page>
  );
};

export default connect(
  ({ product, offlineMode, user }) => ({
    product,
    offlineMode,
    user,
  }),
  null,
)(Exam);
