import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { isPlatform } from '@ionic/react';
import axios from 'axios';
import {
  CapacitorSQLite,
  SQLiteConnection,
  SQLiteDBConnection,
} from '@capacitor-community/sqlite';

import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  setOfflineData,
  setOfflineResults,
  updateIsOffline,
} from '../../redux/actions/offlineModus';
import { All, Result } from '../../graphql';
import { Spinner } from 'common/atoms/spinner';
import {
  getAllByTable,
  getOfflineDataQuery,
  storeExerciseData,
  storeImageData,
  storeLearningData,
  storeTextData,
} from 'pages/offline-settings/queries';
import { InterfaceDataLoaderProps, OfflineDataType } from './types';
import Trackings from 'graphql/trackings';
import { TrackingState } from 'redux/reducers/types';
import { setTrackings } from 'redux/actions/trackings';
import { clearTables } from 'redux/queries';
import { blobToBase64 } from 'libs/utils';
import {
  OfflineSettingSubareaType,
  OfflineSettingType,
} from 'pages/offline-settings/types';
import { removeFromQueueAction } from 'redux/actions/queue';
import { useHistory } from 'react-router';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import useFetch from "../../common/hooks/useFetch";

const DataLoader: React.FC<InterfaceDataLoaderProps> = ({
  user,
  product,
  setOfflineData,
  offlineMode,
  queue,
  setTrackings,
  updateIsOffline,
  setOfflineResults,
  removeFromQueue,
  license,
}) => {
  const history = useHistory();
  const product_id: number = license?.product_id;
  /*

  FUNCTIONALITY FLOW:

  1. we try fetching results and all data from API
    2a. if that fails, we fetch content from the device
      1. for every item that is stored offline, we populate it inside offlineModus.data. Others remain empty.

    2b. if that doesn't fail, we still fetch what we have stored offline
      1. we compare the version stored offline with the version we got from the API
      2. if they don't match, we re-store the offline data
      3. we set offlineModus.data which causes the component to unmount and to reveal the content beneath
  */
  const handleFetchFail = (err) => {

    if (err?.networkError?.statusCode === 401) {
      history.push('/logout');
    } else {
      updateIsOffline(true);
      setTimeout(() => {
        fetchOfflineDataFromDB(product_id); // keep this in place to avoid sqlite issue (connection already existing)
      }, 2000);
    }
  };

  const queueResults = useRef<any>();
  const [createResult] = useMutation(Result.createMutation());
  const { data: allData, error } = useFetch(`${process.env.REACT_APP_SERVER}/api/products/${product_id}`, {
    headers: {
      authorization: `Bearer ${user.token}`
    }
  });

  useEffect( () => {
    if(error) {
      console.log(error);
      handleFetchFail(error)
    }
  },[error])


  const {data: resultData} = useQuery(Result.getAllQuery(), {
    variables: { product_id },
  });

  const trackingApiUrl = process.env.REACT_APP_SERVER + '/api/tracking';

  useEffect(() => {
    if (allData && resultData && user.token) {
      updateIsOffline(false);
      uploadAndFetchTrackings().then(() => fetchOfflineDataFromDB(product_id));
    }
  }, [allData, resultData, product_id]); // eslint-disable-line

  const uploadAndFetchTrackings = async () => {
    const offlineTrackingData = localStorage.getItem('tracking_offline');
    if (offlineTrackingData) {
      const dataArray = JSON.parse(offlineTrackingData);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      try {
        await Promise.all(
          dataArray.map(async (item) =>
            axios.post(trackingApiUrl, item, config),
          ),
        );
        localStorage.removeItem('tracking_offline');
      } catch (err) {
        // console.log(err);
      }
    }
    const trackings = await Trackings.getUserTrackings(
      user.token,
      license.product_id,
    );
    setTrackings(trackings);
    const resultsQueue: any[] = [];
    if (queue && !!queue.list.length) {
      await Promise.all(
        queue.list.map(async (request) => {
          try {
            const result = await createResult(request.request);
            resultsQueue.push(result.data.createResult);
            removeFromQueue(request);
          } catch (err) {
            console.log(err);
          }
        }),
      );
      queueResults.current = resultsQueue;
    }
  };

  const fetchOfflineDataFromDB = async (product_id: number) => {
    const offlineData: OfflineDataType = {
      user,
      learning_contents: [],
      learning_areas: [],
      sub_areas: [],
      newSettings: {},
      results: [],
      exam: {},
      exercises: [],
      images: [],
      product: {},
    };
    const hasOnlineResults =
      !!allData && !!resultData && !!user.token;
    if (isPlatform('mobile') && !isPlatform('mobileweb')) {
      const connection: SQLiteConnection = new SQLiteConnection(
        CapacitorSQLite,
      );
      const connectionName =
        'appucations' + (product_id || process.env.REACT_APP_PRODUCT_ID);

      try {
        const ret = await connection.checkConnectionsConsistency();
        const isConn = await connection.isConnection(connectionName);
        let db: SQLiteDBConnection;
        if (ret.result && isConn.result) {
          db = await connection.retrieveConnection(connectionName);
        } else {
          db = await connection.createConnection(
            connectionName,
            false,
            'no-encryption',
            1,
          );
        }

        await db.open();
        const product = await db.query(getAllByTable('product'));

        const productResult =
          product.values && product.values?.length ? product.values[0] : null;
        if (hasOnlineResults && productResult) {
          const version = Number(productResult.version);
          if (allData.product.version !== version) {
            // we have to  update all the content!
            const dataToStore = await db.query(getOfflineDataQuery); // that's the data we're supposed to store
            if (dataToStore.values?.length) {
              await db.execute(clearTables);
              dataToStore.values.map(
                async (value: { type: string; stored: number }) => {
                  const { type, stored } = value;
                  if (!!stored) {
                    const [area, subarea] = type.split('_');
                    await storeData(
                      area as OfflineSettingType,
                      subarea as OfflineSettingSubareaType,
                      db,
                    );
                  }
                },
              );
            }
          }
        }
        if (!hasOnlineResults && !!productResult) {
          offlineData.product = JSON.parse(productResult.content);
          const content = await db.query(getAllByTable('learning'));
          const images = await db.query(getAllByTable('learning_images'));
          const statistics = await db.query(getAllByTable('statistics'));
          const exercises = await db.query(getAllByTable('exercise'));

          const fixSingleQuoteInText = (text: string) => {
            return text.replace(/###SINGLEQUOTE###/g, "'");
          };

          if (content.values && content.values?.length) {
            const subAreaData = content.values.find(
              (item: { title: string }) => item.title === 'subareas',
            );
            offlineData.sub_areas = JSON.parse(
              fixSingleQuoteInText(subAreaData?.content || '[]'),
            );
            const learningAreaData = content.values.find(
              (item: { title: string }) => item.title === 'learningareas',
            );
            offlineData.learning_areas = JSON.parse(
              fixSingleQuoteInText(learningAreaData?.content || '[]'),
            );
            const learningContentData = content.values.find(
              (item: { title: string }) => item.title === 'learningcontent',
            );
            let learning_contents = learningContentData
              ? learningContentData.content
              : '[]';

            if (images.values && images.values?.length) {
              const maxAmount = +images.values[0].content;
              for (let i = 0; i <= maxAmount; i++) {
                try {
                  const { uri } = await Filesystem.getUri({
                    path:
                      'appucations' +
                      (license.product_id || process.env.REACT_APP_PRODUCT_ID) +
                      `/img-src-${i}.jpg`,
                    directory: Directory.Data,
                  });
                  const convertedUri = Capacitor.convertFileSrc(uri);
                  learning_contents = learning_contents.replace(
                    `img-src-${i}`,
                    convertedUri,
                  );
                } catch (err) {}
              }
            }
            offlineData.learning_contents = JSON.parse(
              fixSingleQuoteInText(learning_contents),
            );
          }
          if (statistics.values?.length) {
            offlineData.results = JSON.parse(
              fixSingleQuoteInText(statistics.values[0].content),
            );
          }
          if (exercises.values?.length) {
            const exerciseData = exercises.values.find(
              (item) => item.title === 'exercises',
            );
            const examData = exercises.values.find(
              (item) => item.title === 'exam',
            );
            const newSettingsData = exercises.values.find(
              (item) => item.title === 'settings',
            );
            offlineData.exercises = JSON.parse(
              fixSingleQuoteInText(exerciseData?.content || '[]'),
            );
            offlineData.exam = JSON.parse(
              fixSingleQuoteInText(examData?.content || '[]'),
            );
            offlineData.newSettings = JSON.parse(
              fixSingleQuoteInText(newSettingsData?.content || '{}'),
            );
          }
        }
        await db.close();
        await connection.closeConnection(connectionName);
      } catch (err) {
        // console.log(err);
      }
    }

    setOfflineData(
      hasOnlineResults
        ? {
            ...allData,
            results: queueResults.current
              ? [...resultData.results, ...queueResults.current]
              : [...resultData.results],

          }
        : offlineData,
    );
  };

  const storeData = async (
    area: OfflineSettingType,
    subarea: OfflineSettingSubareaType,
    db: SQLiteDBConnection,
  ) => {
    const {
      exam, // EXERCISE TABLE
      exercises, // EXERCISE TABLE
      newSettings, // EXERCISE TABLE
      learning_areas, // LEARNING TABLE
      learning_contents, // LEARNING TABLE
      sub_areas, // LEARNING TABLE
      images,
      results,
      product,
    } = offlineMode.data;

    const { version } = product;

    if (subarea === 'all') {
      await db.execute(
        storeTextData({
          tableName: 'product',
          version,
          content: JSON.stringify(product),
        }),
      );
      if (area === 'learning') {
        let learningContentsString = JSON.stringify(learning_contents);
        if (images.length) {
          images.forEach((image: string, index: number) => {
            learningContentsString = learningContentsString.replace(
              image,
              `src-image-[${index}]`,
            );
          });
        }
        await db.execute(
          storeLearningData({
            version,
            title: 'learningareas',
            content: JSON.stringify(learning_areas),
          }),
        );
        await db.execute(
          storeLearningData({
            version,
            title: 'subareas',
            content: JSON.stringify(sub_areas),
          }),
        );
        await db.execute(
          storeLearningData({
            version,
            title: 'learningcontent',
            content: learningContentsString,
          }),
        );
      } else if (area === 'exercise') {
        await db.execute(
          storeExerciseData({
            version,
            title: 'exam',
            content: JSON.stringify(exam),
          }),
        );
        await db.execute(
          storeExerciseData({
            version,
            title: 'exercises',
            content: JSON.stringify(exercises),
          }),
        );
        await db.execute(
          storeExerciseData({
            version,
            title: 'settings',
            content: JSON.stringify(newSettings),
          }),
        );
      } else if (area === 'statistics') {
        await db.execute(
          storeTextData({
            tableName: 'statistics',
            content: JSON.stringify(results),
            version,
          }),
        );
      }
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 300,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#2f9cf2',
        textAlign: 'center',
      }}>
      <div id="loading-overlay">
        <Spinner color="#fff" />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    product: state.product,
    offlineMode: state.offlineMode,
    appDefaults: state.appDefaults,
    queue: state.queue,
    license: state.license,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateIsOffline: (isOffline: boolean) => dispatch(updateIsOffline(isOffline)),
  setOfflineData: (data: OfflineDataType) => dispatch(setOfflineData(data)),
  setTrackings: (data: TrackingState) => dispatch(setTrackings(data)),
  removeFromQueue: (data: any) => dispatch(removeFromQueueAction(data)),
  setOfflineResults: (data: any) => dispatch(setOfflineResults(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataLoader);
