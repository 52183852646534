import React, { useState } from 'react';
import { renderToString } from 'react-dom/server';
import './headline.scss';
import { Wysiwyg } from '../';
import { NOOP } from '../../../libs/utils';

export default function Headline({
  children,
  type = 'h1',
  editable = {
    isEditMode: false,
    onChange: NOOP,
    name: '',
    labelText: '',
    wysiwygConfig: {},
  },
  values = {},
}) {
  const [headline, setHeadline] = useState(
    values.hasOwnProperty(editable.name)
      ? values[editable.name]
      : renderToString(children),
  );

  const types = ['h1', 'h2', 'h3'];
  if (editable.isEditMode) {
    return (
      <div>
        <input
          type="hidden"
          name={editable.name}
          value={(values[editable.name] = headline)}
          onChange={editable.onChange}
        />
        <Wysiwyg
          data={values[editable.name]}
          config={editable.wysiwygConfig}
          onChange={(event, editor) => {
            const data = editor.getData();
            setHeadline(data);
          }}
        />
      </div>
    );
  }

  if (types.indexOf(type) > -1) {
    return React.createElement(type, null, children);
  }

  return <h1>{children}</h1>;
}
