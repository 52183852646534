import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './results.scss';
import { getFormattedDate } from '../../../libs/utils';
import { ResultItem } from 'types/result';

interface ResultsPageProps {
  results: ResultItem[];
  onEnterResult: (result: ResultItem) => void;
}

const ResultsPage: React.FunctionComponent<ResultsPageProps> = ({
  results = [],
  onEnterResult,
}) => {
  const { t } = useTranslation();
  const RenderIcon = Icon['ChevronRight'];

  function getResultsClassNames() {
    return classNames({
      results: true,
      'results--is-empty': results.length === 0,
    });
  }

  function getPointsClassNames(result: ResultItem) {
    return classNames({
      'results-item__points': true,
      'results-item__points--is-success': result.is_success === true,
    });
  }

  const sortedResults = results.sort((a: ResultItem, b: ResultItem) =>
    a.id < b.id ? 1 : -1,
  );

  return (
    <div className={getResultsClassNames()}>
      {sortedResults.map((result) => (
        <div
          key={`result-${result.id}`}
          className="results-item"
          onClick={() => onEnterResult(result)}>
          <span className="results-item__label">
            {result.type === 'exam' ? t('typeExam') : t('typeExercise')}
          </span>
          <span className="results-item__datetime">
            {getFormattedDate(result.started_at * 1000)}
          </span>
          <span className={getPointsClassNames(result)}>
            {result.points_achieved} / {result.points}
          </span>
          <span className="results-item__arrow">
            <RenderIcon />
          </span>
        </div>
      ))}

      {results.length === 0 && <div>{t('noDataFound')}</div>}
    </div>
  );
};

export default ResultsPage;
