import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Page from '../../components/pages';
import LoginPage from '../../common/pages/login';
import { Authorization } from '../../graphql';
import { signIn } from '../../redux/actions';
import { Error } from '../../common/molecules/modal';
import Footer from 'components/pages/footers';
import {isPlatform, useIonViewWillLeave} from '@ionic/react';
import {dispatch} from "../../redux/store";
import {getAndStoreLicenseAction} from "../../redux/actions/license";
import {NOOP} from "../../libs/utils";

interface LoginProps {
  appElement: any;
  signIn: (data) => void;
}

export const PlainLogin: React.FC<LoginProps> = ({ appElement, signIn }) => {
  const { t } = useTranslation();
  const { push, goBack } = useHistory();
  const [loginFailed, setLoginFailed] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  function login(values) {
    setIsLoggingIn(true);
    Authorization.login(values.email.trim(), values.password)
      .then((token) => {
        Authorization.getUserDetails(token).then((user) => {
          signIn({ user, token });
          if(process.env.REACT_APP_PRODUCT_ID) {
            dispatch(getAndStoreLicenseAction({product_id: +process.env.REACT_APP_PRODUCT_ID}));
            push('learning');
          } else {
            push('select-course');
          }

        });
      })
      .catch(() => {
        setLoginFailed(true);
        setIsLoggingIn(false);
      });
  }

  useIonViewWillLeave(() => setIsLoggingIn(false));


  let setOnClick = () => push('/onboarding');
  if(!process.env.REACT_APP_PRODUCT_ID) {
    if(  !isPlatform('ios') ) {
      setOnClick = NOOP;
    } else {
      setOnClick = () => push('/register');
    }
  }

  return (
    <Page title={t('loginPageTitle')}>
      <div
        className={`banner-login banner-wrapper ${
          !!window.localStorage.getItem('login') ? 'knownuser' : ''
        }`}>
        <LoginPage
          headline={t('loginPageTitle')}
          appElement={appElement}
          handleSubmit={login}
          linkToForgotPassword={'lost-password'}
          onClick={setOnClick}
          isLoading={isLoggingIn}
        />
        <Error
          appElement={appElement}
          title={t('loginErrorTitle')}
          description={t('loginErrorDescription')}
          modalIsOpen={loginFailed}
          setModalIsOpen={() => setLoginFailed(false)}
        />
      </div>
      <Footer />
    </Page>
  );
};

export default connect(({ appElement }) => ({ appElement }), { signIn })(
  PlainLogin,
);
