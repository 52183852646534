import React, { useState, useEffect } from 'react';
import Banner from '../../molecules/banner';
import { FormWrapper, LoginForm } from '../../organisms';
import * as Yup from 'yup';
import { NOOP } from '../../../libs/utils';
import { t } from '../../../config/translation';
import {useHistory, useLocation} from 'react-router';
import {isPlatform} from "@ionic/react";

interface LoginPageProps {
  headline: string;
  onClick: () => void;
  handleSubmit: (data: {}) => void;
  linkToForgotPassword: string;
  appElement: JSX.Element;
  isLoading: boolean;
}
const LoginPage: React.FunctionComponent<LoginPageProps> = ({
  headline = 'login',
  onClick = NOOP,
  handleSubmit = NOOP,
  linkToForgotPassword = '#',
  appElement,
  isLoading,
}) => {
  const { search } = useLocation();
  const { push } = useHistory();
  const searchParams = new URLSearchParams(search);
  const [user, setUser] = useState({
    email: searchParams.get('email') || '',
    password: '',
  }); // TO CHECK: can I make this a regular variable?

  const editable = {
    email: {
      name: 'email',
      labelText: t('email'),
    },
    password: {
      name: 'password',
      labelText: t('password'),
    },
    onChange: () => {},
  };

  const validation = {
    headline: t('loginFailedHeadline'),
    description: t('loginFailedDescription'),
    schema: Yup.object().shape({
      email: Yup.string().trim()
        .email('Email ist keine gültige E-Mail-Adresse')
        .required('Email ist ein Pflichtfeld'),
      password: Yup.string().required('Passwort ist ein Pflichtfeld'),
    }),
  };

  return (
    <Banner headline={t(headline)} onClick={onClick}>
      <FormWrapper
        initialValues={user}
        editable={editable}
        handleSubmit={handleSubmit}
        validation={validation}
        appElement={appElement}>
        <LoginForm
          linkToForgotPassword={linkToForgotPassword}
          isLoading={isLoading}
        />
      </FormWrapper>
    </Banner>
  );
};

export default LoginPage;
