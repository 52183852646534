import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { RoundButton } from 'common/atoms';
import { Copyright } from 'common/molecules';
import { OfflineModeState, ProductState } from 'redux/reducers/types';

interface InterfaceSideBarInformationBoxProps {
  onFeedbackClick: () => void;
  onInfoAreaClicked: () => void;
  onStatisticClick: () => void;
  product: ProductState;
  offlineMode: OfflineModeState;
}

const SideBarInformationBox: React.FunctionComponent<InterfaceSideBarInformationBoxProps> = ({
  onFeedbackClick,
  onInfoAreaClicked,
  onStatisticClick,
  product,
  offlineMode,
}) => {
  const { t } = useTranslation();
  const { isOffline } = offlineMode;

  const hasOneInfoArea =
    !!product.general_instructions ||
    !!product.written_exam_instructions ||
    !!product.partner_page ||
    !!product.thanks_page;

  return (
    <>
      <div className="sidebar__information-box">
        <div className="sidebar__links has-icon">
          <button className="only-desktop" onClick={onStatisticClick}>
            <span>{t('statistic')}</span>
            <RoundButton icon="ChevronRight" transparent iconSize={24} />
          </button>
          {hasOneInfoArea && (
            <button onClick={onInfoAreaClicked}>
              <span>{t('infoArea')}</span>
              <RoundButton icon="ChevronRight" transparent iconSize={24} />
            </button>
          )}
          <button className="only-desktop" onClick={onFeedbackClick}>
            <span>{t('feedBack')}</span>
            <RoundButton icon="ChevronRight" transparent iconSize={24} />
          </button>
          <Link
            className={`only-mobile ${isOffline ? 'link-disabled' : ''}`}
            to={isOffline ? '' : '/feedback'}>
            <span>{t('feedBack')}</span>
            <RoundButton icon="ChevronRight" transparent iconSize={24} />
          </Link>
          <Link to={'/terms-of-service'}>
            <span>{t('conditionTerms')}</span>
            <RoundButton icon="ChevronRight" transparent iconSize={24} />
          </Link>
          <Link to={'/data-protection-policy'}>
            <span>{t('dataProtection')}</span>
            <RoundButton icon="ChevronRight" transparent iconSize={24} />
          </Link>
          <Link to={'/imprint'}>
            <span>{t('imprint')}</span>
            <RoundButton icon="ChevronRight" transparent iconSize={24} />
          </Link>
        </div>
      </div>
      <Copyright />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  product: state.product,
  offlineMode: state.offlineMode,
});

export default connect(mapStateToProps)(SideBarInformationBox);
