import React from 'react';
import classNames from 'classnames';
import './divider.scss';

export default function Divider({ size }) {
  const className = classNames({
    divider: true,
    'divider--is-small': size === 'small',
    'divider--is-medium': size === 'medium',
    'divider--is-full': size === 'full',
    'divider--is-auto': size === 'auto',
  });

  return <hr className={className} />;
}
