import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { LearningSearch } from '../../../common/molecules';

interface InterfaceSearchBoxProps {
  offlineMode: any;
}

export interface SearchItemType {
  linkUrl: string;
  wysiwyg: string;
  headline: string;
}

const SearchBox: React.FunctionComponent<InterfaceSearchBoxProps> = (props) => {
  const [items, setItems] = useState<SearchItemType[]>([]);
  const [inputActive, setInputActive] = useState(false);
  const [searchStarted, setSearchStarted] = useState(false);
  const [search, setSearch] = useState('');
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (search.length < 2) {
      setItems([]);
      setSearchStarted(false);
      setInputActive(false);
      return;
    }
    const items = handleSearch();
    setItems(items);
    setSearchStarted(true);
  }, [props.offlineMode.data, search, t]); //eslint-disable-line

  useEffect(() => {
    setItems([]);
    setSearchStarted(false);
    setSearch('');
    setInputActive(false);
  }, [pathname]);

  const containsSearch = (search: string, content: string) => {
    if (!content) return false;
    return content?.toLowerCase()?.indexOf(search.toLowerCase()) > -1;
  }

  const handleSearch: () => SearchItemType[] = () => {
    const offlineData = props.offlineMode?.data || null;
    const items: SearchItemType[] = [];

    if (offlineData) {
      if (offlineData.sub_areas) {
        const subAreaItems = offlineData?.sub_areas?.filter(
          (subArea: any) =>
          {
            return containsSearch(search, subArea.headline) &&
              !subArea.learning_contents?.length &&
              !!subArea?.child_categories?.length
          }
        );
        subAreaItems.forEach((subArea: any) => {
          const { headline, learning_area_id, id } = subArea;
          items.push({
            wysiwyg: t('subarea'),
            headline,
            linkUrl: `/learning/${learning_area_id}/${id}`,
          });
        });
      }
      if (offlineData.learning_areas) {
        const exerciseAreas = offlineData.learning_areas.filter(
          (learningArea: any) =>
            containsSearch(search, learningArea.headline) &&
            !!learningArea.questions.length,
        );
        exerciseAreas.forEach((exerciseArea: any) => {
          const { headline, id } = exerciseArea;
          items.push({
            headline,
            wysiwyg: t('typeExercise'),
            linkUrl: `/exercise/${id}/start?callAgain=${Math.random() * 1000}`,
          });
        });
      }
      if (offlineData.learning_contents) {
        const learningContentItems = offlineData.learning_contents.filter(
          (learningContent: any) => {
            const { headline, wysiwyg, sub_area_id } = learningContent;

            const subArea = offlineData.sub_areas.find(
              (subArea: any) => subArea.id === sub_area_id,
            );

            return (
              containsSearch(search, headline || subArea?.headline) ||
              containsSearch(search, wysiwyg)
            );
          },
        );
        learningContentItems.forEach((item: any) => {
          const { headline, wysiwyg, sub_area_id, id } = item;

          const subArea = offlineData.sub_areas.find(
            (subArea: any) => subArea.id === sub_area_id,
          );

          let searchListItem = {
            wysiwyg,
            headline: headline || subArea.headline,
            linkUrl: '' };
          if (!!subArea.parent_sub_area) {
            searchListItem.linkUrl = `/learning-content/${subArea.learning_area_id}/${subArea.parent_sub_area.id}/${subArea.id}`;
            items.push(searchListItem);
          }
        });
      }
    }
    return items.slice(0, 5);
  };

  return (
    <LearningSearch
      items={items}
      inputActive={inputActive}
      searchValue={search}
      noSearchResultsLabelText="Es konnten keine passenden Inhalte gefunden werden"
      noSearchResultsTypeText="Versuch einen anderen Suchbegriff"
      searchStarted={searchStarted}
      handleSearch={setSearch}
      handleInputActive={setInputActive}
    />
  );
};

export default connect(({ offlineMode }) => ({
  offlineMode,
}))(SearchBox);
