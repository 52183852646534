import gql from 'graphql-tag';

var defaultProperties = `
  id
  name 
  icon
  web_store_url
  play_store_url
  ios_store_url
  general_instructions
  written_exam_instructions
  oral_exam_instructions
`;
export default class Product {
    static getQuery(properties) {
        properties = properties || `
    id
    name 
    icon
    general_instructions
    written_exam_instructions
    oral_exam_instructions
    thanks_page,
    partner_page,
    learning_areas: learningAreas {
      id
      headline
      points
      count_sub_areas
      count_learning_contents
    }
    settings {
      id
      name
      description
      value
      type
      input_type
    }`;

        return gql`query product($id: Int!) {
      product(id: $id) {
        ${properties}
      }
    }
  `;
    }

    static getAllQuery(properties) {
        properties = properties || defaultProperties;

        return gql`query products {
      products(limit: 1000) {
        ${properties}
      }
    }`;
    }

    static createMutation(properties) {
        properties = properties || defaultProperties;

        return gql`mutation createProduct(
      $name: String!
      $icon: String
      $web_store_url: String
      $play_store_url: String
      $ios_store_url: String
      $thanks_page: String
      $partner_page: String
    ) {
      createProduct(
        name: $name
        icon: $icon
        web_store_url: $web_store_url
        play_store_url: $play_store_url
        ios_store_url: $ios_store_url
        thanks_page: $thanks_page
        partner_page: $partner_page
      ) {
        ${properties}
      }
    }`;
    }

    static updateMutation(properties) {
        properties = properties || defaultProperties;

        return gql`mutation updateProduct(
      $id: Int!
      $name: String!
      $icon: String
      $web_store_url: String
      $play_store_url: String
      $ios_store_url: String
      $thanks_page: String
      $partner_page: String
    ) {
      updateProduct(
        id: $id
        name: $name
        icon: $icon
        web_store_url: $web_store_url
        play_store_url: $play_store_url
        ios_store_url: $ios_store_url        
        thanks_page: $thanks_page
        partner_page: $partner_page
      ) {
        ${properties}
      }
    }`;
    }

    static deleteMutation(properties) {
        properties = properties || defaultProperties;

        return gql`mutation deleteProduct(
      $id: Int!
    ) {
      deleteProduct(
        id: $id 
      ) {
        ${properties}
      }
    }`;
    }


    static updateInstructionsMutation (properties) {
        properties = properties || `
    id
    general_instructions 
    written_exam_instructions
    oral_exam_instructions
    thanks_page
    partner_page
    `

        return gql`mutation updateProductInstructions(
    $id: Int!
    $general_instructions: String
    $written_exam_instructions: String
    $oral_exam_instructions: String
    $thanks_page: String
    $partner_page: String
    ) {
      updateProductInstructions(
      id: $id
      general_instructions: $general_instructions
      written_exam_instructions: $written_exam_instructions
      oral_exam_instructions: $oral_exam_instructions
      thanks_page: $thanks_page
      partner_page: $partner_page
      ) {
        ${properties}
      }
    }`
    }
}
