import React from 'react';

import './translationProvider.scss';
import I18n from '../../../config/translation';

/** Wrap your App within this component */
export default class TranslationProvider extends React.Component {
  constructor(props) {
    super(props);
    I18n(props.translationsObject);
  }

  render() {
    const { children } = this.props;
    return <div className="translation-provider">{children}</div>;
  }
}
