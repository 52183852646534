import React from 'react';

import { Link, Button } from '../../atoms';
import { AvatarUpload } from '../../molecules';
import { t } from '../../../config/translation';
import './registerForm.scss';
import { useHistory } from 'react-router';

export default function RegisterForm({
  editable,
  values,
  save,
  linkToAlreadyRegistered,
  onAvatarUpload,
}) {
  const { push } = useHistory();
  return (
    <div className="register-form">
      <div className="register-form__avatar">
        <AvatarUpload onImageUpload={onAvatarUpload} />
      </div>

      <div className="register-form__inputs">
        <div className="register-form__field">
          <span className="label">{editable.email.labelText}</span>
          <input
            value={values.email}
            onChange={editable.onChange}
            {...editable.email}
          />
        </div>
        <div className="register-form__field">
          <span className="label">{editable.password.labelText}</span>
          <input
            value={values.password}
            type="password"
            onChange={editable.onChange}
            {...editable.password}
          />
        </div>
        <div className="register-form__field">
          <span className="label">{editable.c_password.labelText}</span>
          <input
            value={values.c_password}
            type="password"
            onChange={editable.onChange}
            {...editable.c_password}
          />
        </div>
        <div className="register-form__field">
          <span className="label">{editable.first_name.labelText}</span>
          <input
            value={values.first_name}
            onChange={editable.onChange}
            {...editable.first_name}
          />
        </div>
        <div className="register-form__field">
          <span className="label">{editable.last_name.labelText}</span>
          <input
            value={values.last_name}
            onChange={editable.onChange}
            {...editable.last_name}
          />
        </div>
        <div className="register-form__field acceptance">
          <input
            type="checkbox"
            // value={values.terms}
            onClick={() => editable.setFieldValue('terms', !values.terms)}
            // onChange={editable.onChange}
            checked={values.terms}
          />
          <span>
            Ich habe die{' '}
            <a onClick={() => push('/data-protection-policy')}>
              Datenschutz&shy;erklä&shy;rung
            </a>{' '}
            und die <a onClick={() => push('/terms-of-service')}>AGB</a> gelesen
            und akzept&shy;iere sie.
          </span>
        </div>
      </div>
      <div className="register-form__actions">
        <Button onClick={save} text={t('create')} />
        <Link to={linkToAlreadyRegistered}>{t('alreadyRegistered')}</Link>
      </div>
    </div>
  );
}
