import React from 'react';

function PersoImage() {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 487 633"
      xmlSpace="preserve">
      <style>{'.prefix__st1{fill:#fff}'}</style>
      <title>{'img_perso'}</title>
      <path
        d="M356.8 42H236c-53.5 0-97 43.5-97 97v47.8c0 53.5 43.5 97 97 97h11.9v41.5c0 2.7.7 3.6.8 3.7h.1c.3 0 1.4-.3 3.2-2l43.2-43.2h61.6c53.5 0 97-43.5 97-97V139c0-53.5-43.5-97-97-97"
        fill="#bde6a3"
      />
      <path
        className="prefix__st1"
        d="M236 283.8c-53.5 0-97-43.5-97-97V139c0-53.5 43.5-97 97-97h120.8c53.5 0 97 43.5 97 97v47.8c0 53.5-43.5 97-97 97h-61.6L252 326.9c-1.7 1.7-2.8 2-3.2 2h-.1c-.2-.1-.8-1.1-.8-3.7v-41.5H236M356.8 36H236c-56.8 0-103 46.2-103 103v47.8c0 56.8 46.2 103 103 103h5.9v35.5c0 6.4 2.8 8.6 4.5 9.3.6.2 1.4.5 2.3.5 1.9 0 4.4-.8 7.4-3.8l41.4-41.4h59.1c56.8 0 103-46.2 103-103V139c.2-56.8-46-103-102.8-103"
      />
      <path
        d="M47 222h165.7c9.4 0 17 7.6 17 17v337.4c0 9.4-7.6 17-17 17H47c-9.4 0-17-7.6-17-17V239c0-9.4 7.6-17 17-17z"
        fill="#59c11a"
      />
      <path
        className="prefix__st1"
        d="M212.7 596.4H47c-11 0-20-9-20-20V239c0-11 9-20 20-20h165.7c11 0 20 9 20 20v337.4c0 11-9 20-20 20zM47 225c-7.7 0-14 6.3-14 14v337.4c0 7.7 6.3 14 14 14h165.7c7.7 0 14-6.3 14-14V239c0-7.7-6.3-14-14-14H47z"
      />
      <path
        className="prefix__st1"
        d="M129.5 559.1c-11.3 0-20.4-9.2-20.4-20.4s9.2-20.4 20.4-20.4 20.4 9.2 20.4 20.4-9.1 20.4-20.4 20.4zm0-34.8c-7.9 0-14.4 6.5-14.4 14.4s6.5 14.4 14.4 14.4 14.4-6.5 14.4-14.4-6.4-14.4-14.4-14.4zM285.8 216H243v-67h42.8v67zm-36.8-6h30.8v-55H249v55z"
      />
      <path
        className="prefix__st1"
        d="M283.3 206.3c-.8 0-1.5-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1v-41.1c0-1.2.7-2.3 1.9-2.8.1 0 11.1-4.5 17.1-12.8 6.2-8.5 10.8-28.4 10.8-28.6.3-1.4 1.5-2.3 2.9-2.3 3.7 0 6.8 1.5 9 4.3 6.5 8.4 3.2 27.5 1.4 35.9h24.4c1.2 0 2.3.7 2.8 1.8 3.9 9.2 3 20.3-2.5 32-4.1 8.6-9.2 14.6-9.4 14.8-.6.6-1.4 1-2.2 1l-53.2.8zm3-42.2v36.1l48.6-.6c3.4-4.4 15.9-21.9 10.8-37.7h-26.2c-.9 0-1.8-.4-2.4-1.2-.6-.7-.8-1.7-.5-2.6 1.9-7.3 5.8-27.7.6-34.5-.6-.7-1.2-1.2-2-1.6-1.6 6-5.7 20.5-11.1 28-5.4 7.7-14 12.3-17.8 14.1zM130 425c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-90c-23.2 0-42 18.8-42 42s18.8 42 42 42 42-18.8 42-42-18.8-42-42-42zm17.7 65.9h-35.2v-11.3c0-8.2 5.6-15.1 13.2-17.1-3.8-1.7-6.5-5.5-6.5-10 0-6 4.9-10.9 10.9-10.9s10.9 4.9 10.9 10.9c0 4.4-2.7 8.3-6.5 10 7.6 1.9 13.3 8.9 13.3 17.1v11.3zm-29.3-6h23.2v-5.3c0-6.4-5.2-11.6-11.6-11.6s-11.6 5.2-11.6 11.6v5.3zm11.6-37.3c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9z"
      />
    </svg>
  );
}

export default React.memo(PersoImage);
