import * as React from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { IonLoading } from '@ionic/react';

import { Button } from '../../common/atoms';
import { Error } from '../../common/molecules/modal';
import { BaseModal } from '../../common/organisms';

import SearchIcon from '../../assets/icons/ico_magnifier.svg';

interface InterfaceFeedbackProps {
  user: any;
  productName: any;
}

const apiUrl: string = process.env.REACT_APP_SERVER + '/api/feedback-send';

const Feedback: React.FunctionComponent<InterfaceFeedbackProps> = (
  props: InterfaceFeedbackProps,
) => {
  const { user, productName } = props;
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const appElement = '#root';
  const areaRef: any = useRef(null);
  const { t } = useTranslation();

  const sendFeedback = function () {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .post(
        apiUrl,
        {
          message: areaRef.current.value,
          product: productName,
        },
        config,
      )
      .then(() => {
        setLoading(false);
        setHasError(false);
        setShowSuccessModal(true);
        areaRef.current.value = '';
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
        setShowSuccessModal(false);
      });
  };

  return (
    <div className="feedback">
      <IonLoading isOpen={loading} />
      <div className="text-content">
        <h2>{t('feedbackHeadline')}</h2>
        <p>{t('feedbackDescription')}</p>
      </div>
      <textarea
        ref={areaRef}
        className="feedback-form-textarea"
        name="message"
      />
      <div className="submit-button">
        <Button
          onClick={sendFeedback}
          theme="primary"
          text={t('sendFeedback')}
        />
      </div>
      <Error
        appElement={appElement}
        title={t('feedbackErrorMessageTitle')}
        description={
          <span
            dangerouslySetInnerHTML={{
              __html: t('feedbackErrorMessage'),
            }}
          />
        }
        modalIsOpen={hasError}
        setModalIsOpen={() => setHasError(false)}
      />
      <BaseModal
        appElement="#root"
        setModalIsOpen={setShowSuccessModal}
        modalIsOpen={showSuccessModal}
        className="modal modal-success">
        <div className="overlay">
          <img
            src={SearchIcon}
            className="svg"
            height="101"
            alt=""
          />
          <h4>{t('feedbackSuccessHeadline')}</h4>
          <p>{t('feedbackSuccessful')}</p>
          <hr />
          <div className="actions single">
            <button
              onClick={() => setShowSuccessModal(false)}
              className="confirm">
              <strong>{t('further')}</strong>
            </button>
          </div>
        </div>
      </BaseModal>
    </div>
  );
};

export default Feedback;
