import {Constants} from "../constants";

export function setProduct(product) {

    return {
        type: Constants.SET_PRODUCT,
        payload: product,
    }
}

export function resetProduct() {
    return {
        type: Constants.RESET_PRODUCT,
    }
}
