import { Constants } from '../constants';
import { resetAppElement } from './appElement';
import { resetExam } from './exam';
import { resetExercise } from './exercise';
import { resetGlobalErrors } from './globalError';
import { resetLastLocation } from './lastLocation';
import { resetProduct, setProduct } from './product';
import { Product } from '../../graphql';
import { client } from '../../graphql';
import {changeCourseState, resetOfflineData, resetState} from './offlineModus';
import { PURGE } from 'redux-persist';

export function signIn({ user, token }) {
  return function (dispatch) {
    const response = dispatch({ type: Constants.LOGIN, user, token });
    const { id, first_name, last_name, email, avatar, onboardingSeen, licenses } = user;
    localStorage.setItem(
      'user',
      JSON.stringify({
        id,
        first_name,
        last_name,
        email,
        avatar,
        onboardingSeen,
        token,
        licenses
      }),
    );
    window.localStorage.setItem('login', 'true');

    return response;
  };
}

export function setOnboardingSeen() {
  return function (dispatch) {
    return dispatch({ type: Constants.SET_ONBOARDING_SEEN });
  };
}

export function signOut() {
  return function (dispatch) {
    dispatch(resetState());
    // dispatch(resetAppElement());
    // dispatch(resetExam());
    // dispatch(resetExercise());
    // dispatch(resetGlobalErrors());
    // dispatch(resetLastLocation());
    // dispatch(resetProduct());
    // return dispatch({ type: 'PURGE' });
  };
}

export function updateUser(user) {
  return function (dispatch) {
    return dispatch({ type: Constants.UPDATE_USER, user });
  };
}
