// import { PURGE } from 'redux-persist';

import { Constants } from '../constants';
import { UserState } from './types';

const storedUser = localStorage.getItem('user');

const initialState: UserState = storedUser
  ? (JSON.parse(storedUser) as UserState)
  : {
      id: null,
      first_name: null,
      last_name: null,
      email: null,
      avatar: null,
      token: null,
      onboardingSeen: false,
      licenses: []
    };

export default function user(state = initialState, action) {
  switch (action.type) {
    case Constants.LOGIN:
      const { user, token } = action;
      return {
        ...state,
        ...user,
        token,
      };
    case Constants.LOGOUT:
      return { ...initialState };
    case Constants.SET_ONBOARDING_SEEN:
      const userWithOnboardingTrue = { ...state, onboardingSeen: true };
      window.localStorage.setItem(
        'user',
        JSON.stringify(userWithOnboardingTrue),
      );
      return userWithOnboardingTrue;
    case Constants.UPDATE_USER:
      const newUser = {
        token: state.token,
        ...action.user,
      };
      window.localStorage.setItem('user', JSON.stringify(newUser));
      return newUser;
    default:
      return state;
  }
}
