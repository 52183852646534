import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ExerciseCompleted } from '../../../common/pages';
import Page from '../../../components/pages';
import { useHistory, useParams } from 'react-router';
import {
  prepareExercise,
  startExercise,
} from '../../../redux/actions/exercise';

interface ExerciseFinishProps {
  exercise: any;
}

const ExerciseFinish: React.FC<ExerciseFinishProps> = ({ exercise }) => {
  const history = useHistory();
  const { learningAreaId } = useParams();
  let isSuccess = false;
  if (exercise.result) {
    isSuccess = exercise.result.is_success || false;
  }

  useEffect(() => {
    document.addEventListener('ionBackButton', (ev) => {
      if (window.location.pathname.includes('finished')) {
        // @ts-ignore
        ev.detail.register(10, () => {
          history.push('/exam');
        });
      }
    });

    if (!window.location.pathname.includes('finished')) {
      document.removeEventListener('ionBackButton', () => {
        console.log();
      });
    }
  }, [history]);

  function navigateToDetails(result) {
    history.push(`/profile/statistics/result/${result.id}`);
  }

  function onClickTryAgain() {
    prepareExercise(exercise);
    startExercise();
    history.push(
      `/exercise/${learningAreaId}/start?callAgain=${Math.random() * 1000}`,
    );
  }

  return (
    <Page type={isSuccess ? 'success' : 'danger'} hideFooter>
      {exercise.result && (
        <ExerciseCompleted
          points={exercise.result.points_achieved}
          pointsTotal={exercise.result.points}
          isSuccess={isSuccess}
          navigateToDetails={() => navigateToDetails(exercise.result)}
          onClickTryAgain={onClickTryAgain}
        />
      )}
    </Page>
  );
};

export default connect(({ exercise }) => ({ exercise }), null)(ExerciseFinish);
